import { UiContentComponent } from "../common.style";
import { RandomIcon } from "components/RandomIcon/RandomIcon";
import { versionMap } from "constants/index";

export const BookComponent = ({ title, list, handleToDetailPage }) => {
	return (
		<UiContentComponent>
			<div className="breakLine" />
			<div className="title">{title}</div>
			<div className="content">
				<>
					{list.length > 0 ? (
						list.map((item, index) => {
							return (
								<div key={index} className="chapterBox" onClick={() => handleToDetailPage(item.code)}>
									<RandomIcon text={versionMap[item.version]} />
									{item.name}
								</div>
							);
						})
					) : (
						<div className="noData">無資料</div>
					)}
				</>
			</div>
		</UiContentComponent>
	);
};
