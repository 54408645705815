import { useHistory } from "react-router-dom";

import useQuery from "utils/hooks/useQuery";
import { Breadcrumb, Button } from "common-components";
import { UiTop, UiActionBox } from "styles";
import { EDUCATIONAL_LIST } from "constants/index";
import { InfoList } from "./infoList";
import RandomIcon from "components/RandomIcon";
import { subjectsObj } from "constants/index";
import { UiNewSubjectPage, UiContentComponent } from "../common.style";

const InfoComponent = ({ info, handleInfoClick }) => {
	return (
		<UiContentComponent>
			<div className="breakLine" />
			<div className="title">{Object.keys(info)[0]}</div>
			<div className="content">
				{Object.values(info)[0]?.map((infoItem) => {
					const firstWord = infoItem.name.slice(0, 1);
					return (
						<div className="contentBox" key={infoItem.code} onClick={() => handleInfoClick(infoItem.url)}>
							<RandomIcon text={firstWord} />
							{infoItem.name}
						</div>
					);
				})}
			</div>
		</UiContentComponent>
	);
};

export const InfoPage = () => {
	const history = useHistory();

	const edu = useQuery().get("edu");
	const subject = useQuery().get("subject");
	const eduSubject = edu + subject;

	const eduName = EDUCATIONAL_LIST[edu];
	const subjectName = subjectsObj[subject];

	const breadcrumbGroup = [
		{
			icon: "home",
			name: "首頁",
			path: "/",
		},
		{
			icon: "format_list_bulleted",
			name: "新科目資料",
			path: "/newSubject",
		},
		{
			name: `${eduName} - ${subjectName}`,
			path: `/newSubject/info?edu=${edu}&subject=${subject}`,
		},
	];

	const handleInfoClick = (url) => {
		history.push(`${url}/?edu=${edu}&subject=${subject}`);
	};

	return (
		<div>
			<UiTop>
				<UiActionBox>
					<Button.IconButton
						variant="white"
						full={false}
						iconName="keyboard_backspace"
						onClick={() => {
							history.push(`/newSubject`);
						}}>
						回上層
					</Button.IconButton>
					{edu && subject && <Breadcrumb data={breadcrumbGroup} />}
				</UiActionBox>
			</UiTop>
			<UiNewSubjectPage>
				<div className="header">
					{`${eduName} - ${subjectName}`}
					<UiActionBox style={{ marginLeft: "auto" }}>
						<Button.IconButton iconName="file_download" textColor="black" type="outLined">
							下載範本
						</Button.IconButton>
						<Button.IconButton iconName="lightbulb" textColor="black" type="outLined">
							入庫流程說明
						</Button.IconButton>
					</UiActionBox>
				</div>
			</UiNewSubjectPage>
			{InfoList(eduSubject).map((info, index) => (
				<InfoComponent key={index} info={info} handleInfoClick={handleInfoClick} />
			))}
		</div>
	);
};
