"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Api = exports.put = exports.remove = exports.post = exports.fetch = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _common = require("../utils/common");

var _index = require("../constants/index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

_axios.default.default.timeout = 20000; // axios.defaults.withCredentials = true;

var parseEndpoint = function parseEndpoint(endpoint) {
  var url = endpoint.indexOf("http") === 0 ? endpoint : process.env.VUE_APP_API_DOMAIN + endpoint;
  return url;
};

var formatParams = function formatParams(data) {
  Object.entries(data).forEach(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    if (value === "" || value.length === 0) {
      delete data[key];
    }
  });
  return data;
};

_axios.default.interceptors.request.use(function (config) {
  config.headers = _objectSpread({
    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
    Authorization: (0, _common.checkLogin)(_index.COOKIE_LOGIN_TOKEN_NAME) || null,
    "Content-Type": "application/json"
  }, config.headers);
  return config;
}, function (error) {
  return Promise.reject(error);
});

_axios.default.interceptors.response.use(function (response) {
  // if (!response.data.isSuccess) Promise.reject(response.data.message || "");
  return response;
}, function (err) {
  if (err && err.response) {
    switch (err.response.status) {
      case 401:
        console.log("token怪怪");
        break;

      case 404:
        err.response = "".concat(err.response.status, ":\u654F\uFF23\uFF21\u5922  995!!");
        console.log("沒有這個拉！");
        break;

      case 500:
        err.response = "".concat(err.response.status, ":\u654F\uFF23\uFF21\u5922  995!!!");
        console.log("伺服器錯了哦！");
        break;

      case 503:
        err.response = "".concat(err.response.status, ":\u654F\uFF23\uFF21\u5922  995!!!");
        console.log("不想服務拉！");
        break;

      default:
        console.log("\u9023\u7D50\u932F\u8AA4".concat(err.response.status));
        break;
    }
  } else {
    err.response = "敏ＣＡ夢  995!";
    console.log("連結錯誤 or CORS Error", err.response);
  }

  return Promise.reject(err.response);
});

var fetch = function fetch(url) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var config = arguments.length > 2 ? arguments[2] : undefined;
  return new Promise(function (resolve, reject) {
    _axios.default.get(url, _objectSpread({
      params: formatParams(params)
    }, config)).then(function (response) {
      resolve(response.data);
    }).catch(function (err) {
      reject(err);
    });
  });
};

exports.fetch = fetch;

var _post = function post(url) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var config = arguments.length > 2 ? arguments[2] : undefined;
  return new Promise(function (resolve, reject) {
    _axios.default.post(url, formatParams(data), config).then(function (response) {
      resolve(response.data);
    }, function (err) {
      reject(err);
    });
  });
};

exports.post = _post;

var remove = function remove(url) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return new Promise(function (resolve, reject) {
    _axios.default.delete(url, formatParams(data)).then(function (response) {
      resolve(response.data);
    }, function (err) {
      reject(err);
    });
  });
};

exports.remove = remove;

var _put = function put(url) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return new Promise(function (resolve, reject) {
    _axios.default.put(url, data).then(function (response) {
      resolve(response.data);
    }, function (err) {
      reject(err);
    });
  });
};

exports.put = _put;
var api = {};

api.create = function () {
  return {
    get: function get(url, params, config) {
      return fetch(parseEndpoint(url), params, config);
    },
    post: function post(url, data, config) {
      return _post(parseEndpoint(url), data, config);
    },
    put: function put(url, data) {
      return _put(parseEndpoint(url), data);
    },
    delete: function _delete(url, params) {
      return remove(parseEndpoint(url), params);
    }
  };
};

var Api = api.create();
exports.Api = Api;
var _default = Api;
exports.default = _default;