import { Api } from "common-components";

/**
 * 取得基礎選項
 */
export const getBasicTableData = async () => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Definition/Basic`);
	return response;
};

export const getQuestionTypeTableData = async () => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Definition/QuestionType`);
	return response;
};

export const getSourceTableData = async () => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Definition/Source`);
	return response;
};

export const getEduSubjectTableData = async () => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Definition/EduSubject`);
	return response;
};
/**
 * 取得出處來源基礎選項
 */
export const getSourceRelated = async () => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Definition/Source/Related`);
	return response;
};

/**
 * 取得單一出處來源基礎選項
 */
export const getSingleSourceRelated = async (UID) => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Definition/Source/Related/${UID}`);
	return response;
};

/**
 * 取得題型表基礎選項
 */
export const getQuestionTypeRelated = async () => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Definition/QuestionType/Related`);
	return response;
};

/**
 * 取得單一題型表基礎選項
 */
export const getSingleQuestionTypeRelated = async (code) => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Definition/QuestionType/Related/${code}`);
	return response;
};

/**
 * 新增共用代碼
 */
export const createBasicCode = async (params) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Definition/Basic`, params);
	return response;
};

/**
 * 儲存項目資訊
 */

export const editBasicCode = async (params) => {
	const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Definition/Basic`, params);
	return response;
};

/**
 * 編輯題型表
 */

export const updateQuestionTypeList = async (params) => {
	const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/NewDefinition/QuestionType`, params);
	return response;
};

/**
 * 編輯出處來源
 */

export const updateSource = async (params) => {
	const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Definition/Source`, params);
	return response;
};

/**
 * 新增題型表
 */
export const createQuestionType = async (params) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/NewDefinition/QuestionType`, params);
	return response;
};

/**
 * 新增出處來源
 */
export const createSource = async (params) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Definition/Source`, params);
	return response;
};

/**
 * 取得產品列表
 */
export const getProduct = async (year) => {
	const response = await Api.get(`${process.env.REACT_APP_RESOURCE_PLATFORM_DOMAIN}/api/Product/${year}`);
	return response;
};

/**
 * 上傳共用代碼
 */
export const importBasic = (type) => async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Definition/${type}/Import`, payload);
	return response;
};

/**
 * 上傳共用代碼-題型表
 */
export const importQuestionType = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/NewDefinition/QuestionType/Import`, payload);
	return response;
};

/**
 * 上傳共用代碼-出處表
 */
export const importSource = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Definition/Source/Import`, payload);
	return response;
};

/**
 * 上傳共用代碼-學制科目表
 */
export const importEduSubject = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Definition/EduSubject/Import`, payload);
	return response;
};

export const getEnglishListeningQuestionTypeTableData = async () => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/definitionv2/enlquestiontype`);
	return response;
};

/**
 * 上傳共用代碼-英聽題型表
 */
export const importEnglishListeningQuestionType = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/definitionv2/enlquestiontype/import`, payload);
	return response;
};

/**
 * 新增英聽題型表
 */
export const createEnglishListeningQuestionType = async (params) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/definitionv2/enlquestiontype/insert`, params);
	return response;
};

/**
 * 編輯英聽題型表
 */

export const updateEnglishListeningQuestionTypeList = async (params) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/definitionv2/enlquestiontype/edit`, params);
	return response;
};

/**
 * 匯出共用代碼-英聽題型表
 */
export const exportEnglishListeningQuestionType = async () => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/definitionv2/enlquestiontype/export`);
	return response;
};
