import React from "react";
import PropTypes from "prop-types";

import { UiRandomIcon } from "./RandomIcon.style";

const colorList = [
	"#FD8B01",
	"#FF4B40",
	"#FF4085",
	"#FF40EC",
	"#B640FF",
	"#7240FF",
	"#7240FF",
	"#4081FF",
	"#31A7EA",
	"#2CCFC6",
	"#2CCFC6",
	"#3DA634",
];

export const RandomIcon = ({ text }) => {
	const color = colorList[Math.floor(Math.random() * colorList.length)];

	return <UiRandomIcon color={color}>{text}</UiRandomIcon>;
};

RandomIcon.propTypes = {
	text: PropTypes.string.isRequired,
};
