import React, { useState, useEffect, useMemo } from "react";
import { Button, Icon, Modal, Breadcrumb, Checkboxer, AuthProvider } from "common-components";
import { useMachine } from "@xstate/react";
import { useHistory } from "react-router-dom";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import { getAuthorityRoleDefault, putAuthorityRoleDefault } from "api/authority";
import { formatAuthority, formatAuthorityArrayToNumber } from "utils/common";
import { AUTHFUNCTIONICON, AUTHORITYOPTIONS, ROLES } from "constants/index";
import { UiTableTop, UiActionBox } from "styles";
import {
	UiAuthorityDefaultSettingPage,
	UiAuthorityDefaultSettingContent,
	UiAuthorityList,
	UiAuthorityItem,
} from "./AuthorityDefaultSettingPage.style";

/**
 * 預設身份權限
 */
const { useAuthState } = AuthProvider;
const breadcrumbLists = [
	{
		icon: "home",
		name: "首頁-異動紀錄",
		path: "/",
	},
	{
		icon: "manage_accounts",
		name: `權限管理-帳戶管理`,
		path: `/accountAuthorityManagement`,
	},
];
export const AuthorityDefaultSettingPage = () => {
	const authState = useAuthState();
	const { identity: userIdentity } = authState.context.result || {};
	const history = useHistory();
	const [authoritySwitchData, setAuthoritySwitchData] = useState({});
	const [stateCheck, setCheckState] = useState({});
	const [stateDefaultCheck, setDefaultCheckState] = useState({});
	const [identity, setIdentity] = useState("Admin");
	const [identityIndex, setIdentityIndex] = useState(0);
	const [identitySave, setIdentitySave] = useState({
		auth: "",
		index: 0,
	});
	const [showModal, setShowModal] = useState(false);
	const [state, send] = useMachine(fetchMachine, {
		services: {
			fetchData: async () => {
				const { isSuccess, message, data } = await getAuthorityRoleDefault();
				const { allowEdit = false, authorityInfo = {} } = data || {};
				return {
					isSuccess,
					message,
					allowEdit,
					authorityInfo:
						Object.keys(authorityInfo).length === 0
							? null
							: {
									Admin: authorityInfo?.Admin,
									Editor: authorityInfo?.Editor,
									Guest: authorityInfo?.Guest,
									ManagingEditor: authorityInfo?.ManagingEditor,
							  },
				};
			},
			updateData: async (_context, event) => {
				const { isSuccess, message } = await putAuthorityRoleDefault(event.payload);
				return {
					isSuccess,
					message,
				};
			},
		},
	});

	const { allowEdit, authorityInfo } = state.context.result || {};
	const authorityIdentityData = useMemo(() => {
		if (!authorityInfo) return [];

		return Object.entries(authorityInfo[identity].function).map(([key, value]) => ({
			code: value.code,
			name: value.name,
			authority: value.authority,
			icon: AUTHFUNCTIONICON[value.code],
			table: Object.entries(value.table).map(([itemKey, item]) => ({ ...item })),
		}));
	}, [identity, authorityInfo]);

	const isSaveBtnDisabled = useMemo(() => {
		if (!stateCheck || !stateDefaultCheck) return true;
		return JSON.stringify(stateCheck) === JSON.stringify(stateDefaultCheck);
	}, [stateCheck]);
	const onCheckChange = (parentKey, key, value) => {
		setCheckState({
			...stateCheck,
			[parentKey]: {
				...stateCheck[parentKey],
				[key]: formatAuthorityArrayToNumber(value),
			},
		});
	};

	const sendData = () => {
		let payload = {
			code: identity,
			defaultAuthority: Object.entries(stateCheck).map(([key, val]) => ({
				code: key,
				authority: authoritySwitchData[key],
				table: val,
			})),
		};
		send(FetchEventType.Update, { payload });
	};

	useEffect(() => {
		if (userIdentity !== "Admin") {
			history.push("/");
			return;
		}
		send(FetchEventType.Fetch);
	}, []);

	useEffect(() => {
		if (authorityIdentityData.length === 0 || !authorityIdentityData) return;
		let nextData = {};
		let nextAuth = {};
		authorityIdentityData.forEach((item) => {
			let childData = {};
			item.table.forEach((ele) => {
				childData = { ...childData, [ele.code]: ele.authority };
			});
			nextAuth = { ...nextAuth, [item.code]: item.authority };
			nextData = { ...nextData, [item.code]: childData };
		});
		setAuthoritySwitchData(nextAuth);
		setCheckState(nextData);
		setDefaultCheckState(nextData);
	}, [authorityIdentityData]);

	return (
		<UiAuthorityDefaultSettingPage>
			<UiTableTop>
				<div className="breadcrumb">
					<Breadcrumb data={breadcrumbLists} />
				</div>
			</UiTableTop>
			<UiAuthorityDefaultSettingContent p={4}>
				<div className="header">
					<UiAuthorityList barindex={identityIndex}>
						{authorityInfo &&
							Object.entries(authorityInfo).map(([key], index) => {
								return (
									<div key={key}>
										<UiAuthorityItem
											active={key === identity}
											onClick={() => {
												if (!isSaveBtnDisabled) {
													setIdentitySave({
														auth: key,
														index,
													});
													setShowModal(true);
												} else {
													setIdentitySave({});
													setIdentity(key);
													setIdentityIndex(index);
												}
											}}>
											<img src={`/assets/roles/${key}.png`} alt={`${key}`} />
											<div>{ROLES[key]}</div>
										</UiAuthorityItem>
									</div>
								);
							})}
					</UiAuthorityList>
					<UiActionBox>
						<Button
							variant="white"
							full={false}
							disabled={isSaveBtnDisabled}
							onClick={() => {
								setCheckState({ ...stateDefaultCheck });
							}}>
							取消
						</Button>
						<Button
							variant="green"
							disabled={isSaveBtnDisabled}
							full={false}
							onClick={() => {
								sendData();
							}}>
							儲存
						</Button>
					</UiActionBox>
				</div>
				<div className="userAuthority">
					<div className="userAuthorityList">
						{authorityIdentityData &&
							authorityIdentityData.length > 0 &&
							authorityIdentityData.map((item) => {
								return (
									<>
										<div className="userAuthorityDetail" key={item.code}>
											<div className="userAuthorityTitle">
												<Icon name={item.icon} />
												<div className="userAuthorityTitleText">{item.name}</div>
											</div>
											<div className="userAuthorityDetailList">
												{item.table.map((ele, index) => (
													<>
														<div key={index}>
															<Checkboxer
																label={ele.name}
																options={AUTHORITYOPTIONS}
																disabled={!allowEdit}
																onChange={(value) => {
																	onCheckChange(item.code, ele.code, value);
																}}
																value={
																	stateCheck[item.code]
																		? Object.entries(formatAuthority(stateCheck[item.code][ele.code]))
																				.filter(([, value]) => value === 1)
																				.map(([key]) => `${key}`)
																		: []
																}
															/>
														</div>
													</>
												))}
											</div>
										</div>
									</>
								);
							})}
					</div>
				</div>
			</UiAuthorityDefaultSettingContent>
			<Modal.WarningModal
				message={"要切換身份，是否要儲存？"}
				visible={showModal}
				onOk={() => {
					sendData();
					setIdentity(identitySave.auth);
					setIdentityIndex(identitySave.index);
					setShowModal(false);
				}}
				onCancel={() => {
					setIdentity(identitySave.auth);
					setIdentityIndex(identitySave.index);
					setIdentitySave("");
					setShowModal(false);
				}}
			/>
		</UiAuthorityDefaultSettingPage>
	);
};

AuthorityDefaultSettingPage.propTypes = {};
