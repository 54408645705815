import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMachine } from "@xstate/react";
import { format } from "date-fns";
import { Timeline, Modal as AntdModal, Select as AntdSelect } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { UiTop, UiActionBox } from "styles";
import { Button, Select, Input, Table, Pagination, Breadcrumb, Form } from "common-components";
import useQuery from "utils/hooks/useQuery";
import { EDUCATIONAL_LIST, versionList } from "constants/index";
import { subjectsObj } from "constants/index";
import { UiNewSubjectPage, UiNewSubjectTag } from "../common.style";
import fetchMachine, { FetchEventType, FetchStates } from "machines/fetchMachine";
import {
	getNewSubjectChapterBook,
	restoreNewSubjectChapterBook,
	getNewSubjectBookHistory,
	getNewSubjectChapterBookSelection,
	getNewSubjectVolumesSelection,
} from "api/chapter/index";
import { sliceTableData, setSelectOptions } from "utils/common";
import { getNewSubjectChapterBookHistory } from "api/chapter/index";
import { UiCustomDrawer, CustomDrawerContent, UiCustomModal, UiCustomSelect } from "./ChapterDetailPage.style";
import openNotificationWithIcon from "utils/hooks/useNotification";

const { Header, Body, Row, Item } = Table;
const { confirm } = AntdModal;

export const ChapterDetailPage = () => {
	const history = useHistory();

	const edu = useQuery().get("edu");
	const subject = useQuery().get("subject");
	const bookID = useQuery().get("bookId");
	const curriculum = useQuery().get("curriculum");

	const [pageState, setPageState] = useState({
		page: 1,
		limit: 10,
	});
	const [open, setOpen] = useState(false);
	const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
	const [searchParams, setSearchParams] = useState({});
	const [filterChapters, setFilterChapters] = useState([]);

	const eduName = EDUCATIONAL_LIST[edu];
	const subjectName = subjectsObj[subject];

	// 取得所有章節資料
	const [selectionState, selectSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const payload = {
					bookIDs: [event.bookID],
				};
				const response = await getNewSubjectChapterBook(payload);

				if (response.data.bookChapters.length > 0) {
					const { maintainer, createTime, chapters } = response.data.bookChapters[0];

					const _queryString = `?edu=${edu}&subject=${subject}&bookId=${event.bookID}&curriculum=${
						searchParams?.curriculum ?? curriculum
					}`;
					history.push(`/newSubject/chapter/detail/${_queryString}`);

					setFilterChapters(chapters);

					return {
						creator: maintainer,
						createTime: createTime ? format(new Date(createTime), "yyyy/MM/dd HH:mm") : createTime,
						chapters: chapters,
					};
				} else {
					openNotificationWithIcon("error", "查無資料");

					setFilterChapters([]);
					return {
						creator: "",
						createTime: "",
						chapters: [],
					};
				}
			},
		},
	});

	const { creator, createTime, chapters } = selectionState.context.result || {};

	// 取得所有版本紀錄
	const [historyState, historySend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const payload = {
					bookID: event.bookID,
				};
				const response = await getNewSubjectChapterBookHistory(payload);
				const response_ = response.data.map(({ id, maintainer, fileUrl, createTime, restoreTime }) => ({
					id,
					creator: maintainer,
					fileUrl,
					createTime: format(new Date(createTime), "yyyy/MM/dd HH:mm"),
					restoreTime: restoreTime && format(new Date(restoreTime), "yyyy/MM/dd HH:mm"),
				}));

				setOpen(true);

				return response_;
			},
		},
	});

	const historyData = historyState.context.result || [];

	// 確定還原課本章節
	const [, restoreSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const payload = {
					id: event.id,
				};

				const response = await restoreNewSubjectChapterBook(payload);
				if (response.error === 200) {
					openNotificationWithIcon("success", "還原成功");
					showDrawer();
					setIsPreviewModalOpen(false);
				} else {
					openNotificationWithIcon("error", `上傳失敗！ ${response.message}`);
				}
			},
		},
	});

	// 取得預覽資料
	const [previewState, previewSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const payload = {
					id: event.id,
				};

				const { data } = await getNewSubjectBookHistory(payload);

				setIsPreviewModalOpen(true);
				return {
					...data,
					pretestCreateTime: format(new Date(data.createTime), "yyyy/MM/dd"),
					pretestCreator: data.maintainer,
					isPretestRestore: !!data.restoreTime,
				};
			},
		},
	});

	const { id: pretestId, chapters: pretestChapter, pretestCreateTime, pretestCreator, isPretestRestore } =
		previewState.context.result || {};

	// 取得所有下拉選單資料
	const [selectState, selectionSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context) => {
				const response = await getNewSubjectChapterBookSelection();

				const { yearMap, curriculumMap, versionMap } = response.data;

				const regex = /[^-](?=-[^-]*$)/;

				const defaultVolume = bookID.slice(-3);
				const defaultVersion = bookID.match(regex)[0];
				const defaultYear = bookID.slice(0, 3);
				const defaultCurriculum = curriculum;

				setSearchParams((prev) => {
					return {
						...prev,
						year: defaultYear,
						version: defaultVersion,
						volume: defaultVolume,
						curriculum: defaultCurriculum,
					};
				});

				return {
					yearMap: setSelectOptions(yearMap),
					curriculumMap: setSelectOptions(curriculumMap),
					versionMap: setSelectOptions(versionMap),
				};
			},
		},
	});

	const { yearMap, curriculumMap, versionMap } = selectState.context.result || {};

	// 取得冊次資料
	const [volumeState, volumeSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context) => {
				const payload = {
					eduSubject: `${edu}${subject}`,
				};
				const { data } = await getNewSubjectVolumesSelection(payload);

				return {
					volumeMap: setSelectOptions(data),
				};
			},
		},
	});

	const { volumeMap } = volumeState.context.result || {};
	const currentVolume = volumeMap?.find((item) => item.value === searchParams.volume);

	const breadcrumbName =
		currentVolume?.name && searchParams.version
			? `${currentVolume?.name} [${versionList[searchParams.version]}版]`
			: "";

	const breadcrumbGroup = [
		{
			icon: "home",
			name: "首頁",
			path: "/",
		},
		{
			icon: "format_list_bulleted",
			name: "新科目資料",
			path: "/newSubject",
		},
		{
			name: `${eduName} - ${subjectName}`,
			path: `/newSubject/info?edu=${edu}&subject=${subject}`,
		},
		{
			name: "課本章節總表",
			path: `/newSubject/chapter?edu=${edu}&subject=${subject}`,
		},
		{
			name: breadcrumbName,
			path: `/newSubject/chapter?edu=${edu}&subject=${subject}`,
		},
	];

	const pageChange = (page, limit) => {
		setPageState({
			page,
			limit,
		});
	};

	useEffect(() => {
		if (!bookID) return;
		selectSend(FetchEventType.Fetch, {
			bookID,
		});
	}, [bookID]);

	const showDrawer = () => {
		historySend(FetchEventType.Fetch, {
			bookID,
		});
	};

	const onClose = () => {
		setOpen(false);
	};

	const handleConfirmRestore = (createTime, creator, id) => {
		confirm({
			title: "確認要還原此版本紀錄嗎？",
			icon: <ExclamationCircleOutlined />,
			okText: "確認還原",
			cancelText: "取消",
			content: (
				<div>
					<p style={{ color: "#FF4D4F" }}>還原後將取代既有版本</p>
					<div>
						<div>{`還原版本：${createTime}`}</div>
						<div>{`編輯者： ${creator}`}</div>
					</div>
				</div>
			),
			onOk() {
				restoreSend(FetchEventType.Fetch, {
					id,
				});
			},
			onCancel() {},
		});
	};

	const handlePreview = (id) => {
		previewSend(FetchEventType.Fetch, {
			id,
		});
	};

	// 查詢
	const onSearchHandle = () => {
		const { year, version, volume } = searchParams;

		const bookID = `${year}${version}-${edu}${subject}${volume}`;

		selectSend(FetchEventType.Fetch, {
			bookID,
		});
	};

	// 搜尋知識點
	const handleSearchKnowledge = (e) => {
		const { value } = e.target;

		let filteredData = chapters
			.map((data) => {
				let filteredAge = data.knowledgeIndexs.filter((item) => item.code.includes(value) || item.name.includes(value));
				return {
					code: data.code,
					name: data.name,
					knowledgeIndexs: filteredAge,
				};
			})
			.filter((obj) => obj.knowledgeIndexs.length > 0);

		setFilterChapters(filteredData);
	};

	useEffect(() => {
		selectionSend(FetchEventType.Fetch);
		volumeSend(FetchEventType.Fetch);
	}, []);

	return (
		<div>
			<UiTop>
				<UiActionBox>
					<Button.IconButton
						variant="white"
						full={false}
						iconName="keyboard_backspace"
						onClick={() => {
							history.push(`/newSubject/chapter?edu=${edu}&subject=${subject}`);
						}}>
						回上層
					</Button.IconButton>
					{edu && subject && <Breadcrumb data={breadcrumbGroup} />}
				</UiActionBox>
			</UiTop>
			<UiNewSubjectPage>
				<div className="header">
					<div className="title">課本章節總表</div>
					<UiNewSubjectTag isYear={true}>{searchParams.year} 學年度</UiNewSubjectTag>
					<UiNewSubjectTag>{searchParams.curriculum} 課綱</UiNewSubjectTag>

					<UiActionBox style={{ marginLeft: "auto" }}>
						<UiCustomSelect
							defaultValue="匯出章節表"
							options={[
								{
									value: "N",
									label: "南一章節表",
								},
								{
									value: "W",
									label: "萬通章節表",
								},
							]}
						/>
					</UiActionBox>
				</div>
				<div className="search">
					<UiTop>
						<UiActionBox>
							<Select
								options={yearMap}
								value={searchParams.year}
								onChange={(value) => setSearchParams({ ...searchParams, year: value })}
							/>
							<Select
								options={curriculumMap}
								value={searchParams.curriculum}
								onChange={(value) => setSearchParams({ ...searchParams, curriculum: value })}
							/>
							<Select
								options={versionMap}
								value={searchParams.version}
								onChange={(value) => setSearchParams({ ...searchParams, version: value })}
							/>
							<Select
								options={volumeMap}
								value={searchParams.volume}
								onChange={(value) => setSearchParams({ ...searchParams, volume: value })}
							/>
							<Button.IconButton variant="blue" full={false} iconName="search" onClick={onSearchHandle}>
								查詢
							</Button.IconButton>
						</UiActionBox>
						<UiActionBox>
							<Input.Search mode="dark" placeholder="搜尋知識點" onChange={(e) => handleSearchKnowledge(e)} />
						</UiActionBox>
					</UiTop>
				</div>
				<div className="history">
					<div>{creator && createTime && `最近異動：${creator} ${createTime}`}</div>
					<Button.IconButton variant="blue" full={false} iconName="pending_actions" onClick={showDrawer} size="small">
						版本紀錄
					</Button.IconButton>
				</div>
				<Table margin={20}>
					<Header>
						<Row>
							<Item flex={0.5}>範圍代碼</Item>
							<Item flex={2}>範圍名稱</Item>
							<Item className="remarkIcon" flex={3}>
								知識點
							</Item>
							<Item>二次段考別</Item>
							<Item>三次段考別</Item>
							<Item>備註</Item>
						</Row>
					</Header>
					<Body isLoading={!selectionState.matches(FetchStates.Resolved)}>
						{filterChapters &&
							sliceTableData(filterChapters, pageState.page, pageState.limit).map((item) => {
								return (
									<Row key={item.code}>
										<Item flex={0.5}>{item.code}</Item>
										<Item flex={2}>{item.name}</Item>
										<Item flex={3}>
											<div>
												{item.knowledgeIndexs?.map((knowledge) => {
													return (
														<div key={knowledge.code}>
															{knowledge.code}｜{knowledge.name}
														</div>
													);
												})}
											</div>
										</Item>
										<Item>{item.exam2}</Item>
										<Item>{item.exam3}</Item>
										<Item>{item.remark}</Item>
									</Row>
								);
							})}
					</Body>
				</Table>
				<Pagination
					defaultCurrent={1}
					current={pageState.page}
					onChange={pageChange}
					total={filterChapters?.length}
					defaultPageSize={pageState.limit}
				/>
				<CustomDrawerContent
					title={
						<div style={{ color: "#1890FF" }}>
							版本紀錄 - {currentVolume?.name} [{versionList[searchParams.version]}版]
						</div>
					}
					onClose={onClose}
					getContainer={false}
					visible={open}
					className="test"
					width="568px">
					<div>
						<Timeline>
							{historyData.map((item, index) => {
								return (
									<Timeline.Item
										key={item.id}
										dot={
											<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
												<rect x="-0.000488281" width="10" height="10" rx="5" fill="#1890FF" />
											</svg>
										}>
										<UiCustomDrawer isNow={index === 0}>
											<div className="container">
												<div style={{ width: "210px" }}>
													<div className="title">
														{item.createTime} {index === 0 && <span>(目前)</span>}
													</div>
													<div className="description">
														<div>{item.creator}</div>
														{item.restoreTime && (
															<div style={{ display: "flex", gap: "8px" }}>
																<UiNewSubjectTag style={{ margin: "0px" }}>還原</UiNewSubjectTag>
																<div>{item.restoreTime}</div>
															</div>
														)}
													</div>
												</div>
												<div className="buttonGroup">
													{index !== 0 && (
														<>
															<Button.IconButton
																size="small"
																variant="blue"
																iconName="visibility"
																onClick={() => handlePreview(item.id)}>
																預覽
															</Button.IconButton>
															{!item.restoreTime && (
																<Button.IconButton
																	size="small"
																	variant="blue"
																	iconName="replay"
																	onClick={() => handleConfirmRestore(item.createTime, item.creator, item.id)}>
																	還原
																</Button.IconButton>
															)}
														</>
													)}
													<UiActionBox style={{ alignItems: "start" }}>
														<UiCustomSelect
															defaultValue="匯出章節表"
															options={[
																{
																	value: "N",
																	label: "南一章節表",
																},
																{
																	value: "W",
																	label: "萬通章節表",
																},
															]}
															size="small"
														/>
													</UiActionBox>
												</div>
											</div>
										</UiCustomDrawer>
									</Timeline.Item>
								);
							})}
						</Timeline>
					</div>
				</CustomDrawerContent>

				{/* 版本紀錄預覽modal */}
				<UiCustomModal
					visible={isPreviewModalOpen}
					onCancel={() => setIsPreviewModalOpen(false)}
					okText="還原此版本"
					onOk={() => handleConfirmRestore(pretestCreateTime, pretestCreator, pretestId)}
					title={
						<div style={{ display: "flex", alignItems: "center", color: "#1890FF" }}>
							{`版本紀錄 - ${pretestCreateTime} [預覽]`}
							{isPretestRestore && <UiNewSubjectTag>還原</UiNewSubjectTag>}
						</div>
					}
					width="930px"
					restore={isPretestRestore}>
					<Table margin={20}>
						<Header>
							<Row>
								<Item flex={0.5}>範圍代碼</Item>
								<Item flex={2}>範圍名稱</Item>
								<Item className="remarkIcon" flex={3}>
									知識點
								</Item>
								<Item>二次段考別</Item>
								<Item>三次段考別</Item>
								<Item>備註</Item>
							</Row>
						</Header>
						<Body isLoading={!selectionState.matches(FetchStates.Resolved)}>
							{pretestChapter &&
								pretestChapter.map((item) => {
									return (
										<Row key={item.code}>
											<Item flex={0.5}>{item.code}</Item>
											<Item flex={2}>{item.name}</Item>
											<Item flex={3}>
												<div>
													{item.knowledgeIndexs.map((knowledge) => {
														return (
															<div key={knowledge.code}>
																{knowledge.code}｜{knowledge.name}
															</div>
														);
													})}
												</div>
											</Item>
											<Item>{item.exam2}</Item>
											<Item>{item.exam3}</Item>
											<Item>{item.remark}</Item>
										</Row>
									);
								})}
						</Body>
					</Table>
				</UiCustomModal>
			</UiNewSubjectPage>
		</div>
	);
};
