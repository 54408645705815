import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as XLSX from "xlsx";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal as AntdModal } from "antd";
import { v4 as uuidv4 } from "uuid";
import { useMachine } from "@xstate/react";
import fetchMachine, { FetchEventType } from "machines/fetchMachine/index";

import useQuery from "utils/hooks/useQuery";
import { UiTop, UiActionBox } from "styles";
import { Breadcrumb, Button, Select, Upload } from "common-components";
import { EDUCATIONAL_LIST, naniExcelHeader, subjectsObj } from "constants/index";
import { UiNewSubjectChapterTag, UiNewSubjectChapterModal } from "./ChapterPage.style";
import { UiNewSubjectPage } from "../common.style";
import { createValidator, required } from "utils/validation";
import { useFirebaseStorage } from "utils/hooks/useFirebaseStorage";
import { importNewSubjectChapterBook } from "api/chapter/index";
import openNotificationWithIcon from "utils/hooks/useNotification";
import {
	getNewSubjectChapterBookList,
	getNewSubjectChapterBookInfo,
	getNewSubjectChapterBookSelection,
} from "api/chapter";
import { setSelectOptions } from "utils/common";
import { getBasicTableData } from "api/definition";
import { BookComponent } from "../common/BookComponent";

const { confirm } = AntdModal;

export const NewSubjectChapterPage = () => {
	const history = useHistory();

	const { upload } = useFirebaseStorage();

	const [uploadPopup, setUploadPopup] = useState({
		visible: false,
		loading: false,
	});
	const [fileHeader, setFileHeader] = useState([]);
	const [fileData, setFileData] = useState({});
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [uploadFile, setUploadFile] = useState({});
	const [stateFormParams, setFormParams] = useState({});
	const [searchParams, setSearchParams] = useState({});
	const [isFirst, setIsFirst] = useState(true);

	const edu = useQuery().get("edu");
	const subject = useQuery().get("subject");

	const eduName = EDUCATIONAL_LIST[edu];
	const subjectName = subjectsObj[subject];

	const breadcrumbGroup = [
		{
			icon: "home",
			name: "首頁",
			path: "/",
		},
		{
			icon: "format_list_bulleted",
			name: "新科目資料",
			path: "/newSubject",
		},
		{
			name: `${eduName} - ${subjectName}`,
			path: `/newSubject/info?edu=${edu}&subject=${subject}`,
		},
		{
			name: "課本章節總表",
			path: `/newSubject/chapter?edu=${edu}&subject=${subject}`,
		},
	];

	const onFormSelectChangeHandle = (value, key) => {
		setIsSubmitted(false);
		setFormParams({
			...stateFormParams,
			[key]: {
				value,
				message: "",
			},
		});
	};

	const toggleUploadPopupVisible = () => {
		setFormParams({});
		setUploadPopup({
			...uploadPopup,
			visible: !uploadPopup.visible,
		});
		setUploadFile({});
	};

	const changeChapterFile = (file) => {
		handleFileUpload(file);

		setUploadFile((prev) => {
			return {
				...prev,
				chapterFile: file,
			};
		});
	};

	const handleFileUpload = (event) => {
		if (event) {
			const file = event[0];
			const reader = new FileReader();

			reader.onload = (e) => {
				const data = new Uint8Array(e.target.result);
				const workbook = XLSX.read(data, { type: "array" });
				const firstSheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[firstSheetName];
				const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
				setFileHeader(headers);
				setFileData((prev) => ({
					...prev,
					edu: worksheet.B2?.v,
					subject: worksheet.C2?.v,
				}));
			};

			if (file) {
				reader.readAsArrayBuffer(file);
			}
		}
	};

	// 判斷 excel 欄位是否符合條件
	const validateExcelHeader = (type) => {
		// 	判斷是南一或是萬通格式
		const sortList = naniExcelHeader;

		if (type) {
			return sortList.every((item, index) => item === fileHeader?.[index]);
		}
	};

	// 取得版本下拉選單資料(modal)
	const [versionMapState, versionMapStateSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const { data } = await getBasicTableData();
				const versionMap = data.data["PUBLISHER"].map((item) => ({
					name: item.name,
					value: item.code,
				}));

				return {
					versionMap,
				};
			},
		},
	});

	const { versionMap } = versionMapState.context.result || {};

	// 取得課本章節總表
	const [chapterListState, chapterListStateSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const response = await getNewSubjectChapterBookList(event);
				setIsFirst(false);

				return {
					chapterList: response.data,
				};
			},
		},
	});

	const { chapterList } = chapterListState.context.result || {};

	const getMaxCode = (items) => {
		return items.reduce((prev, current) => (Number(prev.code) > Number(current.code) ? prev : current));
	};

	// 取得所有下拉選單資料
	const [selectState, selectionSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context) => {
				const response = await getNewSubjectChapterBookSelection();

				const { yearMap, curriculumMap } = response.data;

				const maxYear = getMaxCode(yearMap);
				const maxCurriculum = getMaxCode(curriculumMap);

				setSearchParams((prev) => {
					return {
						...prev,
						curriculum: maxCurriculum.code,
						year: maxYear.code,
					};
				});

				return {
					yearMap: setSelectOptions(yearMap),
					curriculumMap: setSelectOptions(curriculumMap),
				};
			},
		},
	});

	const { yearMap, curriculumMap } = selectState.context.result || {};

	const handleToDetailPage = (bookId) => {
		history.push(
			`/newSubject/chapter/detail/?edu=${edu}&subject=${subject}&bookId=${bookId}&curriculum=${searchParams.curriculum}`,
		);
	};

	const onSearchHandle = () => {
		if (!searchParams.year || !searchParams.curriculum) return;

		const { year, curriculum } = searchParams;

		chapterListStateSend(FetchEventType.Fetch, {
			year,
			curriculum,
			eduSubject: `${edu}${subject}`,
		});
	};

	useEffect(() => {
		if (searchParams.year && searchParams.curriculum && isFirst) {
			const { year, curriculum } = searchParams;

			chapterListStateSend(FetchEventType.Fetch, {
				year,
				curriculum,
				eduSubject: `${edu}${subject}`,
			});
		}
	}, [searchParams.curriculum, searchParams.year]);

	// 進入畫面，取得學年度
	useEffect(() => {
		selectionSend(FetchEventType.Fetch);
		versionMapStateSend(FetchEventType.Fetch);
	}, []);

	const handleImportConfirm = async () => {
		const uid = uuidv4();
		let chapterResult = {};

		const chapterInfo = await upload(`chapter/${uid}-${uploadFile.chapterFile[0].name}`, uploadFile.chapterFile[0]);

		const chapterPayload = {
			curriculum: stateFormParams.curriculum.value,
			eduSubject: `${edu}${subject}`,
			version: stateFormParams.version.value,
			year: stateFormParams.year.value,
			url: chapterInfo.url,
			fileName: uploadFile.chapterFile[0].name,
		};

		chapterResult = await importNewSubjectChapterBook(chapterPayload);

		if (chapterResult?.error === 200) {
			openNotificationWithIcon("success", "上傳成功");
			toggleUploadPopupVisible();
		} else {
			openNotificationWithIcon("error", `上傳失敗！ ${chapterResult.message}`);
			toggleUploadPopupVisible();
		}
	};

	// 取得匯入冊次資訊
	const [, VolumeInfoSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const response = await getNewSubjectChapterBookInfo(event);
				const { data } = response;

				if (response.error === 200) {
					confirm({
						title: "請再次確認上傳資料是否正確",
						icon: <ExclamationCircleFilled />,
						okText: "確認上傳",
						content: (
							<div>
								<p style={{ color: "#FF4D4F" }}>再次上傳時將取代原本已有的資料</p>
								<div>
									<div>{`學制科目：${eduName} - ${subjectName}`}</div>
									<div>冊次：{data.join(", ")}</div>
								</div>
							</div>
						),
						onOk() {
							handleImportConfirm();
						},
						onCancel() {},
					});
				}
			},
		},
	});

	const showConfirm = async () => {
		const uid = uuidv4();

		setIsSubmitted(true);
		let rules = {
			year: [{ message: "適用年度欄位不得空白", validate: required }],
			curriculum: [{ message: "課綱欄位不得空白", validate: required }],
			version: [{ message: "版本欄位不得空白", validate: required }],
		};

		const inputValues = {
			year: "",
			curriculum: "",
			version: "",
		};

		Object.entries(stateFormParams).forEach(([key, value]) => {
			inputValues[key] = value.value;
		});

		const [isPass, errorGroup] = createValidator(inputValues, rules);
		setFormParams({ ...stateFormParams, ...errorGroup });
		if (isPass || uploadFile.chapterFile?.length === 0) return;

		const chapterInfo = await upload(`chapter/${uid}-${uploadFile.chapterFile[0].name}`, uploadFile.chapterFile[0]);

		VolumeInfoSend(FetchEventType.Fetch, {
			curriculum: stateFormParams.curriculum.value,
			eduSubject: `${edu}${subject}`,
			version: stateFormParams.version.value,
			year: stateFormParams.year.value,
			url: chapterInfo.url,
			fileName: uploadFile.chapterFile[0].name,
		});
	};

	const fileErrorMessage = () => {
		const { edu, subject } = fileData;
		if (isSubmitted) {
			if (uploadFile.chapterFile?.length === 0) {
				return "請上傳檔案";
			} else if (!validateExcelHeader("chapter")) {
				return "欄位名稱錯誤，請重新上傳";
			} else if (edu !== eduName || subject !== subjectName) {
				return "學制科目錯誤";
			} else {
				return "";
			}
		}
	};

	return (
		<div>
			<UiTop>
				<UiActionBox>
					<Button.IconButton
						variant="white"
						full={false}
						iconName="keyboard_backspace"
						onClick={() => {
							history.push(`/newSubject/info?edu=${edu}&subject=${subject}`);
						}}>
						回上層
					</Button.IconButton>
					{edu && subject && <Breadcrumb data={breadcrumbGroup} />}
				</UiActionBox>
			</UiTop>
			<UiNewSubjectPage>
				<div className="header">
					<div className="title">課本章節總表</div>
					<UiNewSubjectChapterTag isYear={true}>{searchParams.year} 學年度</UiNewSubjectChapterTag>
					<UiNewSubjectChapterTag>{searchParams.curriculum} 課綱</UiNewSubjectChapterTag>

					<UiActionBox style={{ marginLeft: "auto" }}>
						<Button.IconButton iconName="file_download" textColor="black" type="outLined">
							下載範本
						</Button.IconButton>
						<Button.IconButton iconName="lightbulb" textColor="black" type="outLined">
							入庫流程說明
						</Button.IconButton>
					</UiActionBox>
				</div>
				<div className="search">
					<UiTop>
						<UiActionBox>
							<Select
								options={yearMap}
								value={searchParams.year}
								onChange={(value) => setSearchParams({ ...searchParams, year: value })}
							/>
							<Select
								options={curriculumMap}
								value={searchParams.curriculum}
								onChange={(value) => setSearchParams({ ...searchParams, curriculum: value })}
							/>
							<Button.IconButton variant="blue" full={false} iconName="search" onClick={onSearchHandle}>
								查詢
							</Button.IconButton>
						</UiActionBox>
						<UiActionBox>
							<Button.IconButton
								onClick={toggleUploadPopupVisible}
								iconName="post_add"
								variant="yellow"
								textColor="black">
								新增版本章節
							</Button.IconButton>
						</UiActionBox>
					</UiTop>
				</div>

				{chapterList &&
					Object.keys(chapterList).map((item) => {
						return (
							<BookComponent key={item} title={item} list={chapterList[item]} handleToDetailPage={handleToDetailPage} />
						);
					})}

				<UiNewSubjectChapterModal
					confirmLoading={uploadPopup.loading}
					visible={uploadPopup.visible}
					onCancel={toggleUploadPopupVisible}
					okText={uploadPopup.loading ? "上傳中..." : "上傳"}
					onOk={showConfirm}
					title={<div style={{ color: "#1890FF" }}>{`新增版本章節：${eduName} - ${subjectName}`}</div>}
					width="560px">
					<div className="modalContent">
						<Select
							label="適用年度"
							options={yearMap}
							value={stateFormParams.year?.value || ""}
							message={stateFormParams.year?.message || ""}
							onChange={(value) => onFormSelectChangeHandle(value, "year")}
						/>
						<Select
							label="課綱"
							options={curriculumMap}
							value={stateFormParams.curriculum?.value || ""}
							message={stateFormParams.curriculum?.message || ""}
							onChange={(value) => onFormSelectChangeHandle(value, "curriculum")}
						/>
						<Select
							label="版本"
							options={versionMap}
							value={stateFormParams.version?.value || ""}
							message={stateFormParams.version?.message || ""}
							onChange={(value) => onFormSelectChangeHandle(value, "version")}
						/>
						<div>上傳章節表</div>
						<Upload
							fileList={uploadFile.chapterFile || []}
							onChange={changeChapterFile}
							prompt={
								<>
									僅限上傳一個 Microsoft Excel 檔<br />
									(副檔名 : .xlsx)
								</>
							}
							acceptFileType=".xlsx"
							message={fileErrorMessage()}
						/>
					</div>
				</UiNewSubjectChapterModal>
			</UiNewSubjectPage>
		</div>
	);
};
