import { Api } from "common-components";
import { stringify } from "query-string";
/**
 * 獲取科目維護頁面資料
 */
export const getSelection = async () => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/SubjectMeta/Selection`);
	return response;
};

/**
 * 查詢學制科目之項目資料
 */
export const getSubjectMeta = async (payload) => {
	const { eduSubject, metaType } = payload;
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/SubjectMeta/${eduSubject}/${metaType}`);
	return response;
};

/**
 * 查詢 學制科目項目資料 新增 使用之相關資訊
 */
export const getSubjectRelated = async (payload) => {
	const { eduSubject, metaType } = payload;
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/SubjectMeta/${eduSubject}/${metaType}/Related`);
	return response;
};

/**
 * 查詢 學制科目項目資料 修改 使用之相關資訊
 */
export const getSingleSubjectRelated = async (payload, UID) => {
	const { eduSubject, metaType } = payload;
	const response = await Api.get(
		`${process.env.REACT_APP_DOMAIN}/api/SubjectMeta/${eduSubject}/${metaType}/Related/${UID}`,
	);
	return response;
};

/**
 * 查詢 學制科目項目資料 新增/修改 使用之相關資訊
 */
export const createSubjectMeta = async (queryParams, payload) => {
	const { eduSubject, metaType } = queryParams;
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/SubjectMeta/${eduSubject}/${metaType}`, payload);
	return response;
};

/**
 * 查詢 學制科目項目資料 新增/修改 使用之相關資訊
 */
export const editSubjectMeta = async (queryParams, payload) => {
	const { eduSubject, metaType } = queryParams;
	const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/SubjectMeta/${eduSubject}/${metaType}`, payload);
	return response;
};

/**
 * 鎖定科目資料table
 */
export const lockSubjectTable = async (queryParams, payload) => {
	const { eduSubject, metaType } = queryParams;
	const querystring = stringify(payload);
	const response = await Api.put(
		`${process.env.REACT_APP_DOMAIN}/api/SubjectMeta/${eduSubject}/${metaType}/Lock?${querystring}`,
	);
	return response;
};

/**
 * 匯入 學制科目項目資料
 */
export const importSubjectMeta = (queryParams) => async (payload) => {
	const { eduSubject, metaType } = queryParams;
	const response = await Api.post(
		`${process.env.REACT_APP_DOMAIN}/api/SubjectMeta/${eduSubject}/${metaType}/Import`,
		payload,
	);
	return response;
};

/**
 * 知識向度詳細資料
 */
export const getBookRelated = (queryParams) => async (payload) => {
	const { eduSubject, metaType } = queryParams;
	const querystring = stringify(payload);
	const response = await Api.get(
		`${process.env.REACT_APP_DOMAIN}/api/SubjectMeta/${eduSubject}/${metaType}/BookRelated?${querystring}`,
	);
	return response;
};
