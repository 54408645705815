import { Api } from "common-components";

export const getAuthorityRoleDefault = async () => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Authority/RoleDefault`)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return {
				isSuccess: false,
				message: error.data,
			};
		});
	return response;
};

export const putAuthorityRoleDefault = async (payload) => {
	const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Authority/RoleDefault`, payload);
	return response;
};

// 取得使用者權限
export const getAuthorityUserRelated = async (uid) => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Authority/${uid}/Related`)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return {
				isSuccess: false,
				message: error.data,
			};
		});
	return response;
};

// 修改使用者權限
export const putAuthorityUserAuthority = async (payload) => {
	const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Authority/UserAuthority`, payload);
	return response;
};
