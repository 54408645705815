import styled from "styled-components";

export const UiSubjectCard = styled.div`
	display: block;
	overflow: hidden;
	width: 100%;
	border-radius: 5px;
	cursor: pointer;

	.img {
		overflow: hidden;
		font-size: 0;

		img {
			transition: 0.5s;

			transform: scale(1.05);
		}
	}

	&:hover .img img {
		transform: scale(1.2);
		opacity: 0.65;
	}

	.title {
		display: flex;
		padding: 10px;
		font-size: 18px;
		font-weight: bold;
		background-color: #fff;
		flex-wrap: nowrap;

		.titleText {
			display: flex;
			flex: 1;
		}

		.lockInfo {
			display: flex;
			align-items: center;
			font-size: 14px;
			color: #ffb800;
			flex: 1 0 100px;
		}
	}
`;
