import React, { useState, useMemo, useEffect, useRef } from "react";
import {
	Button,
	Select,
	Input,
	Table,
	Pagination,
	Breadcrumb,
	AuthMachine,
	AuthProvider,
	Modal,
	Upload,
} from "common-components";
import { useMachine } from "@xstate/react";
import { getEduSubjectTableData, getBasicTableData, importEduSubject } from "api/definition";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getTime } from "date-fns";
import { setSelectOptions, sliceTableData, getTableAuthority, exportFile } from "utils/common";
import { UiTop, UiActionBox } from "styles";
import classnames from "classnames";
import { INIT_AUTHORITY } from "constants/index";
import { useUpload } from "utils/hooks/useUpload";
import { UiEduSubjectPage } from "./EduSubjectPage.style";

/**
 * 共用代碼-學制科目頁
 */
const { Header, Body, Row, Item } = Table;
const { state: AuthStates } = AuthMachine;
const { useAuthState } = AuthProvider;

export const EduSubjectPage = () => {
	const authState = useAuthState();
	const history = useHistory();
	const isPublicCode = useRouteMatch({ path: "/publicCode" }) ? true : false;
	const { executeUpload } = useUpload();
	const [state, setState] = useState({
		searchValue: "",
		type: "edu_subject",
	});

	const [pageState, setPageState] = useState({
		currentPage: 1,
		limit: 10,
	});

	const [authorityState, setAuthority] = useState(INIT_AUTHORITY);

	const tableRef = useRef(null);

	const [files, setFiles] = useState([]);

	const [uploadPopup, setUploadPopup] = useState({
		visible: false,
		loading: false,
	});

	const renderNewFiber = (item, systemNow) => {
		const systemNowTime = getTime(new Date(systemNow));
		const itemTime = getTime(new Date(item.createTime));
		return (
			<div key={item.uid} className="haveFiber">
				{item.name}
				{systemNowTime - itemTime < 604800000 && (
					<i
						className={
							classnames("material-icons", "fiber") //7days
						}>
						fiber_new
					</i>
				)}
			</div>
		);
	};

	const [stateEduSubject, send] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const res = await getEduSubjectTableData();
				const { data, systemNow } = res;
				return {
					data: data.map((value) => ({
						...value,
						nameText: value.name,
						name: renderNewFiber(value, systemNow),
						editState: false,
					})),
				};
			},
		},
	});
	const { data } = stateEduSubject.context.result || [];

	const filterData = useMemo(() => {
		return data?.filter((item) => item.nameText.indexOf(state.searchValue) >= 0) || [];
	}, [data, state.searchValue, pageState.limit, pageState.currentPage]);

	const [stateSelection, sendSelection] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const res = await getBasicTableData();
				const {
					data: { types: options },
				} = res;
				return {
					selectOption: setSelectOptions(options),
				};
			},
		},
	});
	const { selectOption } = stateSelection.context.result || [];
	const breadcrumbLists = useMemo(() => {
		const tableName =
			(selectOption && selectOption.filter((item) => item.value === state.type.toUpperCase())[0]?.name) || "";
		const result = [
			{
				icon: "home",
				name: "首頁-異動紀錄",
				path: "/",
			},
			{
				icon: "grid_on",
				name: `共用代碼-${tableName}總表`,
				path: `/publicCode/questionType`,
			},
		];
		return result;
	});

	const onSearchHandle = (event) => {
		const value = event.target ? event.target.value : "";
		setState({
			...state,
			searchValue: value.toString(),
		});
	};

	const changeTableType = (value) => {
		if (!isPublicCode || !value) return;
		const type = value.toLowerCase();

		setState({
			...state,
			type,
		});
		if (type === "ques_type" || type === "subject_id" || type === "source" || type === "edu_subject") {
			const routeName = {
				ques_type: "questionType",
				subject_id: "subject",
				source: "source",
				edu_subject: "eduSubject",
			};
			history.push(`/publicCode/${routeName[type]}`);
		} else {
			history.push({ pathname: "/publicCode", search: `?type=${type}` });
		}
	};

	const exportHandle = () => {
		const url = `${process.env.REACT_APP_DOMAIN}/api/Definition/EduSubject/Export`;
		exportFile(url, "get");
	};
	const pageChange = (currentPage, pageSize) => {
		setPageState({
			...pageState,
			currentPage,
			limit: pageSize,
		});
		tableRef.current.scrollIntoView();
	};

	const toggleUploadPopupVisible = () => {
		setUploadPopup({
			...uploadPopup,
			visible: !uploadPopup.visible,
		});
	};

	const changeFileList = (value) => {
		setFiles(value);
	};

	const submitFiles = async () => {
		if (files.length === 0) return;
		setUploadPopup({
			...uploadPopup,
			loading: true,
		});
		await executeUpload(files, "basic/eduSubject", importEduSubject);
		setUploadPopup({
			visible: false,
			loading: false,
		});
		setFiles([]);
		send(FetchEventType.Fetch);
	};

	useEffect(() => {
		if (!authState.matches(AuthStates.LoginDone)) return;
		const { authority, tableAuthority } = getTableAuthority(
			"EDUSUBJECT",
			authState.context.authority.function.Definition,
		);
		setAuthority({
			...authorityState,
			authority,
			tableAuthority,
		});
		send(FetchEventType.Fetch);
		sendSelection(FetchEventType.Fetch);
	}, [authState.value]);

	return (
		<UiEduSubjectPage ref={tableRef}>
			<div className="breadcrumb">
				<Breadcrumb data={breadcrumbLists} />
			</div>
			<UiTop>
				<div className="select">
					<Select
						options={selectOption}
						defaultValue={state.type ? state.type.toUpperCase() : ""}
						onChange={changeTableType}
					/>
				</div>
				<UiActionBox>
					{authorityState.tableAuthority.Import ? (
						<Button.IconButton variant="blue" full={false} iconName="cloud_upload" onClick={toggleUploadPopupVisible}>
							匯入
						</Button.IconButton>
					) : (
						""
					)}
					{authorityState.tableAuthority.Export ? (
						<Button.IconButton variant="blue" full={false} iconName="save_alt" onClick={exportHandle}>
							匯出
						</Button.IconButton>
					) : (
						""
					)}
					<div className="search">
						<Input
							placeholder="搜尋..."
							type={"search"}
							value={state.searchValue}
							onChange={(e) => onSearchHandle(e)}
						/>
					</div>
				</UiActionBox>
			</UiTop>
			<Table margin={20}>
				<Header>
					<Row>
						<Item>代碼</Item>
						<Item flex={5}>名稱</Item>
						<Item>註記</Item>
						<Item>備註</Item>
						{/* <Item>編輯</Item> */}
					</Row>
				</Header>
				<Body>
					{filterData &&
						sliceTableData(filterData, pageState.currentPage, pageState.limit).map((item) => {
							return (
								<Row key={item.code}>
									<Item>{item.code}</Item>
									<Item flex={5}>{item.name}</Item>
									<Item>
										{item.attribute
											? item.attribute.map((item, index, arr) => {
													return (
														<span key={index}>
															{item.code}
															{arr.length === index + 1 || "、"}
														</span>
													);
											  })
											: "-"}
									</Item>
									<Item>{item.remark}</Item>
								</Row>
							);
						})}
				</Body>
			</Table>
			{data && data.length > 10 && (
				<Pagination
					defaultCurrent={1}
					current={pageState.currentPage}
					onChange={pageChange}
					total={data.length}
					defaultPageSize={pageState.limit}
				/>
			)}

			<Modal
				confirmLoading={uploadPopup.loading}
				visible={uploadPopup.visible}
				onCancel={toggleUploadPopupVisible}
				okText={uploadPopup.loading ? "上傳中..." : "確定"}
				onOk={submitFiles}
				title="上傳檔案">
				<Upload
					fileList={files}
					onChange={changeFileList}
					prompt={
						<>
							僅限上傳一個Microsoft Excel 檔<br />
							(副檔名 : .xls/.xlsx)
						</>
					}
					acceptFileType=".xls,.xlsx"
				/>
			</Modal>
		</UiEduSubjectPage>
	);
};
