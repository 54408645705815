"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiStretchItem = exports.UiStretchTitle = exports.UiStretchBox = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _styledSystem = require("styled-system");

var _templateObject, _templateObject2, _templateObject3;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var UiStretchBox = _styledComponents.default.ul(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tdisplay: block;\n\tmargin: 0;\n\tpadding: 0;\n\twidth: 100%;\n\tmax-width: 250px;\n\tlist-style: none;\n\t", "\n\t.ant-checkbox-group {\n\t\twidth: 100%;\n\t}\n\n\t.ant-collapse {\n\t\tborder: 0;\n\n\t\t.ant-collapse-item {\n\t\t\tborder-bottom: 1px solid #f0f0f0;\n\t\t}\n\t}\n\n\t.ant-collapse-content {\n\t\tborder-top: 1px solid #f0f0f0;\n\n\t\t.ant-checkbox-wrapper {\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t\tpadding: 9px 16px;\n\t\t\twidth: 100%;\n\t\t}\n\t}\n\n\t.ant-collapse-header,\n\t.ant-collapse-content-box {\n\t\tpadding: 0 !important;\n\t}\n\n\t.ant-collapse-header {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: flex-end;\n\t\tflex-direction: row-reverse;\n\n\t\t.ant-checkbox-wrapper {\n\t\t\tmargin-right: 8px;\n\t\t}\n\t}\n"])), _styledSystem.space);

exports.UiStretchBox = UiStretchBox;

var UiStretchTitle = _styledComponents.default.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\tpadding: 9px 8px 9px 16px;\n\tfont-size: 14px;\n\tcolor: rgba(0, 0, 0, 0.85);\n\tbackground-color: #fafafa;\n\tuser-select: none;\n\n\t.ant-checkbox-wrapper {\n\t\tmargin-right: 10px;\n\t}\n"])));

exports.UiStretchTitle = UiStretchTitle;

var UiStretchItem = _styledComponents.default.li(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\tposition: relative;\n\tmargin: 0;\n\tfont-size: 14px;\n\tcolor: rgba(0, 0, 0, 0.65);\n\tuser-select: none;\n\n\t& > span {\n\t\tdisplay: block;\n\t\tpadding: 9px 8px 9px 16px;\n\t}\n\n\t& + li {\n\t\tborder-top: 1px solid #f0f0f0;\n\t}\n\n\t.ant-checkbox-wrapper {\n\t\tmargin-right: 10px;\n\t}\n"])));

exports.UiStretchItem = UiStretchItem;