export const ELEMENTARY_LIST = [
	{
		name: "國小 國語",
		value: "ECH",
	},
	{
		name: "國小 英語",
		value: "EEN",
	},
	{
		name: "國小 數學",
		value: "EMA",
	},
	{
		name: "國小 自然與生活科技",
		value: "ENA",
	},
	{
		name: "國小 社會",
		value: "ESO",
	},
	{
		name: "國小 生活",
		value: "ELI",
	},
];

export const SECONDARY_LIST = [
	{
		name: "國中 國文",
		value: "JPC",
	},
	{
		name: "國中 華語",
		value: "JCN",
	},
	{
		name: "國中 英文",
		value: "JEN",
	},
	{
		name: "國中 數學",
		value: "JMA",
	},
	{
		name: "國中 生物",
		value: "JBI",
	},
	{
		name: "國中 理化",
		value: "JPY",
	},
	{
		name: "國中 地球科學",
		value: "JEA",
	},
	{
		name: "國中 地理",
		value: "JGE",
	},
	{
		name: "國中 歷史",
		value: "JHI",
	},
	{
		name: "國中 公民",
		value: "JCT",
	},
];

export const HIGH_LIST = [
	{
		name: "高中 國文",
		value: "HPC",
	},
	{
		name: "高中 華語",
		value: "HCN",
	},
	{
		name: "高中 英文",
		value: "HEW",
	},
	{
		name: "高中 數學",
		value: "HMA",
	},
	{
		name: "高中 生物",
		value: "HBI",
	},
	{
		name: "高中 物理",
		value: "HPH",
	},
	{
		name: "高中 化學",
		value: "HCE",
	},
	{
		name: "高中 地球科學",
		value: "HEA",
	},
	{
		name: "高中 地理",
		value: "HGE",
	},
	{
		name: "高中 歷史",
		value: "HHI",
	},
	{
		name: "高中 公民與社會",
		value: "HCS",
	},
];

export const SPECIAL_LIST = [
	{
		name: "國小 健康與體育",
		value: "EPE",
	},
	{
		name: "國中 健康與體育",
		value: "JPE",
	},
	{
		name: "國中 綜合活動",
		value: "JCO",
	},
	{
		name: "國中 科技",
		value: "JTC",
	},
	{
		name: "國中 資訊科技",
		value: "JIT",
	},
	{
		name: "國中 生活科技",
		value: "JLT",
	},
];
