"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiUpload = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var UiUpload = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tdisplay: block;\n\tmargin-top: 15px !important;\n\n\t& > .noFile {\n\t\tpadding: 5px 0;\n\t\tcolor: #d6d6d6;\n\t}\n\n\t& > .fileList {\n\t\tdisplay: flex;\n\t\tjustify-content: space-between;\n\t\tmargin: 10px 0;\n\n\t\t& > i {\n\t\t\tcursor: pointer;\n\t\t}\n\t}\n\n\t.ant-upload-drag-icon {\n\t\tmargin-bottom: 0;\n\n\t\t& > i {\n\t\t\tfont-size: 24px;\n\t\t\tcolor: #98989e;\n\t\t}\n\t}\n\n\t.text {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\t\tcolor: rgba(0, 0, 0, 0.45);\n\n\t\t& > i {\n\t\t\tmargin-right: 5px;\n\t\t\tfont-size: 14px;\n\t\t}\n\t}\n\n\t.prompt {\n\t\tcolor: #ff4d4f;\n\t}\n\n\t& > .message {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tmargin-top: 10px;\n\t\tcolor: #f00;\n\n\t\t& > i {\n\t\t\tmargin-right: 5px;\n\t\t\tfont-size: 18px;\n\t\t}\n\t}\n"])));

exports.UiUpload = UiUpload;