import styled from "styled-components";
import { Modal } from "antd";

export const UiNewSubjectChapterTag = styled.div`
	color: ${(props) => (props.isYear ? "#FFB800" : "#DBFFB7")};
	border: 1px solid;
	border-color: ${(props) => (props.isYear ? "#FFB800" : "#DBFFB7")};
	border-radius: 4px;
	background-color: #1f1f1f;
	font-size: 12px;
	padding: 2px 8px;
	margin-left: 8px;
	height: 20px;
	display: flex;
	align-items: center;
`;

export const UiNewSubjectChapterModal = styled(Modal)`
	.modalContent {
		display: flex;
		padding: 0 24px;
		flex-direction: column;
		row-gap: 10px;
	}
`;
