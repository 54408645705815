import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Breadcrumb, Button, Select, Upload } from "common-components";
import { UiTop, UiActionBox } from "styles";
import { useMachine } from "@xstate/react";
import { v4 as uuidv4 } from "uuid";
import fetchMachine, { FetchEventType } from "machines/fetchMachine/index";
import useQuery from "utils/hooks/useQuery";
import { EDUCATIONAL_LIST, subjectsObj } from "constants/index";
import { UiNewSubjectPage } from "../common.style";
import { UiNewSubjectChapterTag, UiNewEnglishListeningModal } from "./EnglishListeningPage.style";
import { getBasicTableData } from "api/definition";
import { createValidator, required } from "utils/validation";
import { useFirebaseStorage } from "utils/hooks/useFirebaseStorage";
import openNotificationWithIcon from "utils/hooks/useNotification";
import {
	getEnglishListeningCategoryList,
	importEnglishListeningCategory,
	getNewSubjectChapterBookSelection,
} from "api/chapter";
import { setSelectOptions } from "utils/common";
import { BookComponent } from "../common/BookComponent";

export const EnglishListeningPage = () => {
	const history = useHistory();
	const { upload } = useFirebaseStorage();

	const [searchParams, setSearchParams] = useState({});
	const [isFirst, setIsFirst] = useState(true);
	const [stateFormParams, setFormParams] = useState({});
	const [uploadFile, setUploadFile] = useState({});
	const [isSubmitted, setIsSubmitted] = useState(false);

	const edu = useQuery().get("edu");
	const subject = useQuery().get("subject");
	const eduName = EDUCATIONAL_LIST[edu];
	const subjectName = subjectsObj[subject];

	const [uploadPopup, setUploadPopup] = useState({
		visible: false,
		loading: false,
	});

	const breadcrumbGroup = [
		{
			icon: "home",
			name: "首頁",
			path: "/",
		},
		{
			icon: "format_list_bulleted",
			name: "新科目資料",
			path: "/newSubject",
		},
		{
			name: `${eduName} - ${subjectName}`,
			path: `/newSubject/info?edu=${edu}&subject=${subject}`,
		},
		{
			name: "英聽類別總表",
			path: `/newSubject/englishListening?edu=${edu}&subject=${subject}`,
		},
	];

	const getMaxCode = (items) => {
		return items.reduce((prev, current) => (Number(prev.code) > Number(current.code) ? prev : current));
	};

	// 取得所有下拉選單資料
	const [selectState, selectionSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context) => {
				const response = await getNewSubjectChapterBookSelection();

				const { yearMap } = response.data;

				const maxYear = getMaxCode(yearMap);

				setSearchParams((prev) => {
					return {
						...prev,
						year: maxYear.code,
					};
				});

				return {
					yearMap: setSelectOptions(yearMap),
				};
			},
		},
	});

	const { yearMap } = selectState.context.result || {};

	// 取得課本章節總表
	const [chapterListState, chapterListStateSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const response = await getEnglishListeningCategoryList(event);

				setIsFirst(false);

				return {
					chapterList: response.data,
				};
			},
		},
	});

	const { chapterList } = chapterListState.context.result || {};

	// 取得版本下拉選單資料(modal)
	const [versionMapState, versionMapStateSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context) => {
				const { data } = await getBasicTableData();
				const versionMap = data.data["PUBLISHER"].map((item) => ({
					name: item.name,
					value: item.code,
				}));

				return {
					versionMap,
				};
			},
		},
	});

	const { versionMap } = versionMapState.context.result || {};

	const onSearchHandle = () => {
		if (!searchParams.year) return;

		const { year } = searchParams;

		chapterListStateSend(FetchEventType.Fetch, {
			year,
			eduSubject: `${edu}${subject}`,
		});
	};

	useEffect(() => {
		if (searchParams.year && isFirst) {
			const { year } = searchParams;

			chapterListStateSend(FetchEventType.Fetch, {
				year,
				eduSubject: `${edu}${subject}`,
			});
		}
	}, [searchParams.year]);

	// 進入畫面，取得學年度
	useEffect(() => {
		selectionSend(FetchEventType.Fetch);
		versionMapStateSend(FetchEventType.Fetch);
	}, []);

	const handleToDetailPage = (bookId) => {
		history.push(`/newSubject/englishListening/detail/?edu=${edu}&subject=${subject}&bookId=${bookId}`);
	};

	const toggleUploadPopupVisible = () => {
		setFormParams({});
		setUploadPopup((prev) => ({
			...prev,
			visible: !prev.visible,
		}));
		setUploadFile({});
	};

	const onFormSelectChangeHandle = (value, key) => {
		setIsSubmitted(false);
		setFormParams({
			...stateFormParams,
			[key]: {
				value,
				message: "",
			},
		});
	};

	const changeChapterFile = (file) => {
		setUploadFile((prev) => {
			return {
				...prev,
				chapterFile: file,
			};
		});
	};

	const [, VolumeInfoSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const response = await importEnglishListeningCategory(event);

				if (response?.error === 200) {
					openNotificationWithIcon("success", "上傳成功");
					toggleUploadPopupVisible();
				} else {
					openNotificationWithIcon("error", `上傳失敗！ ${response.message}`);
					toggleUploadPopupVisible();
				}
			},
		},
	});

	const showConfirm = async () => {
		const uid = uuidv4();

		setIsSubmitted(true);
		let rules = {
			year: [{ message: "適用年度欄位不得空白", validate: required }],
			version: [{ message: "版本欄位不得空白", validate: required }],
		};

		const inputValues = {
			year: "",
			version: "",
		};

		Object.entries(stateFormParams).forEach(([key, value]) => {
			inputValues[key] = value.value;
		});

		const [isPass, errorGroup] = createValidator(inputValues, rules);
		setFormParams({ ...stateFormParams, ...errorGroup });
		if (isPass || uploadFile.chapterFile?.length === 0) return;

		const chapterInfo = await upload(`chapter/${uid}-${uploadFile.chapterFile[0].name}`, uploadFile.chapterFile[0]);

		VolumeInfoSend(FetchEventType.Fetch, {
			eduSubject: `${edu}${subject}`,
			version: stateFormParams.version.value,
			year: stateFormParams.year.value,
			url: chapterInfo.url,
			fileName: uploadFile.chapterFile[0].name,
		});
	};

	const fileErrorMessage = () => {
		if (isSubmitted) {
			if (uploadFile.chapterFile?.length === 0) {
				return "請上傳檔案";
			} else {
				return "";
			}
		}
	};

	return (
		<div>
			<UiTop>
				<UiActionBox>
					<Button.IconButton
						variant="white"
						full={false}
						iconName="keyboard_backspace"
						onClick={() => {
							history.push(`/newSubject/info?edu=${edu}&subject=${subject}`);
						}}>
						回上層
					</Button.IconButton>
					{edu && subject && <Breadcrumb data={breadcrumbGroup} />}
				</UiActionBox>
			</UiTop>
			<UiNewSubjectPage>
				<div className="header">
					<div className="title">英聽類別總表</div>
					<UiNewSubjectChapterTag isYear={true}>{searchParams.year} 學年度</UiNewSubjectChapterTag>
				</div>
				<div className="search">
					<UiTop>
						<UiActionBox>
							<Select
								options={yearMap}
								value={searchParams.year}
								onChange={(value) => setSearchParams({ ...searchParams, year: value })}
							/>
							<Button.IconButton variant="blue" full={false} iconName="search" onClick={onSearchHandle}>
								查詢
							</Button.IconButton>
						</UiActionBox>
						<UiActionBox>
							<Button.IconButton
								onClick={toggleUploadPopupVisible}
								iconName="post_add"
								variant="yellow"
								textColor="black">
								新增英聽類別
							</Button.IconButton>
						</UiActionBox>
					</UiTop>
				</div>

				{chapterList &&
					Object.keys(chapterList).map((item) => {
						return (
							<BookComponent key={item} title={item} list={chapterList[item]} handleToDetailPage={handleToDetailPage} />
						);
					})}

				<UiNewEnglishListeningModal
					confirmLoading={uploadPopup.loading}
					visible={uploadPopup.visible}
					onCancel={toggleUploadPopupVisible}
					okText={uploadPopup.loading ? "上傳中..." : "上傳"}
					onOk={showConfirm}
					title={<div style={{ color: "#1890FF" }}>{`新增英聽類別：${eduName} - ${subjectName}`}</div>}
					width="560px">
					<div className="modalContent">
						<Select
							label="適用年度"
							options={yearMap}
							value={stateFormParams.year?.value || ""}
							message={stateFormParams.year?.message || ""}
							onChange={(value) => onFormSelectChangeHandle(value, "year")}
						/>
						<Select
							label="版本"
							options={versionMap}
							value={stateFormParams.version?.value || ""}
							message={stateFormParams.version?.message || ""}
							onChange={(value) => onFormSelectChangeHandle(value, "version")}
						/>
						<div>上傳類別表</div>
						<Upload
							fileList={uploadFile.chapterFile || []}
							onChange={changeChapterFile}
							prompt={
								<>
									僅限上傳一個 Microsoft Excel 檔<br />
									(副檔名 : .xlsx)
								</>
							}
							acceptFileType=".xlsx"
							message={fileErrorMessage()}
						/>
					</div>
				</UiNewEnglishListeningModal>
			</UiNewSubjectPage>
		</div>
	);
};
