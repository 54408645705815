import { Api } from "common-components";
// import { stringify } from "query-string";
/**
 * 取得起始頁查詢用選單資料
 */
export const getSystemGroup = async () => {
	const response = await Api.get(`${process.env.REACT_APP_RESOURCE_PLATFORM_DOMAIN}/api/Group/SystemGroup`);
	return response;
};

export const getSystemGroupRelated = async (uid) => {
	const response = await Api.get(`${process.env.REACT_APP_RESOURCE_PLATFORM_DOMAIN}/api/Group/${uid}/Related`);
	return response;
};

export const putSystemGroupEdit = async (payload) => {
	const response = await Api.put(`${process.env.REACT_APP_RESOURCE_PLATFORM_DOMAIN}/api/Group/Edit`, payload)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return {
				isSuccess: false,
				message: error.data,
			};
		});
	return response;
};
