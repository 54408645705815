import { AUTHORITY, AUTHORITY_VALUE } from "constants/index";
import axios from "axios";
import { getTime } from "date-fns";

export const sliceTableData = (data, currentPage, limit) => {
	return data.slice((currentPage - 1) * limit, (currentPage - 1) * limit + limit);
};

export const setSelectOptions = (data, needNull = false) => {
	if (!data) return [];
	const result =
		data.length > 0
			? data.map((item) => {
					const result = {
						name: item.name,
						value: item.code,
					};
					if (item.curriculum) result.curriculum = item.curriculum;
					return result;
			  })
			: [];

	if (needNull) {
		result.push({
			name: "---",
			value: "",
		});
	}
	return result;
};

export const blobDownload = (url, filename, type) => {
	const xhr = new XMLHttpRequest();
	xhr.open(type, url, true);
	xhr.responseType = "blob";
	const requestOnload = () => {
		xhr.onload = function (e) {
			if (this.status === 200) {
				const blob = this.response;
				const a = document.createElement("a");
				document.body.appendChild(a);
				const blobUrl = window.URL.createObjectURL(blob);
				a.href = blobUrl;
				a.download = filename;
				a.click();
				setTimeout(() => {
					window.URL.revokeObjectURL(blobUrl);
					document.body.removeChild(a);
				}, 0);
			}
		};
	};
	if (type === "POST") {
		xhr.setRequestHeader("Content-type", "application/json");
		requestOnload();
	}
	if (type === "GET") {
		requestOnload();
		xhr.send();
	}
};

export const formatAuthorityArrayToNumber = (authArray) => {
	let authority = 0;
	if (!authArray || authArray.length === 0) return authority;

	authArray.forEach((item) => {
		authority += AUTHORITY_VALUE[item];
	});
	return authority;
};

export const formatAuthority = (authorityValue) => {
	if (!authorityValue) return {};
	const authArray = authorityValue.toString(2).split("").reverse().join("");
	let result = {};

	Object.entries(AUTHORITY).forEach(([key, value], index) => {
		result = { [key]: parseInt(authArray[index] || 0), ...result };
	});
	return result;
};

export const getTableAuthority = (type, authData) => {
	if (!authData || !type) {
		return {
			authority: 0,
			tableAuthority: {
				View: 0,
				Edit: 0,
				Lock: 0,
				Import: 0,
				Export: 0,
			},
		};
	}
	const authTableType = type.indexOf("_") !== -1 ? type.split("_")[0] + type.split("_")[1] : type;
	const { table, authority } = authData;
	const tableAuthority = table[authTableType.toUpperCase()] || 0;
	return {
		authority,
		tableAuthority: formatAuthority(tableAuthority),
	};
};

export const exportFile = async (url, method, payload) => {
	axios({ url, method, data: payload, responseType: "blob" }).then((res) => {
		const fileName = decodeURI(res.headers["content-disposition"].replace(/\w+;filename=(.*)/, "$1")).split(
			"UTF-8''",
		)[1];
		const url = window.URL.createObjectURL(new Blob([res.data]));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", fileName);
		document.body.appendChild(link);
		link.click();
	});
};

export const subtraction = (arr1, arr2) => {
	if (!arr1) return [];
	return arr1.filter((e) => arr2.map((item) => item.code).indexOf(e.code) === -1);
};

export const calStudentYear = () => {
	const nowDate = new Date();
	const ROCYear = nowDate.getFullYear() - 1911;

	const studentYearDemarcationPoint = getTime(new Date(`${nowDate.getFullYear()}-08-01`));
	return getTime(nowDate) > studentYearDemarcationPoint ? ROCYear : ROCYear - 1;
};
