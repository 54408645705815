import styled from "styled-components";

export const UiDropList = styled.div`
	display: block;
	padding-bottom: 10px;
	background-color: #fff;

	.title {
		margin-bottom: 5px;
		color: #686868;
	}

	.message {
		display: flex;
		align-items: center;
		padding: 10px 5px;
		color: #f00;

		i {
			margin-right: 5px;
		}
	}
`;

export const UiDropContainer = styled.div`
	display: block;
`;

export const UiDropItem = styled.div`
	display: flex;
	align-items: center;
	width: max-content;

	.drop {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		color: #686868;
	}

	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		cursor: pointer;
		color: #686868;
	}

	& + div {
		margin-top: 10px;
	}
`;
