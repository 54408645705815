"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiAddList = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var UiAddList = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tdisplay: block;\n\twidth: 100%;\n\tmax-width: 300px;\n\n\t.title {\n\t\tdisplay: flex;\n\t\tjustify-content: space-between;\n\t\tpadding: 16px 10px;\n\t\twidth: 100%;\n\t\tfont-size: 14px;\n\t\tfont-weight: 400;\n\t\tbackground-color: #fafafa;\n\t\tborder-bottom: 1px solid #f0f0f0;\n\t}\n\n\t.item {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: space-between;\n\t\tpadding: 10px;\n\t\twidth: 100%;\n\t\tfont-size: 14px;\n\t\tborder-bottom: 1px solid #f0f0f0;\n\n\t\ti {\n\t\t\tmargin-left: 10px;\n\t\t\tcolor: #d9d9d9;\n\t\t\tcursor: pointer;\n\t\t\tuser-select: none;\n\t\t\ttransition: 0.35s;\n\n\t\t\t&:hover {\n\t\t\t\tcolor: #686868;\n\t\t\t}\n\t\t}\n\t}\n\n\t.input {\n\t\tdisplay: flex;\n\t\tpadding: 10px 10px 10px 0;\n\n\t\t.icon i {\n\t\t\tmargin-top: 3px;\n\t\t\tmargin-left: 10px;\n\t\t\tcolor: #686868;\n\t\t\tborder-radius: 50%;\n\t\t\ttransition: 0.35s;\n\t\t\tcursor: pointer;\n\t\t\tuser-select: none;\n\n\t\t\t&:hover {\n\t\t\t\tbackground-color: #d9d9d9;\n\t\t\t}\n\t\t}\n\t}\n"])));

exports.UiAddList = UiAddList;