import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useQuery from "utils/hooks/useQuery";
import { useMachine } from "@xstate/react";
import { format } from "date-fns";
import { UiTop, UiActionBox } from "styles";
import { Button, Select, Table, Pagination, Breadcrumb } from "common-components";
import { EDUCATIONAL_LIST, versionList } from "constants/index";
import { subjectsObj } from "constants/index";
import fetchMachine, { FetchEventType, FetchStates } from "machines/fetchMachine";
import { sliceTableData, setSelectOptions } from "utils/common";
import {
	getNewSubjectVolumesSelection,
	getNewSubjectChapterBookSelection,
	getEnglishListeningCategory,
} from "api/chapter/index";
import { UiNewSubjectPage, UiNewSubjectTag } from "../common.style";

const { Header, Body, Row, Item } = Table;

export const EnglishListeningDetailPage = () => {
	const history = useHistory();
	const edu = useQuery().get("edu");
	const subject = useQuery().get("subject");
	const bookID = useQuery().get("bookId");
	const eduName = EDUCATIONAL_LIST[edu];
	const subjectName = subjectsObj[subject];

	const [searchParams, setSearchParams] = useState({});
	const [filterChapters, setFilterChapters] = useState([]);
	const [pageState, setPageState] = useState({
		page: 1,
		limit: 10,
	});

	// 取得冊次資料
	const [volumeState, volumeSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context) => {
				const payload = {
					eduSubject: `${edu}${subject}`,
				};
				const { data } = await getNewSubjectVolumesSelection(payload);

				return {
					volumeMap: setSelectOptions(data),
				};
			},
		},
	});

	const { volumeMap } = volumeState.context.result || {};
	const currentVolume = volumeMap?.find((item) => item.value === searchParams.volume);

	const breadcrumbName =
		currentVolume?.name && searchParams.version
			? `${currentVolume?.name} [${versionList[searchParams.version]}版]`
			: "";

	const breadcrumbGroup = [
		{
			icon: "home",
			name: "首頁",
			path: "/",
		},
		{
			icon: "format_list_bulleted",
			name: "新科目資料",
			path: "/newSubject",
		},
		{
			name: `${eduName} - ${subjectName}`,
			path: `/newSubject/info?edu=${edu}&subject=${subject}`,
		},
		{
			name: "英聽類別總表",
			path: `/newSubject/englishListening?edu=${edu}&subject=${subject}`,
		},
		{
			name: breadcrumbName,
			path: `/newSubject/englishListening?edu=${edu}&subject=${subject}`,
		},
	];

	// 取得所有下拉選單資料
	const [selectState, selectionSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context) => {
				const response = await getNewSubjectChapterBookSelection();

				const { yearMap, versionMap } = response.data;

				const regex = /[^-](?=-[^-]*$)/;

				const defaultVolume = bookID.slice(-3);
				const defaultVersion = bookID.match(regex)[0];
				const defaultYear = bookID.slice(0, 3);

				setSearchParams((prev) => {
					return {
						...prev,
						year: defaultYear,
						version: defaultVersion,
						volume: defaultVolume,
					};
				});

				return {
					yearMap: setSelectOptions(yearMap),
					versionMap: setSelectOptions(versionMap),
				};
			},
		},
	});

	const { yearMap, versionMap } = selectState.context.result || {};

	useEffect(() => {
		selectionSend(FetchEventType.Fetch);
		volumeSend(FetchEventType.Fetch);
	}, []);

	// 取得所有章節資料
	const [selectionState, selectSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const payload = {
					bookID: event.bookID,
				};
				const response = await getEnglishListeningCategory(payload);
				const { maintainer, createTime, enlCategory } = response.data;

				setFilterChapters(enlCategory);
				setPageState((prev) => ({
					...prev,
					page: 1,
				}));

				return {
					creator: maintainer,
					createTime: createTime ? format(new Date(createTime), "yyyy/MM/dd HH:mm") : createTime,
				};
			},
		},
	});

	const { creator, createTime } = selectionState.context.result || {};

	const onSearchHandle = () => {
		const { year, version, volume } = searchParams;

		const bookID = `${year}${version}-${edu}${subject}${volume}`;

		selectSend(FetchEventType.Fetch, {
			bookID,
		});
	};

	const pageChange = (page, limit) => {
		setPageState({
			page,
			limit,
		});
	};

	useEffect(() => {
		if (!bookID) return;
		selectSend(FetchEventType.Fetch, {
			bookID,
		});
	}, [bookID]);

	return (
		<div>
			<UiTop>
				<UiActionBox>
					<Button.IconButton
						variant="white"
						full={false}
						iconName="keyboard_backspace"
						onClick={() => {
							history.push(`/newSubject/englishListening?edu=${edu}&subject=${subject}`);
						}}>
						回上層
					</Button.IconButton>
					{edu && subject && <Breadcrumb data={breadcrumbGroup} />}
				</UiActionBox>
			</UiTop>

			<UiNewSubjectPage>
				<div className="header">
					<div className="title">英聽類別總表</div>
					<UiNewSubjectTag isYear={true}>{searchParams.year} 學年度</UiNewSubjectTag>
				</div>
				<div className="search">
					<UiTop>
						<UiActionBox>
							<Select
								options={yearMap}
								value={searchParams.year}
								onChange={(value) => setSearchParams({ ...searchParams, year: value })}
							/>
							<Select
								options={versionMap}
								value={searchParams.version}
								onChange={(value) => setSearchParams({ ...searchParams, version: value })}
							/>
							<Select
								options={volumeMap}
								value={searchParams.volume}
								onChange={(value) => setSearchParams({ ...searchParams, volume: value })}
							/>
							<Button.IconButton variant="blue" full={false} iconName="search" onClick={onSearchHandle}>
								查詢
							</Button.IconButton>
						</UiActionBox>
					</UiTop>
				</div>

				<div className="history">
					<div>{creator && createTime && `最近異動：${creator} ${createTime}`}</div>
				</div>
				<Table margin={20}>
					<Header>
						<Row>
							<Item flex={0.5}>課名代碼</Item>
							<Item flex={2}>課名</Item>
						</Row>
					</Header>
					<Body isLoading={!selectionState.matches(FetchStates.Resolved)}>
						{filterChapters &&
							sliceTableData(filterChapters, pageState.page, pageState.limit).map((item) => {
								return (
									<Row key={item.code}>
										<Item flex={0.5}>{item.code}</Item>
										<Item flex={2}>{item.name}</Item>
									</Row>
								);
							})}
						{filterChapters.length === 0 && (
							<div className="tableNoData">
								<img src={`/assets/noData.png`} alt="noData" />
								無資料顯示
							</div>
						)}
					</Body>
				</Table>
				<Pagination
					defaultCurrent={1}
					current={pageState.page}
					onChange={pageChange}
					total={filterChapters?.length}
					defaultPageSize={pageState.limit}
				/>
			</UiNewSubjectPage>
		</div>
	);
};
