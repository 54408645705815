import styled from "styled-components";

export const sharedHeaderStyles = `
	width: 100%;
	height: 40px;
	font-size: 18px;
	font-weight: 900;
	padding: 9px 14px;
`;

export const UiNewSubjectPage = styled.div`
	.ant-modal-body {
		max-height: 500px;
		overflow: auto;
	}
	.header {
		${sharedHeaderStyles}
		background-color: #3f8ae0;
		color: #fff;
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}

	.history {
		display: flex;
		justify-content: flex-end;
		gap: 8px;
		color: #98989e;
		margin-bottom: 8px;
	}

	.content {
		margin: 24px 0;
		color: #fff;
	}

	.tableNoData {
		margin: 24px auto;
		width: 50%;
		font-size: 24px;
		text-align: center;
		color: #fff;
	}
`;

export const UiContentComponent = styled.div`
	.breakLine {
		border: 1px solid #535353;
		margin: 24px 0;
	}

	.title {
		${sharedHeaderStyles}
		color: #ffec43;
		display: flex;
		align-items: center;
		margin-bottom: 16px;
	}

	.collapse {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #fff;
		padding: 9px 14px;
		cursor: pointer;

		& > .collapseTitle {
			font-size: 18px;
			font-weight: 900;
			color: #ffec43;
		}

		& > .collapseIcon {
			color: #fff;
			font-size: 14px;
			cursor: pointer;

			& > .icon {
				display: flex;
				align-items: center;
			}
		}
	}

	.content {
		display: flex;
		gap: 8px;
		flex-wrap: wrap;

		& > .chapterBox {
			border-radius: 2px;
			width: 290px;
			height: 64px;
			color: #fff;
			font-size: 14px;
			padding: 20px 16px;
			white-space: nowrap;
			display: flex;
			align-items: center;
			gap: 8px;
			cursor: pointer;
			border: 1px solid #fff;

			&:hover {
				background-color: #6c6c6c;
			}
		}

		& > .noData {
			text-align: center;
			color: #fff;
			font-size: 20px;
			margin: 0 auto;
		}
	}

	.contentBox {
		border-radius: 2px;
		width: 290px;
		height: 64px;
		color: #fff;
		font-size: 14px;
		padding: 20px 16px;
		white-space: nowrap;
		display: flex;
		align-items: center;
		cursor: pointer;
		border: 1px solid #fff;
		gap: 8px;

		&:hover {
			background-color: #6c6c6c;
		}
	}
`;

export const UiNewSubjectTag = styled.div`
	color: ${(props) => (props.isYear ? "#FFB800" : "#DBFFB7")};
	border: 1px solid;
	border-color: ${(props) => (props.isYear ? "#FFB800" : "#DBFFB7")};
	border-radius: 4px;
	background-color: #1f1f1f;
	font-size: 12px;
	padding: 2px 8px;
	margin-left: 8px;
	height: 20px;
	display: flex;
	align-items: center;
`;
