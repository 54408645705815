import React, { useState, useEffect, useMemo } from "react";
import { useMachine } from "@xstate/react";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import { getTransactionLog } from "api/transaction";
import { Table, Button, Select, Pagination, Input, DatePicker, DATEFORMAT } from "common-components";
import { setSelectOptions, sliceTableData } from "utils/common";
import { getTime, format } from "date-fns";
import moment from "moment";
import { UiTableTop } from "styles";
import { UiHomeTop, UiHomeActionBox, UiTransaction } from "./HomePage.style";

/**
 * 異動紀錄
 */

const { Header, Body, Row, Item } = Table;

export const HomePage = () => {
	const [date, setDate] = useState([moment(moment().subtract(30, "days"), DATEFORMAT), moment(moment(), DATEFORMAT)]);
	const [filterType, setFilterType] = useState("");
	const [filterValue, setFilterValue] = useState("");
	const [logsData, setLogsData] = useState([]);
	const [pageState, setPageState] = useState({
		currentPage: 1,
		limit: 10,
	});
	const [state, send] = useMachine(fetchMachine, {
		services: {
			fetchData: async () => {
				const response = await getTransactionLog();
				const { isSuccess, message, data: { actionFilter, logs } = {} } = response;
				return {
					isSuccess,
					message,
					actionFilter: setSelectOptions(actionFilter) || [],
					logs:
						logs.map((item, index) => ({
							...item,
							id: index,
							systemTime: format(getTime(new Date(item.systemTime)), "yyyy-MM-dd HH:mm:ss"),
						})) || [],
				};
			},
		},
	});
	const { actionFilter, logs } = state.context.result || {};

	const filterLogsData = useMemo(() => {
		if (!logsData) return [];
		const minDate = date[0] ? getTime(new Date(date[0])) : 0;
		const maxDate = date[1] ? getTime(new Date(date[1])) : 0;
		return (
			logsData
				.filter((item) => (filterType ? item.action === filterType : true))
				.filter((item) =>
					filterValue ? item.transaction.indexOf(filterValue) > -1 || item.description.indexOf(filterValue) > -1 : true,
				)
				.filter((item) => {
					const itemTime = getTime(new Date(item.systemTime));
					if (!minDate && !maxDate) return true;
					if (itemTime >= minDate && itemTime <= maxDate) return true;
					return false;
				}) || []
		);
	}, [logsData, filterType, filterValue, date]);

	const pageChange = (currentPage, pageSize) => {
		setPageState({
			...pageState,
			currentPage,
			limit: pageSize,
		});
	};

	const onSearchHandle = (event) => {
		setFilterValue(event.target.value);
		setPageState({
			...pageState,
			currentPage: 1,
		});
	};

	const setSearchKey = (value) => {
		setFilterType(value);
		setPageState({
			...pageState,
			currentPage: 1,
		});
	};
	const onDateChange = (date, dateString) => {
		setDate(dateString);
	};

	const expandLogsDesc = (data, state) => {
		const nextLogs = [...logsData].map((item) => {
			if (item.id === data.id) {
				return {
					...item,
					active: state,
				};
			} else {
				return {
					...item,
				};
			}
		});
		setLogsData(nextLogs);
	};

	useEffect(() => {
		send(FetchEventType.Fetch);
	}, []);
	useEffect(() => {
		if (!logs) return;
		setLogsData([...logs]);
	}, [logs]);
	return (
		<>
			<UiTableTop>
				<UiHomeTop>
					<UiHomeActionBox>
						<DatePicker variant="dark" defaultValue={date} onChange={onDateChange} />
						<div className="miniSelect">
							<Select
								variant="dark"
								options={actionFilter || []}
								value={filterType}
								getPopupContainer={() => document.getElementsByClassName("miniSelect")[0]}
								onChange={(value) => {
									setSearchKey(value);
								}}
								onClear={() => {
									setFilterType("");
								}}
							/>
						</div>
						<div className="homeInput">
							<Input.Search mode="dark" value={filterValue} onChange={onSearchHandle} placeholder="搜尋" />
						</div>
					</UiHomeActionBox>
				</UiHomeTop>
			</UiTableTop>

			<Table margin="3">
				<Header>
					<Row>
						<Item>時間</Item>
						<Item>使用者名稱</Item>
						<Item>產品</Item>
						<Item flex="2">動作</Item>
						<Item>操作</Item>
					</Row>
				</Header>
				<Body>
					{filterLogsData &&
						sliceTableData(filterLogsData, pageState.currentPage, pageState.limit).map((item, index) => {
							return (
								<Row key={index}>
									<Item>{item.systemTime}</Item>
									<Item>{item.userName}</Item>
									<Item>{item.applicationName}</Item>
									<Item flex="2">
										<div>{item.description}</div>
										<UiTransaction>{item.active ? item.transaction : ""}</UiTransaction>
									</Item>
									<Item>
										{item.active ? (
											<Button.IconButton
												full={false}
												variant="white"
												iconName="visibility_off"
												onClick={() => {
													expandLogsDesc(item, false);
												}}>
												看更多
											</Button.IconButton>
										) : (
											<Button.IconButton
												full={false}
												iconName="visibility"
												onClick={() => {
													expandLogsDesc(item, true);
												}}>
												看更多
											</Button.IconButton>
										)}
									</Item>
								</Row>
							);
						})}
				</Body>
			</Table>
			<Pagination
				defaultCurrent={1}
				current={pageState.currentPage}
				onChange={pageChange}
				total={filterLogsData ? filterLogsData.length : 0}
				defaultPageSize={pageState.limit}
			/>
		</>
	);
};
