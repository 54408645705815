import styled from "styled-components";

export const UiSubjectPage = styled.div`
	.selectBox {
		margin-bottom: 20px;
		display: flex;

		& > div {
			margin-right: 10px;
			max-width: 180px;
		}
	}

	.container {
		display: flex;
		flex-wrap: wrap;
		align-items: cneter;

		& > * {
			max-width: 24%;
			margin-bottom: 20px;

			&:not(:nth-child(4n)) {
				margin-right: calc(4% / 3);
			}
		}
	}
`;
