import React, { useState, useEffect, useMemo } from "react";
import {
	Button,
	Select,
	Icon,
	Table,
	Pagination,
	Modal,
	Breadcrumb,
	AddListTable,
	AuthProvider,
} from "common-components";
import { useHistory } from "react-router-dom";
import { getTime, format } from "date-fns";
import { useMachine } from "@xstate/react";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import { getSystemGroup, getSystemGroupRelated, putSystemGroupEdit } from "api/group";
import { FetchStates } from "machines/fetchMachine/index";
import { EDUCATIONAL_LIST, ROLES } from "constants/index";
import { sliceTableData } from "utils/common";
import { UiTableTop } from "styles";
import { UiGroupAuthorityManagementPage } from "./GroupAuthorityManagementPage.style";

/**
 * 權限管理-群組管理
 */
const { useAuthState } = AuthProvider;
const { Header, Body, Row, Item } = Table;
const { Row: AddListRow, Item: AddListItem, Body: AddListBody, Header: AddListHeader } = AddListTable;
const breadcrumbLists = [
	{
		icon: "home",
		name: "首頁-異動紀錄",
		path: "/",
	},
	{
		icon: "manage_accounts",
		name: `權限管理-群組管理`,
		path: `/groupAuthorityManagement`,
	},
];
export const GroupAuthorityManagementPage = () => {
	const authState = useAuthState();
	const history = useHistory();
	const { identity: userIdentity } = authState.context.result || {};
	const [page, setPage] = useState({
		currentPage: 1,
		limit: 10,
	});
	const [edit, setEdit] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [modalInfo, setModalInfo] = useState({
		admin: {},
		member: [],
	});
	const [selectMember, setSelectMember] = useState(null);

	const onSelectChange = (val) => {
		setSelectMember(val);
	};

	const onDeleteMember = (data, type = "member") => {
		const memberList = [...modalInfo.member];
		switch (type) {
			case "member":
				setModalInfo({
					...modalInfo,
					member: memberList.filter((item) => item.uid !== data.uid),
				});

				break;
			default:
				setModalInfo({
					...modalInfo,
					admin: {},
				});
				break;
		}
	};

	const upgradeMemberToAdmin = (data) => {
		const adminData = modalInfo.admin;
		const nextData = modalInfo.member.filter((item) => item.uid !== data.uid);
		setModalInfo({
			admin: data,
			member: [...nextData, { ...adminData }],
		});
	};
	const pageChange = (currentPage) => {
		setPage({
			...page,
			currentPage,
		});
	};
	const onShowSizeChange = (currentPage, limit) => {
		setPage({
			currentPage,
			limit,
		});
	};

	const getGroupMember = async (uid) => {
		await sendSystemGroupRelated(FetchEventType.Fetch, { uid });
		setModalVisible(true);
	};
	const [stateSystemGroup, sendSystemGroup] = useMachine(fetchMachine, {
		services: {
			fetchData: async () => {
				const response = await getSystemGroup();
				const { isSuccess, message, data } = response;
				return {
					isSuccess,
					message,
					groupList: data
						? data.map((item) => ({
								...item,
								updateTime: format(getTime(new Date(item.updateTime)), "yyyy-MM-dd HH:mm:ss"),
								education: item.code.substr("")[0],
						  }))
						: [],
				};
			},
			updateData: async (_, event) => {
				const response = await putSystemGroupEdit(event.payload);
				const { isSuccess, message } = response;

				return {
					isSuccess,
					message,
				};
			},
		},
	});
	const { groupList } = stateSystemGroup.context.result || {};

	const [stateSystemGroupRelated, sendSystemGroupRelated] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_, event) => {
				const response = await getSystemGroupRelated(event.uid);
				const { isSuccess, message, data } = response;
				return {
					isSuccess,
					message,
					adminData: data.admin || {},
					memberData: (data.member && data.member.filter((item) => item.uid !== data.admin.uid)) || [],
					groupInfo: data.group || {},
					systemUsers: data.systemUsers || [],
					allowEdit: data.allowEdit,
				};
			},
		},
	});
	const { adminData, memberData, groupInfo, systemUsers, allowEdit } = stateSystemGroupRelated.context.result || {};

	const updateGroupData = async () => {
		const { admin, member } = modalInfo;
		await sendSystemGroup(FetchEventType.Update, {
			payload: {
				groupUID: groupInfo.uid,
				admin: admin.uid,
				member: [admin.uid, ...member.map((item) => item.uid)],
			},
		});
		setEdit(false);
	};
	const resetGroupData = () => {
		setEdit(false);
		setModalInfo({
			admin: adminData,
			member: memberData,
		});
	};

	const searchArrayIsDiff = (a1, a2, key) => {
		let result = false;
		a1.forEach((item, index) => {
			if (item[key] !== a2[index][key]) result = true;
		});
		return result;
	};

	const saveBtnDisabled = useMemo(() => {
		if (Object.keys(modalInfo.admin).length === 0) return true;
		let result = true;
		if (modalInfo.admin.uid !== adminData.uid) result = false;
		if (memberData.length !== modalInfo.member.length || searchArrayIsDiff(memberData, modalInfo.member, "uid")) {
			result = false;
		}

		return result;
	}, [modalInfo.admin, modalInfo.member]);

	const selectMemberOptions = useMemo(() => {
		if (!systemUsers) return [];
		return systemUsers
			.filter((item) =>
				modalInfo.member.length === 0 ? true : modalInfo.member.findIndex((element) => element.uid === item.uid) === -1,
			)
			.filter((item) => (modalInfo.admin?.uid ? item.uid !== modalInfo.admin.uid : true))
			.map((item) => ({
				name: item.name,
				value: item.uid,
			}));
	}, [systemUsers, modalInfo.member, modalInfo.admin]);

	const onMemberAdd = () => {
		if (!selectMember) return;
		const selectData = systemUsers.find((item) => item.uid === selectMember);
		if (!selectData) return;
		setModalInfo({
			...modalInfo,
			member: [
				...modalInfo.member,
				{
					...selectData,
				},
			],
		});
		setSelectMember(null);
	};

	useEffect(() => {
		if (userIdentity !== "Admin") {
			history.push("/");
			return;
		}
		sendSystemGroup(FetchEventType.Fetch);
	}, []);

	useEffect(() => {
		if (!stateSystemGroupRelated.matches(FetchStates.Resolved)) return;
		setModalInfo({
			admin: adminData,
			member: memberData,
		});
	}, [stateSystemGroupRelated.value]);

	return (
		<UiGroupAuthorityManagementPage>
			<UiTableTop>
				<div className="breadcrumb">
					<Breadcrumb data={breadcrumbLists} />
				</div>
			</UiTableTop>
			<Table>
				<Header>
					<Row>
						<Item>群組名稱</Item>
						<Item>學制</Item>
						<Item flex={2}>管理者</Item>
						<Item>操作</Item>
					</Row>
				</Header>
				<Body>
					{groupList
						? sliceTableData(groupList, page.currentPage, page.limit).map((item, index) => (
								<>
									<Row key={`${index}${item.uid}`}>
										<Item>{item.name}</Item>
										<Item>
											<span style={{ color: "#ceff8f" }}>{EDUCATIONAL_LIST[item.education]}</span>
										</Item>
										<Item flex={2}>
											{item.adminAccount}｜{item.adminName}
										</Item>
										<Item>
											<Button.IconButton
												variant="blue"
												full={false}
												iconName="supervisor_account"
												type="outLined"
												onClick={() => {
													getGroupMember(item.uid);
												}}>
												成員管理
											</Button.IconButton>
										</Item>
									</Row>
								</>
						  ))
						: ""}
				</Body>
			</Table>
			{groupList && groupList.length > 10 && (
				<Pagination
					defaultCurrent={1}
					current={page.currentPage}
					onChange={pageChange}
					onShowSizeChange={onShowSizeChange}
					total={groupList.length}
					defaultPageSize={page.limit}
				/>
			)}
			<Modal
				width={"90%"}
				visible={modalVisible}
				footer={null}
				onCancel={() => {
					setModalVisible(false);
					setEdit(false);
				}}
				title={
					<div className="modalTitle">
						<div className="title">
							<Icon name="supervisor_account" type="outLined" color="#fff" bgcolor="#40a9ff" mr={"1"} />
							<span>成員管理</span>
						</div>
						<div className="titleBtn">
							{edit ? (
								<>
									<Button variant="white" full={false} onClick={resetGroupData}>
										取消
									</Button>
									<Button variant="green" disabled={saveBtnDisabled} full={false} onClick={updateGroupData}>
										儲存
									</Button>
								</>
							) : (
								<Button
									variant="green"
									full={false}
									disabled={!allowEdit}
									onClick={() => {
										setEdit(true);
									}}>
									編輯
								</Button>
							)}
						</div>
					</div>
				}>
				<div className="modalContent">
					{groupInfo && Object.keys(groupInfo).length > 0 ? (
						<>
							<div className="modalContentHeader">{groupInfo.name}-成員管理</div>
							<AddListTable title="qq">
								<AddListHeader>
									<AddListRow>
										<AddListItem flex={2.5}>姓名</AddListItem>
										<AddListItem flex={2}>身份</AddListItem>
										<AddListItem flex={2}>電子郵件</AddListItem>
										<AddListItem>操作</AddListItem>
									</AddListRow>
								</AddListHeader>
								<AddListBody>
									{modalInfo.admin && Object.keys(modalInfo.admin).length !== 0 && (
										<AddListRow isadmin={true}>
											<AddListItem flex={2.5}>
												{modalInfo.admin.name}｜{modalInfo.admin.account}
											</AddListItem>
											<AddListItem flex={2}>
												{ROLES[modalInfo.admin.identity]}
												<span className="admin">
													<Icon name="how_to_reg" />
													管理者
												</span>
											</AddListItem>
											<AddListItem flex={2}>{modalInfo.admin?.email || "--"}</AddListItem>
											<AddListItem>
												{edit ? (
													<Icon.Popconfirm
														icon={<Icon name="delete_forever" color="#FF4D4F" />}
														mr={"1"}
														name="delete_forever"
														title={`是否將「${modalInfo.admin?.name}」從${groupInfo.name}內移除?`}
														focuscolor="#FF4D4F"
														clickable={true}
														onConfirm={() => {
															onDeleteMember(modalInfo.admin, "admin");
														}}
													/>
												) : (
													"--"
												)}
											</AddListItem>
										</AddListRow>
									)}
									{edit ? (
										<AddListRow>
											<AddListItem flex={2.5}>
												<Select
													options={selectMemberOptions || []}
													value={selectMember}
													label="新增成員"
													onChange={(value) => onSelectChange(value)}
												/>
											</AddListItem>
											<AddListItem flex={2}></AddListItem>
											<AddListItem flex={2}></AddListItem>
											<AddListItem>
												<Icon name="person_add" disabled={!selectMember} clickable={true} onClick={onMemberAdd} />
											</AddListItem>
										</AddListRow>
									) : (
										""
									)}

									{modalInfo.member &&
										modalInfo.member.map((item, index) => (
											<AddListRow key={`${item.uid}${index}`}>
												<AddListItem flex={2.5}>
													{item.name}｜{item.account}
												</AddListItem>
												<AddListItem flex={2}>{ROLES[item.identity]}</AddListItem>
												<AddListItem flex={2}>{item.email}</AddListItem>
												<AddListItem>
													{edit ? (
														<>
															<Icon.Popconfirm
																icon={<Icon name="how_to_reg" color="#1890FF" />}
																mr={"1"}
																name="how_to_reg"
																title={`是否將「${item.name}」設為管理員?`}
																clickable={true}
																focuscolor="#1890FF"
																onConfirm={() => {
																	upgradeMemberToAdmin(item);
																}}
															/>
															<Icon.Popconfirm
																icon={<Icon name="delete_forever" color="#FF4D4F" />}
																mr={"1"}
																name="delete_forever"
																title={`是否將「${item.name}」從${groupInfo.name}內移除?`}
																clickable={true}
																focuscolor="#FF4D4F"
																onConfirm={() => {
																	onDeleteMember(item);
																}}
															/>
														</>
													) : (
														"--"
													)}
												</AddListItem>
											</AddListRow>
										))}
								</AddListBody>
							</AddListTable>
						</>
					) : (
						""
					)}
				</div>
			</Modal>
		</UiGroupAuthorityManagementPage>
	);
};

GroupAuthorityManagementPage.propTypes = {};
