import React, { useState, useEffect, useMemo } from "react";
import {
	Breadcrumb,
	Select,
	Button,
	Table,
	Pagination,
	Input,
	Modal,
	AuthProvider,
	Icon,
	Upload,
} from "common-components";
import { useHistory } from "react-router-dom";
import { setSelectOptions, sliceTableData, getTableAuthority, exportFile } from "utils/common";
import useQuery from "utils/hooks/useQuery";
import { Tag } from "components";
import { getTime } from "date-fns";
import { LANGUAGE_GROUP, POPUP_TITLE, COMMON_TEXT, INIT_AUTHORITY } from "constants/index";
import { createValidator, required, checkDuplicateName } from "utils/validation";
import fetchMachine, { FetchEventType, FetchStates } from "machines/fetchMachine";
import { useMachine } from "@xstate/react";
import classnames from "classnames";
import { UiLockModal, UiTop, UiActionBox } from "styles";
import {
	getSelection,
	getSubjectMeta,
	getSubjectRelated,
	createSubjectMeta,
	editSubjectMeta,
	getSingleSubjectRelated,
	lockSubjectTable,
	importSubjectMeta,
} from "api/subject";
import { useUpload } from "utils/hooks/useUpload";
import { UiSubjectListPage, UiPopupShowData, UiModalButtons } from "./SubjectListPage.style";

const Hierarchy = {
	FIRST: 1,
	SECOND: 2,
	THIRD: 3,
};

const rulesModel = {
	[COMMON_TEXT.EDIT]: {
		type: [{ message: "類型欄位為必填", validate: required }],
		name: [{ message: "名稱欄位不得空白", validate: required }],
	},
	[COMMON_TEXT.TOPIC]: {
		type: [{ message: "類型欄位為必填", validate: required }],
		name: [{ message: "名稱欄位不得空白", validate: required }],
		curriculum: [{ message: "課綱欄位不得空白", validate: required }],
	},
	[COMMON_TEXT.NO_HIERARCHY]: {
		name: [{ message: "名稱欄位不得空白", validate: required }],
		code: [{ message: "代碼欄位不得空白", validate: required }],
	},
	[COMMON_TEXT.OTHER]: {
		type: [{ message: "類型欄位為必填", validate: required }],
		name: [{ message: "名稱欄位不得空白", validate: required }],
		parentCode: [{ message: "主題/子題欄位不得空白", validate: required }],
	},
};

const hierarchyDicFormat = (data) => {
	const result = data
		? Object.values(data).map((item) => {
				return {
					...item,
					value: item.code,
				};
		  })
		: [];
	return result;
};

const renderNewFiber = (item, systemNow) => {
	const systemNowTime = getTime(new Date(systemNow));
	const itemTime = getTime(new Date(item.createTime));
	return (
		<div key={item.uid} className="haveFiber">
			{item.name}
			{systemNowTime - itemTime < 604800000 && (
				<i
					className={
						classnames("material-icons", "fiber") //7days
					}>
					fiber_new
				</i>
			)}
		</div>
	);
};

const parentMapFormat = (data, target) => {
	if (Object.keys(data).length === 0) return [];
	if (target === COMMON_TEXT.SUB_TOPIC) return setSelectOptions(data[COMMON_TEXT.TOPIC]);
	if (target === COMMON_TEXT.KNOWLEDGE) return setSelectOptions(data[COMMON_TEXT.SUB_TOPIC]);
};

const hierarchyDicText = {
	[COMMON_TEXT.KNOWLEDGE]: "子題",
	[COMMON_TEXT.SUB_TOPIC]: "主題",
};

const searchSelect = [
	{
		name: "代碼",
		value: "code",
	},
	{
		name: "名稱",
		value: "name",
	},
];

const metaTypeDefaultSearchKey = {
	LEARN_CONTENT: "code",
	PERFORMANCE: "code",
	CORE_ACCOMPLISHMENT: "code",
	KNOWLEDGE: "code",
	EXAM_ABILITY: "name",
	ABILITY: "code",
	CATEGORY: "name",
	BOOK_NAME: "name",
};

const { Header, Body, Row, Item } = Table;

/**
 * 科目資料
 */

const { useAuthState } = AuthProvider;
export const SubjectListPage = () => {
	const authState = useAuthState();
	const history = useHistory();
	const query = useQuery();
	const { executeUpload } = useUpload();
	const [breadcrumbGroup, setBreadcrumbGroup] = useState([]);

	const [lockText, setLockText] = useState("");

	const [state, setState] = useState({
		eduMapValue: "",
		eduSubjectValue: "",
		metaTypeValue: "",
		isLanguageType: "",
	});

	const [pageState, setPageState] = useState({
		page: 1,
		limit: 10,
	});

	const [searchState, setSearchState] = useState("");

	const [tag, setTag] = useState({
		id: "",
		hierarchy: "",
	});

	const [popupState, setPopupState] = useState({
		visible: false,
		mode: COMMON_TEXT.CREATE,
	});

	const [isCanDuplicate, setDuplicate] = useState(false);

	const [duplicateItem, setDuplicateItem] = useState("");

	const [formParams, setFormParams] = useState({});

	const [authorityState, setAuthority] = useState(INIT_AUTHORITY);

	const [lockPopState, setLockPop] = useState(false);

	const [files, setFiles] = useState([]);

	const [searchKey, setSearchKey] = useState("name");

	const [uploadPopup, setUploadPopup] = useState({
		visible: false,
		loading: false,
	});
	const [selectionState, selectSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const response = await getSelection();
				const { eduMap, eduSubject, metatypes } = response.data;
				return {
					eduMap: setSelectOptions(eduMap),
					eduSubject,
					metaTypes: metatypes,
				};
			},
		},
	});

	const { eduMap, eduSubject, metaTypes } = selectionState.context.result || {};

	const [subjectMetaState, subjectMetaSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const queryEdu = query.get("edu");
				const querySubject = query.get("subject");
				const queryMetaType = query.get("metaType");
				const queryParams = {
					eduSubject: queryEdu + querySubject,
					metaType: queryMetaType,
				};
				const { isSuccess, message, systemCode, data, systemNow } = await getSubjectMeta(
					event.queryParams || queryParams,
				);
				if (!data) {
					return {
						metaData: [],
					};
				}
				const nextMetaData = data.metaData.map((item) => {
					return {
						...item,
						nameText: item.name,
						name: renderNewFiber(item, systemNow),
					};
				});
				return {
					isSuccess,
					message,
					systemCode,
					...data,
					metaData: nextMetaData,
					isLock: data?.tableLock ? data?.tableLock?.isLock : undefined,
				};
			},
			createData: (_context, event) => createSubjectMeta(event.queryParams, event.payload),
			updateData: (_context, event) => {
				return event.apiType
					? lockSubjectTable(event.queryParams, event.payload)
					: editSubjectMeta(event.queryParams, event.payload);
			},
		},
	});

	const { metaData, eduSubject: selectedEduSubject, isLock, includeHierarchy } = subjectMetaState.context.result || {};

	const [relatedState, relatedSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const { data } = await getSubjectRelated(event.queryParams);
				return {
					...data,
				};
			},
		},
	});

	const [singleRelatedState, singleRelatedSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const {
					data: { existCode, existName },
				} = await getSingleSubjectRelated(event.queryParams, event.uid);
				return {
					editExistCode: existCode,
					editExistName: existName,
				};
			},
		},
	});

	const { hierarchy, hierarchyDic, curriculumMap, metaType, parentMap, existName, existCode } =
		relatedState.context.result || {};

	const { editExistName } = singleRelatedState.context.result || {};

	const isMultipleHierarchy = includeHierarchy > 1;

	const togglePopup = (state, mode) => {
		setPopupState({
			visible: state,
			mode,
		});
		setFormParams({});
	};

	const onUpdatePopup = (itemData) => {
		togglePopup(true, COMMON_TEXT.EDIT);
		const { nameText, uid, remark, code, curriculum, metaType } = itemData;
		const { eduMapValue, eduSubjectValue, metaTypeValue } = state;
		singleRelatedSend(FetchEventType.Fetch, {
			queryParams: {
				eduSubject: eduMapValue + eduSubjectValue,
				metaType: metaTypeValue,
			},
			uid,
		});
		setFormParams({
			name: {
				value: nameText,
			},
			uid: {
				value: uid,
			},
			remark: {
				value: remark,
			},
			selfCode: {
				value: code,
			},
			curriculum: {
				value: curriculum,
			},
			type: {
				value: metaType,
			},
		});
	};

	const submitDisabled = useMemo(() => {
		if (!popupState.visible) return;
		const type = formParams.type;
		let rules = "";
		switch (type) {
			case COMMON_TEXT.TOPIC:
				if (popupState.mode === COMMON_TEXT.CREATE) {
					rules = rulesModel[COMMON_TEXT.TOPIC];
				}
				break;
			case COMMON_TEXT.SUB_TOPIC:
				if (!includeHierarchy && popupState.mode === COMMON_TEXT.CREATE) {
					rules = rulesModel[COMMON_TEXT.OTHER];
				}
				break;
			case COMMON_TEXT.KNOWLEDGE:
				if (popupState.mode === COMMON_TEXT.CREATE) {
					if (state.isLanguageType) {
						rules = rulesModel[COMMON_TEXT.NO_HIERARCHY];
						delete rules.code;
					} else {
						rules = rulesModel[COMMON_TEXT.OTHER];
					}
				}
				break;
			default:
				if (!isMultipleHierarchy && popupState.mode === COMMON_TEXT.CREATE) {
					rules = rulesModel[COMMON_TEXT.NO_HIERARCHY];
				}
				break;
		}
		const allSubmitDataLength = formParams.type ? Object.keys(rules).length : -1;
		if (Object.keys(formParams).length >= allSubmitDataLength) {
			for (const [, value] of Object.entries(formParams)) {
				if (value.message) {
					return true;
				}
			}
			return false;
		}
		return false;
	}, [popupState.mode, formParams, relatedState.value]);

	const onChange = (key, value) => {
		if (key === "eduMapValue") {
			setState({
				eduSubjectValue: "",
				metaTypeValue: "",
				eduMapValue: value,
			});
		} else {
			setState({
				...state,
				[key]: value,
			});
		}
	};

	const onFormChange = (key, value) => {
		setFormParams({
			...formParams,
			[key]: {
				value,
				message: "",
			},
		});
	};

	const setFormFetch = (payload) => {
		const queryParams = {
			eduSubject: selectedEduSubject,
			metaType: metaType,
		};
		popupState.mode === COMMON_TEXT.CREATE
			? subjectMetaSend(FetchEventType.Create, { queryParams, payload })
			: subjectMetaSend(FetchEventType.Update, { queryParams, payload });
	};

	const lockTable = (isLock) => {
		const payload = {
			isLock,
		};
		const queryParams = {
			eduSubject: selectedEduSubject,
			metaType: metaType,
		};
		setLockPop(false);
		subjectMetaSend(FetchEventType.Update, { queryParams, payload, apiType: "lock" });
	};

	const onSubmitHandle = () => {
		const inputValues = {
			name: "",
			type: metaType,
			code: "",
			remark: "",
			parentCode: "",
		};
		Object.entries(formParams).forEach(([key, value]) => {
			inputValues[key] = value.value;
		});
		let rules = {};
		switch (inputValues.type) {
			case COMMON_TEXT.TOPIC:
				if (popupState.mode === COMMON_TEXT.CREATE) {
					inputValues.code = inputValues.curriculum;
					rules = rulesModel[COMMON_TEXT.TOPIC];
				}
				break;
			case COMMON_TEXT.SUB_TOPIC:
				if (!includeHierarchy && popupState.mode === COMMON_TEXT.CREATE) {
					rules = rulesModel[COMMON_TEXT.OTHER];
				}
				break;
			case COMMON_TEXT.KNOWLEDGE:
				if (popupState.mode === COMMON_TEXT.CREATE) {
					if (state.isLanguageType) {
						inputValues.code = inputValues.curriculum || "";
						rules = rulesModel[COMMON_TEXT.NO_HIERARCHY];
						delete rules.code;
					} else {
						rules = rulesModel[COMMON_TEXT.OTHER];
					}
				}
				break;
			default:
				if (!isMultipleHierarchy && popupState.mode === COMMON_TEXT.CREATE) {
					rules = rulesModel[COMMON_TEXT.NO_HIERARCHY];
				}
				break;
		}
		if (popupState.mode === COMMON_TEXT.EDIT) {
			rules = rulesModel[popupState.mode];
			inputValues.code = inputValues.selfCode;
			delete inputValues.parentCode;
		}
		const [isPass, errorGroup] = createValidator(inputValues, rules);
		console.log("errorGroup", errorGroup);
		setFormParams({ ...formParams, ...errorGroup });
		if (isPass) return;
		const { curriculum } = inputValues;
		const checkCode =
			curriculum ||
			parentMapFormat(parentMap, inputValues.type).filter((item) => item.value === inputValues.parentCode)[0]
				?.curriculum ||
			"";
		if (popupState.mode === COMMON_TEXT.CREATE) {
			if (!checkDuplicateName(inputValues.name, existName[inputValues.type][checkCode])) {
				setDuplicate(true);
				setDuplicateItem("名稱 : " + inputValues.name);
				return;
			}
			if (hierarchyDic[formParams["type"]?.value || metaType].isCreateCode) {
				if (!checkDuplicateName(inputValues.code, existCode[inputValues.type])) {
					setDuplicate(true);
					setDuplicateItem("代碼 : " + inputValues.code);
					return;
				}
			}
		} else {
			if (!checkDuplicateName(inputValues.name, editExistName[inputValues.type][checkCode])) {
				setDuplicate(true);
				setDuplicateItem("名稱 : " + inputValues.name);
				return;
			}
		}

		delete inputValues.curriculum;
		setFormFetch(inputValues);
	};

	const duplicateSubmit = () => {
		const inputValues = {
			name: "",
			type: metaType,
			code: "",
			remark: "",
			parentCode: "",
		};
		Object.entries(formParams).forEach(([key, value]) => {
			inputValues[key] = value.value;
		});
		if (popupState.mode === COMMON_TEXT.EDIT) {
			inputValues.code = inputValues.selfCode;
			delete inputValues.curriculum;
		}
		setFormFetch(inputValues);
	};

	const onPageChangeHandle = (page, limit) => {
		setPageState({
			page,
			limit,
		});
	};

	const formatMetaTypes = (data, edu, eduSubject) => {
		if (!edu || !eduSubject) return [];
		const knowledgeName = LANGUAGE_GROUP.includes(eduSubject) ? "課名" : "知識向度";
		const result = data[edu];
		const nextResult = setSelectOptions(
			result.filter((meta) => {
				if (meta.subjects === null || meta.subjects.includes(eduSubject)) return meta;
			}),
		).map((item) => {
			if (item.value === "KNOWLEDGE") {
				return {
					name: knowledgeName,
					value: item.value,
				};
			}
			return item;
		});
		return nextResult;
	};

	const setBreadAndLockModalTextHandle = (edu, subject, meta) => {
		const eduName = eduMap.filter((item) => item.value === edu)[0]?.name || "";
		const subjectName = setSelectOptions(eduSubject[edu]).filter((item) => item.value === subject)[0]?.name || "";
		const metaName = formatMetaTypes(metaTypes, edu, subject).filter((item) => item.value === meta)[0]?.name || "";
		const breadcrumbGroup = [
			{
				icon: "home",
				name: "首頁-異動紀錄",
				path: "/",
			},
			{
				icon: "domain_verification",
				name: "科目資料",
				path: "/subject",
			},
			{
				icon: "book",
				name: `${eduName}${subjectName} - ${metaName}`,
				path: `/subject/list?edu=${edu}&subject=${subject}&metaType=${meta}`,
			},
		];
		setLockText(`是否要將${eduName}${subjectName} - ${metaName}表${isLock ? "進行解鎖" : "進行鎖定"}？`);
		setBreadcrumbGroup(breadcrumbGroup);
	};

	const searchSubject = () => {
		const { eduMapValue, eduSubjectValue, metaTypeValue } = state;
		setBreadAndLockModalTextHandle(eduMapValue, eduSubjectValue, metaTypeValue);
		const queryParams = {
			eduSubject: eduMapValue + eduSubjectValue,
			metaType: metaTypeValue,
		};

		const { authority, tableAuthority } = getTableAuthority(
			metaTypeValue,
			authState.context.authority.function.EduSubject,
		);
		setAuthority({
			...authorityState,
			authority,
			tableAuthority,
		});
		setSearchState("");
		subjectMetaSend(FetchEventType.Fetch, { queryParams });
		history.push(`/subject/list?edu=${eduMapValue}&subject=${eduSubjectValue}&metaType=${metaTypeValue}`);
	};

	const onSearchHandle = (event) => {
		setSearchState(event.target.value);
		setPageState({
			...pageState,
			page: 1,
		});
	};

	const onTagFilterHandle = (tagId, hierarchy) => {
		setTag({
			id: tagId,
			hierarchy,
		});
	};

	const clearTag = () => {
		setTag({
			id: "",
			hierarchy: "",
		});
	};

	const exportHandle = () => {
		const queryEdu = query.get("edu");
		const querySubject = query.get("subject");
		const queryMetaType = query.get("metaType");
		const url = `${process.env.REACT_APP_DOMAIN}/api/SubjectMeta/${queryEdu + querySubject}/${queryMetaType}/Export`;
		exportFile(url, "get");
	};

	const toggleUploadPopupVisible = () => {
		setUploadPopup({
			...uploadPopup,
			visible: !uploadPopup.visible,
		});
	};

	const changeFileList = (value) => {
		setFiles(value);
	};

	const submitFiles = async () => {
		if (files.length === 0) return;
		const queryEdu = query.get("edu");
		const querySubject = query.get("subject");
		const queryMetaType = query.get("metaType");
		const queryParams = {
			eduSubject: queryEdu + querySubject,
			metaType: queryMetaType,
		};
		setUploadPopup({
			...uploadPopup,
			loading: true,
		});
		await executeUpload(files, "subject", importSubjectMeta(queryParams));
		setUploadPopup({
			visible: false,
			loading: false,
		});
		setFiles([]);
		subjectMetaSend(FetchEventType.Fetch, {
			queryParams,
		});
	};

	const subjectMetaData = useMemo(() => {
		if (!subjectMetaState.matches(FetchStates.Resolved) && !relatedState.matches(FetchStates.Resolved)) return [];

		const searchFilterResult =
			metaData?.filter((item) => {
				if (searchKey === "name") {
					return item.nameText.indexOf(searchState) > -1;
				} else {
					return item.code.indexOf(searchState) > -1;
				}
			}) || [];
		if (!isMultipleHierarchy) {
			return searchFilterResult;
		} else {
			const multipleHierarchy =
				metaData &&
				metaData
					.map((item, index, array) => {
						let firstHierarchy = "";
						let secHierarchyId = "";
						let secHierarchy = "";
						const filterHierarchy = state.isLanguageType ? includeHierarchy : item.hierarchy;
						switch (filterHierarchy) {
							case Hierarchy.FIRST:
								return {
									...item,
									knowledgeCatalog: [],
									knowEl: "",
								};
							case Hierarchy.SECOND:
								firstHierarchy = array.filter((i) => i.uid === item.parentUID)[0].code;
								return {
									...item,
									knowledgeCatalog: [firstHierarchy],
									knowEl: <Tag label={firstHierarchy} color="blue" onClick={(tag) => onTagFilterHandle(tag, 1)} />,
								};
							case Hierarchy.THIRD:
								secHierarchy = array.filter((i) => i.uid === item.parentUID)[0]?.code;
								secHierarchyId = array.filter((i) => i.uid === item.parentUID)[0]?.parentUID;
								firstHierarchy = array.filter((i) => i.uid === secHierarchyId)[0]?.code;
								return {
									...item,
									knowledgeCatalog: [firstHierarchy, secHierarchy],
									knowEl: (
										<>
											<Tag label={firstHierarchy} color="blue" mr="2" onClick={(tag) => onTagFilterHandle(tag, 1)} />
											<Tag label={secHierarchy} color="red" onClick={(tag) => onTagFilterHandle(tag, 2)} />
										</>
									),
								};
							default:
								return {
									...item,
								};
						}
					})
					.filter((item) => {
						if (searchKey === "name") {
							return item.nameText.indexOf(searchState) > -1;
						} else {
							return item.code.indexOf(searchState) > -1;
						}
					});
			return tag.id ? multipleHierarchy.filter((item) => item.knowledgeCatalog.includes(tag.id)) : multipleHierarchy;
		}
	}, [subjectMetaState.value, searchState, tag.id, metaData, relatedState.value, searchKey, includeHierarchy]);

	useEffect(() => {
		selectSend(FetchEventType.Fetch);
	}, []);

	useEffect(() => {
		if (!eduMap || !selectionState.matches(FetchStates.Resolved)) return;
		const queryEdu = query.get("edu");
		const querySubject = query.get("subject");
		const queryMetaType = query.get("metaType");
		const { authority, tableAuthority } = getTableAuthority(
			queryMetaType,
			authState.context.authority.function.EduSubject,
		);
		setAuthority({
			...authorityState,
			authority,
			tableAuthority,
		});
		setBreadAndLockModalTextHandle(queryEdu, querySubject, queryMetaType);
		subjectMetaSend(FetchEventType.Fetch, {
			queryParams: {
				eduSubject: queryEdu + querySubject,
				metaType: queryMetaType,
			},
		});
	}, [selectionState.value, authState.value]);

	useEffect(() => {
		if (!metaData || metaData.length === 0 || !subjectMetaState.matches(FetchStates.Resolved)) return;
		const queryEdu = query.get("edu");
		const querySubject = query.get("subject");
		const queryMetaType = query.get("metaType");
		setSearchKey(metaTypeDefaultSearchKey[queryMetaType] ? metaTypeDefaultSearchKey[queryMetaType] : "name");
		relatedSend(FetchEventType.Fetch, {
			queryParams: {
				eduSubject: queryEdu + querySubject,
				metaType: queryMetaType,
			},
		});
		setState({
			...state,
			eduMapValue: queryEdu,
			eduSubjectValue: querySubject,
			metaTypeValue: queryMetaType,
			isLanguageType: queryMetaType === "KNOWLEDGE" && LANGUAGE_GROUP.includes(querySubject),
		});
		togglePopup(false, "");
		setDuplicate(false);
	}, [subjectMetaState.value, authState.value]);

	return (
		<>
			<UiTop>
				<UiActionBox>
					<Button.IconButton
						variant="white"
						full={false}
						iconName="keyboard_backspace"
						onClick={() => {
							history.push(`/subject?education=${state.eduMapValue}&subject=${state.eduSubjectValue}`);
						}}>
						回上層
					</Button.IconButton>
					<Breadcrumb data={breadcrumbGroup} />
				</UiActionBox>
			</UiTop>
			<UiSubjectListPage>
				<UiLockModal>
					<Modal
						visible={lockPopState}
						footer={
							<UiModalButtons>
								<Button.IconButton
									iconName="close"
									variant="white"
									full={false}
									Key="cancel"
									onClick={() => {
										setLockPop(false);
									}}>
									取消
								</Button.IconButton>
								<Button.IconButton
									variant="blue"
									full={false}
									iconName="panorama_fish_eye"
									onClick={() => {
										lockTable(!isLock);
									}}
									Key="ok">
									確認
								</Button.IconButton>
							</UiModalButtons>
						}>
						<div className="lockModalIcon">
							{isLock ? (
								<Icon name="lock_open" color="#000" type="outLined" />
							) : (
								<Icon name="lock" color="#ffb800" type="outLined" />
							)}
						</div>
						<div className="lockModalContent">
							{lockText}
							<div className="lockModalHint">{isLock ? "解鎖之後編輯可隨意進行修改" : "鎖定之後編輯無法進行修改"}</div>
						</div>
					</Modal>
				</UiLockModal>

				<div className="actionBox">
					{selectionState.matches(FetchStates.Resolved) && (
						<div className="left">
							<Select options={eduMap} value={state.eduMapValue} onChange={(value) => onChange("eduMapValue", value)} />
							<Select
								options={setSelectOptions(eduSubject[state.eduMapValue] || [])}
								value={state.eduSubjectValue}
								onChange={(value) => onChange("eduSubjectValue", value)}
							/>
							<Select
								options={formatMetaTypes(metaTypes, state.eduMapValue, state.eduSubjectValue)}
								value={state.metaTypeValue}
								onChange={(value) => onChange("metaTypeValue", value)}
							/>
							<Button.IconButton variant="blue" full={false} iconName="search" onClick={searchSubject}>
								查詢
							</Button.IconButton>
							{tag.id && (
								<Tag label={tag.id} color={tag.hierarchy === 1 ? "blue" : "red"} onClick={clearTag}>
									<i className="material-icons">close</i>
								</Tag>
							)}
						</div>
					)}
					<div className="right">
						{/* https://team-oneclass.atlassian.net/browse/RLIB-1 重要功能按鈕暫時隱藏 */}
						{/* {authorityState.tableAuthority.Lock && isLock !== undefined ? (
							<>
								{isLock ? (
									<Button.IconButton
										variant="white"
										full={false}
										iconName="lock_open"
										onClick={() => {
											setLockPop(true);
										}}>
										解鎖
									</Button.IconButton>
								) : (
									<Button.IconButton
										variant="orange"
										full={false}
										iconName="lock"
										onClick={() => {
											setLockPop(true);
										}}>
										鎖定
									</Button.IconButton>
								)}
							</>
						) : (
							""
						)} */}
						{authorityState.tableAuthority.Import ? (
							<Button.IconButton variant="blue" full={false} iconName="cloud_upload" onClick={toggleUploadPopupVisible}>
								匯入
							</Button.IconButton>
						) : (
							""
						)}
						{authorityState.tableAuthority.Export ? (
							<Button.IconButton variant="blue" full={false} iconName="save_alt" onClick={exportHandle}>
								匯出
							</Button.IconButton>
						) : (
							""
						)}
						{authorityState.tableAuthority.Edit ? (
							<Button.IconButton
								variant="blue"
								full={false}
								iconName="add"
								onClick={() => togglePopup(true, COMMON_TEXT.CREATE)}>
								新增
							</Button.IconButton>
						) : (
							""
						)}
						<div className="miniSelect">
							<Select
								variant="dark"
								options={searchSelect}
								value={searchKey}
								getPopupContainer={() => document.getElementsByClassName("miniSelect")[0]}
								onChange={(value) => {
									setSearchKey(value);
								}}
							/>
						</div>
						<Input.Search mode="dark" value={searchState} onChange={onSearchHandle} placeholder="搜尋" />
					</div>
				</div>
			</UiSubjectListPage>
			<Table margin={20}>
				<Header>
					<Row>
						{includeHierarchy < 2 || <Item>階層</Item>}
						<Item>代碼</Item>
						<Item flex="2">名稱</Item>
						{includeHierarchy < 2 || <Item flex="2">知識分類</Item>}
						<Item>備註</Item>
						{/* https://team-oneclass.atlassian.net/browse/RLIB-1 重要功能按鈕暫時隱藏 */}
						{/* <Item>編輯</Item> */}
					</Row>
				</Header>
				<Body>
					{metaData &&
						sliceTableData(subjectMetaData, pageState.page, pageState.limit).map((meta) => {
							return (
								<Row key={meta.uid}>
									{isMultipleHierarchy && <Item>{meta.hierarchy}</Item>}
									<Item>{meta.code}</Item>
									<Item flex="2">{meta.name}</Item>
									{isMultipleHierarchy && <Item flex="2">{meta.knowEl}</Item>}
									<Item>{meta.remark}</Item>
									{/* https://team-oneclass.atlassian.net/browse/RLIB-1 重要功能按鈕暫時隱藏 */}
									{/* <Item>
										{authorityState.tableAuthority.Edit && !isLock ? (
											<Button.IconButton
												variant="orange"
												full={false}
												iconName="edit"
												onClick={() => onUpdatePopup(meta)}>
												編輯
											</Button.IconButton>
										) : (
											""
										)}
									</Item> */}
								</Row>
							);
						})}
				</Body>
			</Table>
			{metaData && <Pagination total={subjectMetaData.length} onChange={onPageChangeHandle} current={pageState.page} />}
			<Modal
				title={POPUP_TITLE[popupState.mode]}
				visible={popupState.visible}
				okButtonProps={{ ...Modal.okButtonProps, disabled: submitDisabled }}
				onCancel={() => togglePopup(false, "")}
				onOk={onSubmitHandle}>
				{isMultipleHierarchy && popupState.mode !== COMMON_TEXT.EDIT && (
					<Select
						label="類型"
						options={hierarchyDicFormat(hierarchyDic) || []}
						onChange={(value) => onFormChange("type", value)}
						value={formParams["type"]?.value || ""}
						message={formParams["type"]?.message || ""}
					/>
				)}
				{popupState.mode === COMMON_TEXT.CREATE ? (
					hierarchyDic &&
					hierarchyDic[formParams["type"]?.value || metaType].isCreateCode && (
						<Input
							label="代碼"
							placeholder="請輸入代碼*"
							onChange={(e) => onFormChange("code", e.target.value)}
							value={formParams["code"]?.value || ""}
							message={formParams["code"]?.message || ""}
						/>
					)
				) : (
					<UiPopupShowData>
						代碼 <span>【{formParams["selfCode"]?.value}】</span>
					</UiPopupShowData>
				)}
				{popupState.mode === COMMON_TEXT.CREATE &&
					hierarchyDic &&
					!state.isLanguageType &&
					hierarchyDic[formParams["type"]?.value || metaType].showCurriculumMap &&
					curriculumMap && (
						<Select
							label="課綱"
							options={setSelectOptions(curriculumMap)}
							onChange={(value) => onFormChange("curriculum", value)}
							message={formParams["curriculum"]?.message || ""}
							value={formParams["curriculum"]?.value || ""}
						/>
					)}
				{popupState.mode === COMMON_TEXT.CREATE && state.isLanguageType && curriculumMap && (
					<Select
						label="課綱"
						options={setSelectOptions(curriculumMap)}
						onChange={(value) => onFormChange("curriculum", value)}
						message={formParams["curriculum"]?.message || ""}
						value={formParams["curriculum"]?.value || ""}
					/>
				)}
				{(formParams.type?.value === COMMON_TEXT.KNOWLEDGE || formParams.type?.value === COMMON_TEXT.SUB_TOPIC) &&
					isMultipleHierarchy &&
					popupState.mode === COMMON_TEXT.CREATE && (
						<Select
							label={hierarchyDicText[formParams.type.value] || ""}
							options={parentMapFormat(parentMap, formParams.type.value)}
							value={formParams.parentCode?.value || ""}
							message={formParams.parentCode?.message || ""}
							onChange={(value) => onFormChange("parentCode", value)}
						/>
					)}
				<Input.TextArea
					label="名稱"
					placeholder="請輸入名稱*"
					onChange={(e) => onFormChange("name", e.target.value)}
					value={formParams["name"]?.value || ""}
					message={formParams["name"]?.message || ""}
				/>
				<Input
					label="備註"
					placeholder="備註"
					onChange={(e) => onFormChange("remark", e.target.value)}
					value={formParams["remark"]?.value || ""}
				/>
			</Modal>
			<Modal.WarningModal visible={isCanDuplicate} onOk={duplicateSubmit} onCancel={() => setDuplicate(false)}>
				資料：{duplicateItem} 已存在, 請確認輸入內容。
			</Modal.WarningModal>
			<Modal
				confirmLoading={uploadPopup.loading}
				visible={uploadPopup.visible}
				onCancel={toggleUploadPopupVisible}
				okText={uploadPopup.loading ? "上傳中..." : "確定"}
				onOk={submitFiles}
				title="上傳檔案">
				<Upload
					fileList={files}
					onChange={changeFileList}
					prompt={
						<>
							僅限上傳一個Microsoft Excel 檔<br />
							(副檔名 : .xls/.xlsx)
						</>
					}
					acceptFileType=".xls,.xlsx"
				/>
			</Modal>
		</>
	);
};
