import styled from "styled-components";
import { Drawer, Modal, Select } from "antd";

export const UiCustomDrawer = styled.div`
	cursor: pointer;

	.container {
		display: flex;

		:hover > .buttonGroup {
			display: flex;
			gap: 8px;
		}
	}

	.title {
		font-size: 16px;
	}
	.description {
		font-size: 14px;
		color: ${(props) => (props.isNow ? "#000000D9" : "#00000073")};
	}

	.buttonGroup {
		display: none;
		color: red;

		:has(.ant-select-open) {
			display: flex;
			gap: 8px;
		}
	}
`;

export const CustomDrawerContent = styled(Drawer)`
	.ant-timeline-item-tail {
		border-left: 1px solid #1890ff;
	}
`;

export const UiCustomModal = styled(Modal)`
	.ant-modal-body {
		max-height: 500px;
		overflow: auto;
	}
	.ant-modal-footer {
		padding-top: 24px;
		.ant-btn {
			display: ${(props) => (props.restore ? "none" : "inline-block")};
		}
	}
`;
export const UiCustomSelect = styled(Select)`
	display: flex;
	align-items: center;

	&.ant-select {
		.ant-select-selector {
			color: black;
			background-color: #ffb800;
			border: 0;
			width: 120px;
			font-size: 14px;
			font-weight: 400;
			display: flex;
			align-items: center;
		}
	}
`;
