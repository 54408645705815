import {
	HomePage,
	LoginPage,
	BasicPage,
	SubjectPage,
	QuestionTypePage,
	SourcePage,
	EduSubjectPage,
	SubjectListPage,
	ChapterPage,
	ChapterListPage,
	ProductSearchPage,
	AccountAuthorityManagementPage,
	GroupAuthorityManagementPage,
	KnowledgeDetailPage,
	AuthorityDefaultSettingPage,
} from "views";
import NewSubjectPage from "views/NewSubjectPage/index";
import { MainLayout } from "layouts";
const routes = [
	{
		path: "/login",
		component: LoginPage,
		exact: true,
	},
	{
		path: "/",
		component: MainLayout,
		exact: true,
		routes: [
			{
				path: "/",
				component: HomePage,
				name: "home",
				exact: true,
			},
		],
	},
	{
		path: "/publicCode",
		component: MainLayout,
		routes: [
			{
				path: "/publicCode",
				component: BasicPage,
				name: "publicCode",
				exact: true,
			},
			{
				path: "/publicCode/questionType",
				component: QuestionTypePage,
				name: "ques_type",
				exact: true,
			},
			{
				path: "/publicCode/source",
				component: SourcePage,
				name: "source",
				exact: true,
			},
			{
				path: "/publicCode/eduSubject",
				component: EduSubjectPage,
				name: "edu_subject",
				exact: true,
			},
		],
	},
	{
		path: "/subject",
		component: MainLayout,
		routes: [
			{
				path: "/subject",
				component: SubjectPage,
				name: "subject",
				exact: true,
			},
			{
				path: "/subject/list",
				component: SubjectListPage,
				name: "subjectList",
				exact: true,
			},
		],
	},
	{
		path: "/newSubject",
		component: MainLayout,
		routes: [
			{
				path: "/newSubject",
				component: NewSubjectPage.BasicPage,
				name: "newSubject",
				exact: true,
			},
			{
				path: "/newSubject/info",
				component: NewSubjectPage.InfoPage,
				name: "newSubjectInfo",
				exact: true,
			},
			{
				path: "/newSubject/chapter",
				component: NewSubjectPage.ChapterPage,
				name: "newSubjectChapter",
				exact: true,
			},
			{
				path: "/newSubject/chapter/detail",
				component: NewSubjectPage.ChapterDetailPage,
				name: "newSubjectChapterDetail",
				exact: true,
			},
			{
				path: "/newSubject/knowledge",
				component: NewSubjectPage.KnowledgePage,
				name: "newSubjectKnowledge",
				exact: true,
			},
			{
				path: "/newSubject/englishListening",
				component: NewSubjectPage.EnglishListeningPage,
				name: "newSubjectEnglishListening",
				exact: true,
			},
			{
				path: "/newSubject/englishListening/detail",
				component: NewSubjectPage.EnglishListeningDetailPage,
				name: "newSubjectEnglishListening",
				exact: true,
			},
			{
				path: "/newSubject/englishListeningQuestionType",
				component: NewSubjectPage.EnglishListeningQuestionTypePage,
				name: "newSubjectEnglishListeningQuestionType",
				exact: true,
			},
		],
	},
	{
		path: "/chapter",
		component: MainLayout,
		routes: [
			{
				path: "/chapter",
				component: ChapterPage,
				name: "chapter",
				exact: true,
			},
			{
				path: "/chapter/list",
				component: ChapterListPage,
				name: "chapterList",
				exact: true,
			},
			{
				path: "/chapter/knowledgeDetail",
				component: KnowledgeDetailPage,
				name: "knowledgeDetail",
				exact: true,
			},
		],
	},
	{
		path: "/productSearch",
		component: MainLayout,
		routes: [
			{
				path: "/productSearch",
				component: ProductSearchPage,
				name: "productSearch",
				exact: true,
			},
		],
	},
	{
		path: "/accountAuthorityManagement",
		component: MainLayout,
		routes: [
			{
				path: "/accountAuthorityManagement",
				component: AccountAuthorityManagementPage,
				name: "accountAuthorityManagement",
				exact: true,
			},
		],
	},
	{
		path: "/groupAuthorityManagement",
		component: MainLayout,
		routes: [
			{
				path: "/groupAuthorityManagement",
				component: GroupAuthorityManagementPage,
				name: "groupAuthorityManagement",
				exact: true,
			},
		],
	},
	{
		path: "/authorityDefaultSetting",
		component: MainLayout,
		routes: [
			{
				path: "/authorityDefaultSetting",
				component: AuthorityDefaultSettingPage,
				name: "authorityDefaultSetting",
				exact: true,
			},
		],
	},
];
export default routes;
