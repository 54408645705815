"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiTitleContainer = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var UiTitleContainer = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tdisplay: flex;\n\tflex-direction: column;\n\theight: 100vh;\n\tmax-height: 100%;\n\tbackground-color: #fff;\n\tborder: 1px solid #f0f0f0;\n\n\t> .header {\n\t\tborder-bottom: 1px solid #f0f0f0;\n\t}\n\n\t.container {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\toverflow-x: hidden;\n\t\tpadding: 10px;\n\t\tflex-direction: column;\n\t\tflex: 1;\n\t}\n"])));

exports.UiTitleContainer = UiTitleContainer;