import BasicPage from "./BasicPage/index";
import InfoPage from "./InfoPage/index";
import ChapterPage from "./ChapterPage/index";
import ChapterDetailPage from "./ChapterDetailPage/index";
import KnowledgePage from "./KnowledgePage/index";
import EnglishListeningPage from "./EnglishListeningPage/index";
import EnglishListeningDetailPage from "./EnglishListeningDetailPage/index";
import EnglishListeningQuestionTypePage from "./EnglishListeningQuestionTypePage/EnglishListeningQuestionTypePage";

export default {
	BasicPage,
	InfoPage,
	ChapterPage,
	ChapterDetailPage,
	KnowledgePage,
	EnglishListeningPage,
	EnglishListeningDetailPage,
	EnglishListeningQuestionTypePage,
};
