import styled from "styled-components";

export const UiHomePage = styled.div`
	display: block;
`;
export const UiHomeTop = styled.div`
	display: flex;
	align-items: flex-end;
	flex: 1;
	margin-bottom: 20px;
	flex-wrap: wrap;
`;
export const UiHomeActionBox = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
	justify-content: flex-end;

	& > * + * {
		margin-left: 5px;
	}

	.homeInput {
		max-width: 220px;
	}
`;
export const UiTransaction = styled.div`
	color: #ffb800;
`;
