import React, { useEffect, useState } from "react";
import { useMachine } from "@xstate/react";
import { format } from "date-fns";
import { setSelectOptions, sliceTableData, calStudentYear } from "utils/common";
import { EDUCATIONAL } from "constants/index";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import { getProduct } from "api/definition";
import { Breadcrumb, Button, Table, Pagination, FilterBar, PickListDropdown } from "common-components";
import { UiProductSearchPage } from "./ProductSearchPage.style";

/**
 * 產品查詢頁
 */

const breadcrumbList = [
	{
		icon: "pageview",
		name: "產品查詢",
		path: "/ruleSearchPage",
	},
];

const intersection = (target, compare) => {
	const arrSet = new Set(compare);
	return arrSet.has(target);
};

const intersectionArray = (target, compare) => {
	const arrSet = new Set(compare);
	let isFilter = false;
	target.forEach((item) => {
		if (arrSet.has(item)) {
			isFilter = true;
			return;
		}
	});
	return isFilter;
};

const search = (target, compare) => compare.indexOf(target) !== -1;

export const ProductSearchPage = () => {
	const [year, setYear] = useState("");
	const [filterParams, setState] = useState({
		education: [],
		books: [],
		version: [],
		subject: [],
		productType: [],
		name: "",
	});
	const [pageState, setPageState] = useState({
		currentPage: 1,
		limit: 10,
	});

	const pickListChange = (key, value) => {
		setState({
			...filterParams,
			[key]: value,
		});
	};
	const pageChange = (currentPage, pageSize) => {
		setPageState({
			...pageState,
			currentPage,
			limit: pageSize,
		});
	};

	const [stateSpecificationProduct, sendSpecificationProduct] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const {
					data: {
						products,
						selection: { bookMap, eduMap, subjectMap, versionMap, yearMap, productMap: productTypeMap },
					},
				} = await getProduct(event.year);
				const nextProducts = products.map((item) => {
					const eduText = EDUCATIONAL.filter((edu) => edu.value === item.education)[0].name;
					const subjectText = subjectMap.filter((subject) => subject.code === item.subject)[0].name;
					const versionText = versionMap.filter((version) => version.code === item.version)[0].name;
					const bookList = item.books.map((book, index, arr) => {
						let bookName = "";
						bookMap.forEach((item) => {
							if (item.code === book) bookName = item.name;
						});
						return (
							<span key={index}>
								{bookName}
								{index !== arr.length - 1 && " | "}
							</span>
						);
					});
					setPageState({
						...pageState,
						currentPage: 1,
						limit: 10,
					});
					//貌似可以把值reset
					return {
						...item,
						eduText,
						subjectText,
						bookList,
						versionText,
					};
				});
				return {
					products: nextProducts,
					bookMap: setSelectOptions(bookMap || []),
					eduMap: setSelectOptions(eduMap || []),
					subjectMap: setSelectOptions(subjectMap || []),
					versionMap: setSelectOptions(versionMap || []),
					productTypeMap: setSelectOptions(productTypeMap || []),
					yearMap: setSelectOptions(yearMap || []),
				};
			},
		},
	});
	const { products, bookMap, eduMap, subjectMap, versionMap, yearMap, productTypeMap } =
		stateSpecificationProduct.context.result || {};

	const productsFilter = (arr) => {
		if (!arr) return [];
		const result = arr.filter((item) => {
			const checkResult = Object.entries(filterParams).map(([key, value]) => {
				if (value.length === 0) return true;
				if (key === "books") {
					return intersectionArray(item[key], value);
				} else if (key === "name") {
					return search(value, item[key]);
				} else if (key === "productType") {
					return search(value, item["type"]);
				} else {
					return intersection(item[key], value);
				}
			});

			const checkResultSet = new Set(checkResult);
			if (!checkResultSet.has(false)) return item;
		});
		return result;
	};

	const detailPageHandler = (product) => {
		const productType = product.type;
		const { uid } = product;
		let domain = "";
		switch (productType) {
			case "QuestionBank":
				domain = process.env.REACT_APP_QUESTION_BANK_DOMAIN;
				break;
			default:
				break;
		}
		window.location.href = `${domain}/ruleSearchDetail/${uid}`;
	};

	const onYearChange = (year) => {
		setYear(year);
		sendSpecificationProduct(FetchEventType.Fetch, {
			year,
		});
		setState({
			...filterParams,
			education: [],
			books: [],
			version: [],
			subject: [],
			productType: [],
		});
	};

	useEffect(() => {
		onYearChange(calStudentYear().toString());
	}, []);

	return (
		<UiProductSearchPage>
			<div className="breadBlock">
				<Breadcrumb data={breadcrumbList} />
			</div>
			<div className="filterBlock">
				<FilterBar onChange={(value) => pickListChange("name", value)}>
					<PickListDropdown.SinglePickListDropdown
						defaultTitle="學年度"
						options={yearMap || []}
						onClick={(value) => onYearChange(value)}
						value={year}
					/>
					<PickListDropdown
						defaultTitle="學制"
						options={eduMap || []}
						onChange={(value) => pickListChange("education", value)}
						value={filterParams.education}
					/>
					<PickListDropdown
						defaultTitle="科目"
						options={subjectMap || []}
						onChange={(value) => pickListChange("subject", value)}
						value={filterParams.subject}
					/>
					<PickListDropdown
						defaultTitle="版本"
						options={versionMap || []}
						onChange={(value) => pickListChange("version", value)}
						value={filterParams.version}
					/>
					<PickListDropdown
						defaultTitle="產品別"
						options={productTypeMap || []}
						onChange={(value) => pickListChange("productType", value)}
						value={filterParams.productType}
					/>
					<PickListDropdown
						defaultTitle="冊次"
						options={bookMap || []}
						onChange={(value) => pickListChange("books", value)}
						value={filterParams.books}
					/>
				</FilterBar>
			</div>
			<div className="tableBlock">
				<Table>
					<Table.Header>
						<Table.Row>
							<Table.Item>學年度</Table.Item>
							<Table.Item>學制</Table.Item>
							<Table.Item>科目</Table.Item>
							<Table.Item>版本</Table.Item>
							<Table.Item>產品別</Table.Item>
							<Table.Item flex={4}>產品名稱</Table.Item>
							<Table.Item flex={4}>冊次</Table.Item>
							<Table.Item>維護紀錄</Table.Item>
							<Table.Item>檢視更多</Table.Item>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{products &&
							sliceTableData(productsFilter(products), pageState.currentPage, pageState.limit).map((item, index) => {
								return (
									<Table.Row key={index}>
										<Table.Item>{item.year}</Table.Item>
										<Table.Item>
											<span className="eduItem">{item.eduText}</span>
										</Table.Item>
										<Table.Item>
											<span className="subjectItem">{item.subjectText}</span>
										</Table.Item>
										<Table.Item>{item.versionText}</Table.Item>
										<Table.Item>{item.typeDesc}</Table.Item>
										<Table.Item flex={4}>{item.name}</Table.Item>
										<Table.Item flex={4}>{item.bookList}</Table.Item>
										<Table.Item>{`${item.maintainer} ${format(
											new Date(item.updateTime),
											"yyyy/MM/dd HH:mm",
										)}`}</Table.Item>
										<Table.Item>
											<Button.IconButton iconName="remove_red_eye" onClick={() => detailPageHandler(item)}>
												更多
											</Button.IconButton>
										</Table.Item>
									</Table.Row>
								);
							})}
					</Table.Body>
				</Table>
			</div>
			<div className="pageBlock">
				<Pagination
					total={productsFilter(products)?.length || 0}
					defaultCurrent={1}
					current={pageState.currentPage}
					onChange={pageChange}
					defaultPageSize={pageState.limit}
				/>
			</div>
		</UiProductSearchPage>
	);
};

ProductSearchPage.propTypes = {};
