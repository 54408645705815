const InfoCategory = {
	basic: "基礎資訊",
	crossYear: "跨年度基礎資訊",
};

export const InfoList = (eduSubject) => {
	const basicMap = [
		{
			name: "課本章節總表",
			code: "1",
			url: "/newSubject/chapter",
		},
		{
			name: "知識點總表",
			code: "2",
			url: "/newSubject/knowledge",
		},
		{
			name: "認知向度總表",
			code: "3",
		},
		{
			name: "核心向度總表",
			code: "4",
		},
		{
			name: "學習內容向度總表",
			code: "5",
		},
		{
			name: "學習表現向度總表",
			code: "6",
		},
	];

	const crossYearMap = [
		{
			name: "題型總表",
			code: "1",
		},
		{
			name: "出處來源總表",
			code: "2",
		},
		{
			name: "產品代碼總表",
			code: "3",
		},
		{
			name: "冊次代碼總表",
			code: "4",
		},
		{
			name: "難易度代碼總表",
			code: "5",
		},
	];

	// 國中英語
	if (eduSubject === "JEN") {
		basicMap.push(
			{
				name: "英聽類別總表",
				code: "7",
				url: "/newSubject/englishListening",
			},
			{
				name: "英聽題本題型",
				code: "8",
				url: "/newSubject/englishListeningQuestionType",
			},
		);
	}

	return [
		{
			[InfoCategory.basic]: basicMap,
		},
		{
			[InfoCategory.crossYear]: crossYearMap,
		},
	];
};
