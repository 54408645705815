import React, { useState, useEffect, useMemo } from "react";
import {
	Button,
	Select,
	Icon,
	Table,
	Pagination,
	Modal,
	Breadcrumb,
	AuthProvider,
	Checkboxer,
	TreeSelect,
} from "common-components";
import { useHistory } from "react-router-dom";
import { getTime, format } from "date-fns";
import { useMachine } from "@xstate/react";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import { getAuthorityUserRelated, putAuthorityUserAuthority } from "api/authority";
import { getMemberUserList, putMemberStatus, putMemberProfileEdit, getMemberProfileRelated } from "api/member";
import { AUTHFUNCTIONICON, AUTHORITYOPTIONS } from "constants/index";
import { Switch, Tabs, Tooltip } from "antd";
import { sliceTableData, setSelectOptions, formatAuthority, formatAuthorityArrayToNumber } from "utils/common";
import { UiTop, UiTableTop } from "styles";
import { UiAccountAuthorityManagementPage, UiTabTitle } from "./AccountAuthorityManagementPage.style";

/**
 * 權限管理-帳戶管理
 */
const { Header, Body, Row, Item } = Table;
const eduStr = {
	E: "國小全科",
	H: "高中全科",
	J: "國中全科",
};

const breadcrumbLists = [
	{
		icon: "home",
		name: "首頁-異動紀錄",
		path: "/",
	},
	{
		icon: "manage_accounts",
		name: `權限管理-帳戶管理`,
		path: `/accountAuthorityManagement`,
	},
];
const eduFilterOptions = [
	{
		value: "國小",
		name: "國小",
	},
	{
		value: "國中",
		name: "國中",
	},
	{
		value: "高中",
		name: "高中",
	},
];

const { useAuthState } = AuthProvider;
export const AccountAuthorityManagementPage = () => {
	const authState = useAuthState();
	const { uid: userUid, identity: userIdentity } = authState.context.result || {};
	const history = useHistory();
	const [page, setPage] = useState({
		currentPage: 1,
		limit: 10,
	});
	const [modalVisible, setModalVisible] = useState(false);
	const [userProfilePayload, setUserProfilePayload] = useState({});
	const [stateCheck, setCheckState] = useState({});
	const [stateDefaultData, setDefaultDataState] = useState({
		auth: {},
		userProfile: {},
	});
	const [authoritySwitchData, setAuthoritySwitchData] = useState({});

	const [filterEdu, setFilterEdu] = useState("");

	const [stateUserList, send] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const { isSuccess, message, systemCode, data } = await getMemberUserList(event.payload);
				const { allowEdit, users } = data;
				return {
					isSuccess,
					message,
					systemCode,
					allowEdit,
					users,
				};
			},
			updateData: async (_context, event) => {
				const { isSuccess, message, systemCode } =
					event.apiType === "authEdit"
						? await putAuthorityUserAuthority(event.payload)
						: await putMemberStatus(event.payload);
				return {
					isSuccess,
					message,
					systemCode,
				};
			},
		},
	});

	const [, sendEditUser] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_content, event) => {
				const { isSuccess, message, systemCode } = await putMemberProfileEdit(event.payload);
				return {
					isSuccess,
					message,
					systemCode,
				};
			},
		},
	});

	const [stateMemberProfileRelated, sendMemberProfileRelated] = useMachine(fetchMachine, {
		services: {
			fetchData: async (content, event) => {
				const { isSuccess, message, systemCode, data } = await getMemberProfileRelated(event.uid);
				const { departmentMap, eduMap, groupMap, identityMap, subSystemMap, userProfile, groups } = data;
				return {
					isSuccess,
					message,
					systemCode,
					departmentMap: setSelectOptions(departmentMap),
					eduMap: setSelectOptions(eduMap),
					groupMap,
					subSystemMap,
					identityMap: setSelectOptions(identityMap),
					userProfile,
					userGroups: groups,
				};
			},
		},
	});

	const [stateAuthorityUserRelated, sendAuthorityUserRelated] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_, event) => {
				const { isSuccess, message, systemCode, data } = await getAuthorityUserRelated(event.uid);
				const { allowEdit = false, authorityInfo = {} } = data || {};
				return {
					isSuccess,
					message,
					systemCode,
					allowAuthorityEdit: allowEdit,
					authorityInfo: Object.keys(authorityInfo).length === 0 ? null : authorityInfo,
				};
			},
		},
	});

	const { users, allowEdit } = stateUserList.context.result || {};
	const { departmentMap, eduMap, groupMap, identityMap, subSystemMap, userProfile, userGroups } =
		stateMemberProfileRelated.context.result || {};

	const { authorityInfo } = stateAuthorityUserRelated.context.result || {};

	const authorityIdentityData = useMemo(() => {
		if (!authorityInfo) return [];
		return Object.entries(authorityInfo).map(([_, value]) => ({
			code: value.code,
			name: value.name,
			authority: value.authority,
			icon: AUTHFUNCTIONICON[value.code],
			table: Object.entries(value.table).map(([_, item]) => ({ ...item })),
		}));
	}, [authorityInfo]);

	const groupOptions = useMemo(() => {
		if (!userProfilePayload?.education || !eduMap || !groupMap) return [];
		const currentEdu = userProfilePayload.education;
		const nextData = currentEdu.map((edu) => ({
			title: eduStr[edu],
			value: edu,
			key: edu,
			children: groupMap[edu].map((item) => ({
				title: item.name,
				value: item.code,
				key: item.code,
			})),
		}));
		return nextData;
	}, [eduMap, userProfilePayload?.education]);

	// const subSystemOptions = useMemo(() => {
	// 	if (!userProfilePayload?.department || !departmentMap) return [];
	// 	return setSelectOptions(subSystemMap[userProfilePayload.department]);
	// }, [departmentMap, userProfilePayload?.sendData]);

	const isSaveBtnDisabled = useMemo(() => {
		if (Object.keys(stateDefaultData.auth).length === 0 || Object.keys(stateDefaultData.userProfile).length === 0)
			return true;
		return (
			JSON.stringify(stateCheck) === JSON.stringify(stateDefaultData.auth) &&
			JSON.stringify(userProfilePayload) === JSON.stringify(stateDefaultData.userProfile)
		);
	}, [stateCheck, userProfilePayload]);

	const filterUsersData = useMemo(() => {
		if (!users) return [];
		if (!filterEdu) return users;
		return users.filter((item) => item.edus && item.edus.includes(filterEdu));
	}, [users, filterEdu]);

	const onSwitchChange = (val, uid) => {
		const payload = {
			uid,
			status: val ? "Active" : "Suspended",
		};
		send(FetchEventType.Update, { payload });
	};
	const onAuthSwitchChange = (val, key) => {
		setAuthoritySwitchData({
			...authoritySwitchData,
			[key]: val,
		});
	};
	const pageChange = (currentPage) => {
		setPage({
			...page,
			currentPage,
		});
	};

	const onShowSizeChange = (currentPage, limit) => {
		setPage({
			currentPage,
			limit,
		});
	};
	const showEditModal = (uid) => {
		sendMemberProfileRelated(FetchEventType.Fetch, { uid });
		sendAuthorityUserRelated(FetchEventType.Fetch, { uid });
		setModalVisible(true);
	};

	const onSelectChange = (key, value) => {
		setUserProfilePayload({
			...userProfilePayload,
			[key]: value,
		});
	};
	const onAuthCheckChange = (parentKey, key, value) => {
		setCheckState({
			...stateCheck,
			[parentKey]: {
				...stateCheck[parentKey],
				[key]: formatAuthorityArrayToNumber(value),
			},
		});
	};

	const sendEditData = () => {
		let authPayload = {
			code: userProfilePayload.uid,
			defaultAuthority: Object.entries(stateCheck).map(([key, val]) => ({
				code: key,
				authority: authoritySwitchData[key],
				table: val,
			})),
		};
		send(FetchEventType.Update, { apiType: "authEdit", payload: authPayload });
		sendEditUser(FetchEventType.Fetch, { payload: { ...userProfilePayload } });
		setModalVisible(false);
	};

	const onFilterEduChange = (val) => {
		setFilterEdu(val);
	};

	useEffect(() => {
		if (userIdentity !== "Admin") {
			history.push("/");
			return;
		}
		send(FetchEventType.Fetch);
	}, []);
	useEffect(() => {
		if (!userProfile) return;
		const { uid, identity, department, education } = userProfile;
		const nextData = {
			...userProfilePayload,
			uid,
			identity,
			department,
			education,
			groups: userGroups ? userGroups.map((item) => item.uid) : [],
		};
		setUserProfilePayload(nextData);
		setDefaultDataState({
			...stateDefaultData,
			userProfile: nextData,
		});
	}, [userProfile, userGroups]);

	useEffect(() => {
		if (authorityIdentityData.length === 0 || !authorityIdentityData) return;
		let nextData = {};
		let nextAuth = {};
		authorityIdentityData.forEach((item) => {
			let childData = {};
			item.table.forEach((ele) => {
				childData = { ...childData, [ele.code]: ele.authority };
			});
			nextAuth = { ...nextAuth, [item.code]: item.authority };
			nextData = { ...nextData, [item.code]: childData };
		});
		setAuthoritySwitchData(nextAuth);
		setCheckState(nextData);
		setDefaultDataState({
			...stateDefaultData,
			auth: nextData,
		});
	}, [authorityIdentityData]);
	return (
		<UiAccountAuthorityManagementPage>
			<UiTableTop>
				<div className="breadcrumb">
					<Breadcrumb data={breadcrumbLists} />
				</div>
				<UiTop>
					<div className="select">
						<Select placeholder="請選擇學制" options={eduFilterOptions} onChange={onFilterEduChange} />
					</div>
				</UiTop>
			</UiTableTop>
			<Table>
				<Header>
					<Row>
						<Item className="tableItem">姓名</Item>
						<Item className="tableItem">學制</Item>
						<Item className="tableItem">身份</Item>
						<Item className="tableItem">群組</Item>
						<Item className="tableItem">部門</Item>
						<Item className="tableItem">帳號</Item>
						<Item className="tableItem" flex={3}>
							電子郵件
						</Item>
						<Item className="tableItem">最後登入時間</Item>
						<Item className="tableItem">狀態</Item>
						{allowEdit ? <Item className="tableItem">編輯</Item> : ""}
					</Row>
				</Header>
				<Body>
					{filterUsersData &&
						sliceTableData(filterUsersData, page.currentPage, page.limit).map((item) => {
							return (
								<Row key={item.uid}>
									<Item className="tableItem">{item.name}</Item>
									<Item className="tableItem">
										<span style={{ color: "#ceff8f" }}>
											{item.edus ? item.edus.map((ele, index) => (index === 0 ? ele : `,${ele}`)) : "未設定"}
										</span>
									</Item>
									<Item className="tableItem">{item.identity || "--"}</Item>
									<Item className="tableItem">
										{item.groups ? item.groups.map((ele, index) => (index === 0 ? ele : `,${ele}`)) : "未設定"}
									</Item>
									<Item className="tableItem">{item.department || "未設定"}</Item>
									<Item className="tableItem">{item.account}</Item>
									<Item className="tableItem" flex={3}>
										<Tooltip placement="top" title={item.email}>
											{item.email}
										</Tooltip>
									</Item>
									<Item className="tableItem">
										{item.lastLogin ? format(getTime(new Date(item.lastLogin)), "yyyy-MM-dd HH:mm:ss") : "--"}
									</Item>
									<Item className="tableItem">
										{userUid === item.uid ? (
											""
										) : (
											<div className="switchItem">
												<Switch
													disabled={!allowEdit}
													checked={item.status === "Active"}
													onChange={(checked) => {
														onSwitchChange(checked, item.uid);
													}}
												/>
											</div>
										)}
									</Item>
									{allowEdit ? (
										<Item className="tableItem">
											<Button.IconButton
												variant="blue"
												full={false}
												iconName="edit"
												onClick={() => {
													showEditModal(item.uid);
												}}>
												編輯
											</Button.IconButton>
										</Item>
									) : (
										""
									)}
								</Row>
							);
						})}
				</Body>
			</Table>

			{filterUsersData && filterUsersData.length > 10 && (
				<Pagination
					defaultCurrent={1}
					current={page.currentPage}
					onChange={pageChange}
					onShowSizeChange={onShowSizeChange}
					total={filterUsersData.length}
					defaultPageSize={page.limit}
				/>
			)}
			<Modal
				width={"80%"}
				visible={modalVisible}
				onCancel={() => {
					setModalVisible(false);
				}}
				title={
					<div className="modalTitle">
						<Icon name="edit" color="#fff" bgcolor="#40a9ff" mr={"1"} />
						<span>編輯</span>
					</div>
				}
				footer={[
					<Button
						key="back"
						variant="white"
						full={false}
						onClick={() => {
							setModalVisible(false);
						}}>
						取消
					</Button>,
					<Button key="submit" variant="green" full={false} disabled={isSaveBtnDisabled} onClick={sendEditData}>
						儲存
					</Button>,
				]}>
				<div className="editAccountContent">
					<div className="userInfo">
						{userProfile && Object.keys(userProfilePayload).length > 0 && (
							<>
								<div className="userInfoLeft">
									<div className="userAvatar">
										<img src={`/assets/roles/${userProfile.identity}.png`} alt={userProfile.identity} />
										<div className="userName">{userProfile.name}</div>
									</div>
									<div className="userInfoDetail">
										<div>
											<div className="userInfoLabel">帳號</div>｜
											<div className="userInfoValue">{userProfile.account}</div>
										</div>
										<div>
											<div className="userInfoLabel">電子郵件</div>｜
											<div className="userInfoValue">{userProfile.email || "--"}</div>
										</div>
										<div className="lastLogin">
											<div className="userInfoLabel">最後登入時間:</div>
											<div className="userInfoValue">
												{userProfile.lastLogin
													? format(getTime(new Date(userProfile.lastLogin)), "yyyy-MM-dd HH:mm:ss")
													: "--"}
											</div>
										</div>
									</div>
								</div>
								<div className="userInfoList">
									<div className="userInfoListRow">
										<div>
											<div className="userInfoLabel">身份</div>
											<div className="userInfoValue">
												<Select
													options={identityMap || []}
													value={userProfilePayload?.identity || ""}
													onChange={(val) => {
														onSelectChange("identity", val);
													}}
												/>
											</div>
										</div>
										<div>
											<div className="userInfoLabel">部門</div>
											<div className="userInfoValue">
												<Select
													placeholder="選擇部門"
													options={departmentMap || []}
													value={userProfilePayload?.department || ""}
													onChange={(val) => {
														onSelectChange("department", val);
													}}
												/>
											</div>
										</div>
									</div>

									<div className="userInfoListRow">
										<div>
											<div className="userInfoLabel">學制</div>
											<div className="userInfoValue">
												<Select
													mode="multiple"
													allowClear
													showArrow={false}
													style={{ width: "100%" }}
													placeholder="選擇學制"
													value={userProfilePayload?.education || []}
													onChange={(val) => {
														onSelectChange("education", val);
													}}
													options={eduMap || []}
												/>
											</div>
										</div>

										<div>
											<div className="userInfoValue">
												<TreeSelect
													label="群組"
													style={{ width: "100%" }}
													placeholder="選擇群組"
													onChange={(val) => {
														onSelectChange("groups", val);
													}}
													value={userProfilePayload?.groups || []}
													options={groupOptions || []}></TreeSelect>
											</div>
										</div>
									</div>
								</div>
							</>
						)}
					</div>
					<div className="userAuthority">
						<Tabs>
							<Tabs.TabPane
								tab={
									<UiTabTitle>
										<span>資源庫</span>
									</UiTabTitle>
								}
								key="1">
								<div className="userAuthorityList">
									{authorityIdentityData &&
										authorityIdentityData.length > 0 &&
										authorityIdentityData.map((item) => {
											return (
												<>
													<div className="userAuthorityDetail" key={item.code}>
														<div className="userAuthorityTitle">
															<Icon name={item.icon} />
															<div className="userAuthorityTitleText">{item.name}</div>
															<Switch
																checked={authoritySwitchData[item.code]}
																size={"small"}
																onChange={(checked) => {
																	onAuthSwitchChange(checked, item.code);
																}}
															/>
														</div>
														<div className="userAuthorityDetailList">
															{item.table.map((ele, index) => (
																<>
																	<div key={index}>
																		<Checkboxer
																			label={ele.name}
																			options={AUTHORITYOPTIONS}
																			disabled={!authoritySwitchData[item.code]}
																			onChange={(value) => {
																				onAuthCheckChange(item.code, ele.code, value);
																			}}
																			value={
																				stateCheck[item.code]
																					? Object.entries(formatAuthority(stateCheck[item.code][ele.code]))
																							.filter(([, value]) => value === 1)
																							.map(([key]) => `${key}`)
																					: []
																			}
																		/>
																	</div>
																</>
															))}
														</div>
													</div>
												</>
											);
										})}
								</div>
							</Tabs.TabPane>
						</Tabs>
					</div>
				</div>
			</Modal>
		</UiAccountAuthorityManagementPage>
	);
};

AccountAuthorityManagementPage.propTypes = {};
