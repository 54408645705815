import styled from "styled-components";

export const UiRandomIcon = styled.div`
	width: 24px;
	height: 24px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 16px;
	font-weight: 700;
	background-color: ${(props) => props.color};
`;
