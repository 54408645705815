import React, { useEffect } from "react";
import { useSetState } from "utils/useSetState";
import { setSelectOptions } from "utils/common";
import { useHistory } from "react-router-dom";
import { SubjectCard } from "components";
import useQuery from "utils/hooks/useQuery";
import { LANGUAGE_GROUP } from "constants/index";
import { Select, AuthMachine, AuthProvider, Breadcrumb } from "common-components";
import fetchMachine, { FetchEventType, FetchStates } from "machines/fetchMachine";
import { useMachine } from "@xstate/react";
import { getSelection } from "api/subject";
import { UiSubjectPage } from "./SubjectPage.style";

/**
 * 科目代碼
 */
const { state: AuthStates } = AuthMachine;
const { useAuthState } = AuthProvider;
const breadcrumbGroup = [
	{
		icon: "home",
		name: "首頁-異動紀錄",
		path: "/",
	},
	{
		icon: "domain_verification",
		name: "科目資料",
		path: "/subject",
	},
];

export const SubjectPage = () => {
	const authState = useAuthState();
	const history = useHistory();
	const query = useQuery();
	const queryEducation = query.get("education");
	const querySubject = query.get("subject");
	const [state, send] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const { data, isSuccess } = await getSelection();
				const { eduMap, eduSubject, metatypes } = data;
				return {
					isSuccess,
					eduMap: setSelectOptions(eduMap),
					eduSubject: eduSubject || [],
					metaTypes: metatypes || {},
				};
			},
		},
	});

	const [{ eduMapValue, eduSubjectValue }, setState] = useSetState({
		eduMapValue: "",
		eduSubjectValue: "",
	});

	const { eduMap, eduSubject, metaTypes } = state.context.result || {};

	const onChange = (key, value) => {
		if (key === "eduMapValue") {
			setState({
				eduSubjectValue: "",
			});
		}
		setState({
			[key]: value,
		});
	};

	useEffect(() => {
		if (!authState.matches(AuthStates.LoginDone)) return;
		send(FetchEventType.Fetch);
	}, [authState.value]);

	useEffect(() => {
		if (!eduMap || !state.matches(FetchStates.Resolved)) return;
		const eduMapValue = queryEducation || eduMap[0]?.value || "";
		const eduSubjectValue = querySubject || setSelectOptions(eduSubject[eduMapValue])[0]?.value;
		setState({
			eduMapValue,
			eduSubjectValue,
		});
	}, [state.value]);

	return (
		state.matches(FetchStates.Resolved) && (
			<UiSubjectPage>
				<Breadcrumb data={breadcrumbGroup} mb={20} />
				<div className="selectBox">
					<Select options={eduMap} value={eduMapValue} onChange={(value) => onChange("eduMapValue", value)} />
					<Select
						options={setSelectOptions(eduSubject[eduMapValue] || [])}
						value={eduSubjectValue}
						onChange={(value) => onChange("eduSubjectValue", value)}
					/>
				</div>
				<div className="container">
					{eduMapValue && eduSubjectValue ? (
						metaTypes[eduMapValue].map((metaItem, index) => {
							if (metaItem.subjects === null || metaItem.subjects.includes(eduSubjectValue)) {
								const isLanguageType = metaItem.code === "KNOWLEDGE" && LANGUAGE_GROUP.includes(eduSubjectValue);
								return (
									<SubjectCard
										key={index}
										title={isLanguageType ? "課名" : metaItem.name}
										lockInfo={
											metaItem.lockInfo.locks &&
											metaItem.lockInfo.locks.find((item) => item.eduSubject === `${eduMapValue}${eduSubjectValue}`)
										}
										image={`assets/subject/${isLanguageType ? "LESSON" : metaItem.code}.png`}
										onClick={() =>
											history.push(
												`/subject/list?edu=${eduMapValue}&subject=${eduSubjectValue}&metaType=${metaItem.code}`,
											)
										}
									/>
								);
							}
						})
					) : (
						<div>請選擇條件</div>
					)}
				</div>
			</UiSubjectPage>
		)
	);
};
