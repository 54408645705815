import styled from "styled-components";
export const UiGroupAuthorityManagementPage = styled.div`
	display: block;

	.modalTitle {
		display: flex;
		flex: 0.95;
		justify-content: space-between;

		.title {
			display: flex;
			align-items: center;
		}

		.titleBtn {
			display: flex;

			& > button {
				margin-left: 4px;
			}
		}
	}

	.modalContent {
		padding: 28px 24px;

		.modalContentHeader {
			margin-bottom: 40px;
			font-size: 20px;
			text-align: center;
		}

		.admin {
			display: flex;
			align-items: center;
			margin: 0 8px;
			padding: 4px;
			color: #1890ff;
			background: #fff;
			border: 1px solid #1890ff;
			border-radius: 2px;
			box-sizing: border-box;
		}
	}
`;
