import styled from "styled-components";
import { flexbox, space, typography } from "styled-system";
export const UiKnowledgeDetailPage = styled.div`
	display: block;
`;

export const UiListItem = styled.div`
	flex: 1;
	${flexbox}
	${typography}
	&.link {
		text-decoration: underline;
		color: #82b4ff;
		cursor: pointer;
	}
`;

export const UiKnowledgeDetailContainer = styled.div`
	display: flex;
	padding: 24px;
	background-color: #fff;
`;

export const UiKnowledgeDetailContent = styled.div`
	${flexbox}
	${space}
	display: flex;
	flex-direction: column;

	& > .title {
		padding: 8px;
		color: #fff;
		background-color: #82b4ff;
	}

	.listRow {
		border-bottom: 1px solid #f0f0f0;
		display: flex;
		padding: 8px;
	}
`;
export const UiRelatedList = styled.div`
	padding: 0 16px;
	border: 4px solid #f0f0f0;

	.listRow:last-child {
		border-bottom: none;
	}
	${space}
`;

export const UiKnowledgeList = styled.div`
	padding: 0 20px;
	min-height: 615px;
	${space}
`;

export const UiKnowledgeDetailListBox = styled.div`
	& > .listTitle {
		padding: 0 8px;
		color: #fff;
		background-color: #82b4ff;
	}
	border: 8px solid #82b4ff;
	${space}
`;

export const UiKnowledgeDetailList = styled.div`
	& > .info {
		padding: 8px 24px;

		.listRow:last-child {
			border-bottom: none;
		}
	}

	& > .recentlyInfo {
		display: flex;
		flex-direction: column;

		.listRow:last-child {
			border-bottom: none;
		}
	}
	border: 8px solid #82b4ff;
`;
