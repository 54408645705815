import { Api } from "common-components";
import { stringify } from "query-string";

//取得修改帳戶相關選項資料
export const getMemberProfileRelated = async (uid) => {
	const querystring = stringify({ uid });
	const response = await Api.get(
		`${process.env.REACT_APP_RESOURCE_PLATFORM_DOMAIN}/api/Member/Profile/Related?${querystring}`,
	);
	return response;
};
//修改帳戶資料
export const putMemberProfileEdit = async (payload) => {
	const response = await Api.put(`${process.env.REACT_APP_RESOURCE_PLATFORM_DOMAIN}/api/Member/Profile`, payload);
	return response;
};
//取得帳戶管理列表
export const getMemberUserList = async (payload) => {
	const querystring = stringify(payload);
	const response = await Api.get(
		`${process.env.REACT_APP_RESOURCE_PLATFORM_DOMAIN}/api/Member/UserList?${
			querystring ? querystring : "onlyActive=false"
		}`,
	);
	return response;
};
//更改帳戶狀態
export const putMemberStatus = async (payload) => {
	const response = await Api.put(`${process.env.REACT_APP_RESOURCE_PLATFORM_DOMAIN}/api/Member/Status`, payload);
	return response;
};
