"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiBreadcrumb = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _styledSystem = require("styled-system");

var _templateObject;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var UiBreadcrumb = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\t", "\n\tdisplay: block;\n\n\t.ant-breadcrumb {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\n\t\tspan {\n\t\t\tdisplay: flex;\n\n\t\t\t.ant-breadcrumb-link {\n\t\t\t\tdisplay: flex;\n\t\t\t\talign-items: center;\n\n\t\t\t\t& > * {\n\t\t\t\t\tcolor: #98989e;\n\t\t\t\t\ttransition: 0.5s;\n\n\t\t\t\t\t&:hover {\n\t\t\t\t\t\tcolor: #fff;\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t.ant-breadcrumb-separator {\n\t\t\t\tcolor: #fff;\n\t\t\t}\n\t\t}\n\n\t\t> span:last-child {\n\t\t\t.ant-breadcrumb-link {\n\t\t\t\t& > * {\n\t\t\t\t\tfont-size: 20px;\n\t\t\t\t\tcolor: #fff;\n\t\t\t\t}\n\n\t\t\t\ti {\n\t\t\t\t\tfont-size: 20px;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n\n\t.ant-breadcrumb-link {\n\t\tdisplay: flex !important;\n\n\t\ta {\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t}\n\n\t\ti {\n\t\t\tfont-size: 18px;\n\t\t}\n\t}\n"])), _styledSystem.space);

exports.UiBreadcrumb = UiBreadcrumb;