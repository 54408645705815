import React from "react";
import PropTypes from "prop-types";
import { Icon } from "common-components";
import { getTime, format } from "date-fns";
import { UiSubjectCard } from "./SubjectCard.style";
/**
 * 科目代碼卡牌組件
 */
const noop = () => {};
export const SubjectCard = ({ title, image, lockInfo, onClick = noop }) => {
	const ImgError = (source) => {
		source.src = ``;
		source.onError = "";
		return true;
	};
	return (
		<UiSubjectCard onClick={onClick} image={image}>
			<div className="img">
				<img src={image} alt={title} onError={ImgError} />
			</div>
			<div className="title">
				<div className="titleText">{title}</div>

				<>
					{lockInfo?.isLock && (
						<div className="lockInfo">
							<Icon name="lock" />
							{format(getTime(new Date(lockInfo?.updateTime)), "yyyy-MM-dd HH:mm")}
						</div>
					)}
				</>
			</div>
		</UiSubjectCard>
	);
};

SubjectCard.propTypes = {
	title: PropTypes.string,
	image: PropTypes.string,
	onClick: PropTypes.func,
	lockInfo: PropTypes.shape({
		code: PropTypes.string,
		isLock: PropTypes.bool,
		maintainer: PropTypes.string,
		updateTime: PropTypes.string,
	}),
};
