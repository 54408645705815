import React from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Icon } from "common-components";
import { AutoComplete } from "antd";
import { UiDropList, UiDropContainer, UiDropItem } from "./DropList.style";

/**
 * 拖曳SelectList
 */

const noop = () => {};
export const DropList = ({
	label = "向度*",
	list = [],
	options = [],
	onChange = noop,
	onAdd = noop,
	onDelete = noop,
	message = "",
	onSelectChange = noop,
}) => {
	return (
		<UiDropList>
			<div className="title">{label}</div>
			<DragDropContext
				onDragEnd={(result) => {
					const { source, destination } = result;
					if (!destination) return;
					let arr = Array.from(list);
					const [remove] = arr.splice(source.index, 1);
					arr.splice(destination.index, 0, remove);
					onChange(arr);
				}}>
				<Droppable droppableId="dropList">
					{(provided) => (
						<UiDropContainer ref={provided.innerRef} {...provided.droppableProps}>
							{list.map((li, i, listArr) => (
								<Draggable draggableId={li.id} index={i} key={li.id}>
									{(p) => (
										<>
											<UiDropItem {...p.draggableProps} {...p.dragHandleProps} key={li.id} index={li.i}>
												<div className="drop" ref={p.innerRef}>
													<Icon name="menu" />
												</div>
												<AutoComplete
													style={{
														minWidth: 300,
													}}
													options={options}
													value={li.value}
													onChange={(value, option) => onSelectChange(option, li.id)}
													placeholder="搜尋向度"
													filterOption={(inputValue, option) =>
														option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
													}
													allowClear={true}
												/>
												<div className="icon" onClick={i === listArr.length - 1 ? onAdd : () => onDelete(li.id)}>
													{i === listArr.length - 1 ? <Icon name="add" /> : <Icon name="delete" />}
												</div>
											</UiDropItem>
											{p.placeholder}
										</>
									)}
								</Draggable>
							))}
						</UiDropContainer>
					)}
				</Droppable>
			</DragDropContext>
			{message && (
				<div className="message">
					<Icon name="cancel" color="#f00" />
					{message}
				</div>
			)}
		</UiDropList>
	);
};

DropList.propTypes = {
	label: PropTypes.string,
	list: PropTypes.array,
	options: PropTypes.array,
	onChange: PropTypes.func,
	onAdd: PropTypes.func,
	onDelete: PropTypes.func,
	onSelectChange: PropTypes.func,
	message: PropTypes.string,
};
