import React, { useEffect, useMemo, useState, useRef } from "react";
import {
	Button,
	Input,
	Table,
	Pagination,
	Modal,
	Breadcrumb,
	AuthMachine,
	AuthProvider,
	Upload,
} from "common-components";
import { useMachine } from "@xstate/react";
import {
	createEnglishListeningQuestionType,
	updateEnglishListeningQuestionTypeList,
	getQuestionTypeRelated,
	getSingleQuestionTypeRelated,
	importEnglishListeningQuestionType,
} from "api/definition";
import { fetchMachine, FetchEventType, FetchStates } from "machines/fetchMachine";
import { useHistory } from "react-router-dom";
import { createValidator, required, checkDuplicateName } from "utils/validation";
import { sliceTableData, getTableAuthority } from "utils/common";
import { POPUP_TITLE, COMMON_TEXT, INIT_AUTHORITY, EDUCATIONAL_LIST, subjectsObj } from "constants/index";
import { UiTop, UiActionBox, UiTableTop } from "styles";
import useQuery from "utils/hooks/useQuery";
import { useUpload } from "utils/hooks/useUpload";
import { HeadphonesSolid } from "@onedesign/icon";

import { UiQuestionTypePage, UiQuestionTypeDetail } from "./EnglishListeningQuestionTypePage.style";
import { exportEnglishListeningQuestionType, getEnglishListeningQuestionTypeTableData } from "api/definition/index";
/**
 * 題目類型總表頁面
 */
const { Header, Body, Row, Item } = Table;

const { state: AuthStates } = AuthMachine;
const { useAuthState } = AuthProvider;

const EnglishListeningQuestionTypePage = () => {
	const authState = useAuthState();
	const history = useHistory();
	const query = useQuery();
	const edu = query.get("edu");
	const subject = query.get("subject");
	const { executeUpload } = useUpload();
	const [state, setState] = useState({
		mode: COMMON_TEXT.CREATE,
		searchValue: "",
		duplicatePayload: {},
	});
	const [pageState, setPageState] = useState({
		currentPage: 1,
		limit: 10,
	});
	const [sendParamsState, setSendParamsState] = useState({
		createPublicCodeParams: {},
	});

	const [modalState, setModalState] = useState({
		isDuplicateVisible: false,
		isVisible: false,
		questionTypeModalIsVisible: false,
	});

	const [authorityState, setAuthority] = useState(INIT_AUTHORITY);

	const tableRef = useRef(null);

	const [files, setFiles] = useState([]);

	const [uploadPopup, setUploadPopup] = useState({
		visible: false,
		loading: false,
	});

	const breadcrumbGroup = [
		{
			icon: "home",
			name: "首頁",
			path: "/",
		},
		{
			icon: "format_list_bulleted",
			name: "新科目資料",
			path: "/newSubject",
		},
		{
			name: `${EDUCATIONAL_LIST[edu]} - ${subjectsObj[subject]}`,
			path: `/newSubject/info?edu=${edu}&subject=${subject}`,
		},
		{
			name: "英聽題本題型",
			path: `/newSubject/englishListeningQuestionType?edu=${edu}&subject=${subject}`,
		},
	];

	const rulesModel = {
		[COMMON_TEXT.CREATE]: {
			orderby: [{ message: "排序欄位不得空白", validate: required }],
			name: [{ message: "名稱欄位不得空白", validate: required }],
		},
		[COMMON_TEXT.EDIT]: {
			orderby: [{ message: "排序欄位不得空白", validate: required }],
			name: [{ message: "名稱欄位不得空白", validate: required }],
		},
	};

	const submitDisabled = useMemo(() => {
		const allSubmitDataLength = state.mode ? Object.keys(rulesModel[state.mode]).length : -1;
		if (Object.keys(sendParamsState.createPublicCodeParams).length >= allSubmitDataLength) {
			for (const [, value] of Object.entries(sendParamsState.createPublicCodeParams)) {
				if (value.message) {
					return true;
				}
			}
			return false;
		}
		return true;
	}, [state.mode, sendParamsState.createPublicCodeParams]);

	const [stateQuestionType, send] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const res = await getEnglishListeningQuestionTypeTableData();
				const { data, error, message } = res;
				return {
					isSuccess: error === 200,
					message,
					data,
				};
			},
			createData: async (_context, event) => {
				const { error, message } = await createEnglishListeningQuestionType(event.payload);
				return {
					isSuccess: error === 200,
					message,
				};
			},
			updateData: async (_context, event) => {
				const { error } = await updateEnglishListeningQuestionTypeList(event.payload);
				return {
					isSuccess: error === 200,
				};
			},
		},
	});

	const { data } = stateQuestionType.context.result || [];

	const filterData = useMemo(() => {
		return data?.enlQuestionTypeDetail?.filter((item) => item.name.indexOf(state.searchValue) >= 0) || [];
	}, [data, state.searchValue]);

	const [stateRelated, sendRelated] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const { data, isSuccess, message } = await getQuestionTypeRelated();
				return {
					isSuccess,
					message,
					questionTypes: data?.attributes || [],
					questionTypeOptions: data?.attrData || {},
					questionTypeExistName: data?.existName || [],
				};
			},
		},
	});

	const { questionTypeOptions, questionTypeExistName } = stateRelated.context.result || {};

	const [singleStateRelated, singleSendRelated] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const res = await getSingleQuestionTypeRelated(event.code);
				return {
					editExistName: res.data.existName || [],
				};
			},
		},
	});

	const { editExistName } = singleStateRelated.context.result || {};

	const pageChange = (currentPage, pageSize) => {
		setPageState({
			...pageState,
			currentPage,
			limit: pageSize,
		});
		tableRef.current.scrollIntoView();
	};

	const onSearchHandle = (event) => {
		const value = event.target ? event.target.value : "";
		setState({
			...state,
			searchValue: value.toString(),
		});
		setPageState({
			...pageState,
			currentPage: 1,
		});
	};

	const onFormPopupToggle = (mode) => {
		setModalState({
			...modalState,
			isDuplicateVisible: false,
			isVisible: !modalState.isVisible,
		});
		setState({
			...state,
			mode,
		});
		setSendParamsState({
			createPublicCodeParams: {},
		});
	};

	const onFormChangeHandle = (event, key) => {
		const value = event.target ? event.target.value : "";
		setSendParamsState({
			createPublicCodeParams: {
				...sendParamsState.createPublicCodeParams,
				[key]: {
					value,
					message: "",
				},
			},
		});
	};

	const onUpdatePopup = (itemData) => {
		onFormPopupToggle(COMMON_TEXT.EDIT);
		const { code, name, orderby, uid } = itemData;
		singleSendRelated(FetchEventType.Fetch, { code });
		setSendParamsState({
			createPublicCodeParams: {
				uid: {
					value: uid,
				},
				name: {
					value: name,
				},
				orderby: {
					value: orderby,
				},
			},
		});
	};

	const setFormFetch = (payload) => {
		state.mode === COMMON_TEXT.CREATE
			? send(FetchEventType.Create, { payload })
			: send(FetchEventType.Update, { payload });
	};

	const setDuplicateFormFetch = () => {
		const nextPayload = {
			isIgnoreName: true,
			...state.duplicatePayload,
		};
		state.mode === COMMON_TEXT.CREATE
			? send(FetchEventType.Create, { payload: nextPayload })
			: send(FetchEventType.Update, { payload: nextPayload });
		setModalState({
			...modalState,
			isDuplicateVisible: false,
		});
	};

	const onFormSubmitHandle = async () => {
		let rules = {};
		const inputValues = {
			name: "",
			groupCode: "",
			printCode: "",
		};
		Object.entries(sendParamsState.createPublicCodeParams).forEach(([key, value]) => {
			inputValues[key] = value.value;
		});

		rules = rulesModel[state.mode];

		if (state.mode !== COMMON_TEXT.CREATE) {
			delete inputValues.groupCode;
		}
		const [isPass, errorGroup] = createValidator(inputValues, rules);
		setSendParamsState({
			createPublicCodeParams: {
				...sendParamsState.createPublicCodeParams,
				...errorGroup,
			},
		});
		if (isPass) return;
		const payload = {
			...inputValues,
		};
		if (
			!checkDuplicateName(inputValues.name, state.mode === COMMON_TEXT.CREATE ? questionTypeExistName : editExistName)
		) {
			setModalState({
				...modalState,
				isDuplicateVisible: true,
			});
			setState({
				...state,
				duplicatePayload: payload,
			});
			return;
		}
		setFormFetch(payload);
	};

	const exportHandle = async () => {
		const { data } = await exportEnglishListeningQuestionType();
		if (data?.url) {
			window.open(data.url);
		}
	};

	const questionTypeModalHandler = () => {
		setModalState({
			...modalState,
			questionTypeModalIsVisible: !modalState.questionTypeModalIsVisible,
		});
	};

	const renderQuestionTypeDetail = () => {
		return (
			<>
				<UiQuestionTypeDetail>
					<div className="questionTypeOptionsContent">
						<div className="questionTypeOptionsCode">
							<div>
								<div>代碼</div>
								{questionTypeOptions &&
									questionTypeOptions["QUES_GROUP"]?.map((item, index) => <div key={index}>{item.code}</div>)}
							</div>
						</div>
						<div className="questionTypeOptionsName">
							<div>
								<div>作答方式</div>
								{questionTypeOptions &&
									questionTypeOptions["QUES_GROUP"]?.map((item, index) => <div key={index}>{item.name}</div>)}
							</div>
						</div>
					</div>
					<div className="questionTypeOptionsContent quesPrint">
						<div className="questionTypeOptionsCode">
							<div>
								<div>代碼</div>
								{questionTypeOptions &&
									questionTypeOptions["QUES_PRINT"]?.map((item, index) => <div key={index}>{item.code}</div>)}
							</div>
						</div>
						<div className="questionTypeOptionsName">
							<div>
								<div>列印方式</div>
								{questionTypeOptions &&
									questionTypeOptions["QUES_PRINT"]?.map((item, index) => <div key={index}>{item.name}</div>)}
							</div>
						</div>
					</div>
				</UiQuestionTypeDetail>
			</>
		);
	};

	const toggleUploadPopupVisible = () => {
		setUploadPopup({
			...uploadPopup,
			visible: !uploadPopup.visible,
		});
	};

	const changeFileList = (value) => {
		setFiles(value);
	};

	const submitFiles = async () => {
		if (files.length === 0) return;
		setUploadPopup({
			...uploadPopup,
			loading: true,
		});
		await executeUpload(files, "basic/questionType", importEnglishListeningQuestionType);
		setUploadPopup({
			visible: false,
			loading: false,
		});
		setFiles([]);
		send(FetchEventType.Fetch);
		sendRelated(FetchEventType.Fetch);
	};

	useEffect(() => {
		if (!authState.matches(AuthStates.LoginDone)) return;

		const { authority, tableAuthority } = getTableAuthority(
			"QUESTYPE",
			authState.context.authority.function.Definition,
		);
		setAuthority({
			...authorityState,
			authority,
			tableAuthority,
		});
		send(FetchEventType.Fetch);
		sendRelated(FetchEventType.Fetch);
	}, [authState.value]);

	useEffect(() => {
		if (!stateQuestionType.matches(FetchStates.Resolved)) return;
		setModalState({
			...modalState,
			isDuplicateVisible: false,
			isVisible: false,
		});
	}, [stateQuestionType.value]);

	return (
		<UiQuestionTypePage ref={tableRef}>
			<UiTableTop>
				<UiActionBox style={{ marginBottom: "20px" }}>
					<Button.IconButton
						variant="white"
						full={false}
						iconName="keyboard_backspace"
						onClick={() => {
							history.push(`/newSubject/info?edu=${edu}&subject=${subject}`);
						}}>
						回上層
					</Button.IconButton>
					{edu && subject && <Breadcrumb data={breadcrumbGroup} />}
				</UiActionBox>
				<UiTop>
					<UiActionBox style={{ marginLeft: "auto" }}>
						{authorityState.tableAuthority.Import && (
							<Button.IconButton variant="blue" full={false} iconName="cloud_upload" onClick={toggleUploadPopupVisible}>
								匯入
							</Button.IconButton>
						)}
						{authorityState.tableAuthority.Export && (
							<Button.IconButton variant="blue" full={false} iconName="save_alt" onClick={exportHandle}>
								匯出
							</Button.IconButton>
						)}
						{authorityState.tableAuthority.Edit && (
							<Button.IconButton
								variant="blue"
								full={false}
								iconName="add"
								onClick={() => onFormPopupToggle(COMMON_TEXT.CREATE)}>
								新增
							</Button.IconButton>
						)}
						<div className="search">
							<Input
								placeholder="搜尋..."
								type={"search"}
								value={state.searchValue}
								onChange={(e) => onSearchHandle(e)}
							/>
						</div>
					</UiActionBox>
				</UiTop>
			</UiTableTop>
			<Table margin={20}>
				<Header>
					<Row>
						<Item>排序</Item>
						<Item>代碼</Item>
						<Item flex={5}>名稱</Item>
						<Item>編輯</Item>
					</Row>
				</Header>
				<Body>
					{filterData &&
						sliceTableData(filterData, pageState.currentPage, pageState.limit).map((item) => {
							return (
								<Row key={item.code}>
									<Item>{item.orderby}</Item>
									<Item>{item.code}</Item>
									<Item flex={5}>
										{item.name}
										<HeadphonesSolid style={{ color: "#ffb800", marginLeft: "8px" }} />
									</Item>
									<Item>
										{authorityState.tableAuthority.Edit ? (
											<Button.IconButton
												variant="orange"
												full={false}
												iconName="edit"
												onClick={() => onUpdatePopup(item)}>
												編輯
											</Button.IconButton>
										) : (
											""
										)}
									</Item>
								</Row>
							);
						})}
				</Body>
			</Table>
			{filterData && filterData.length > 10 && (
				<Pagination
					defaultCurrent={1}
					current={pageState.currentPage}
					onChange={pageChange}
					total={filterData.length}
					defaultPageSize={pageState.limit}
				/>
			)}

			<Modal
				title={
					<>
						<i className="material-icons">add_box</i>
						{POPUP_TITLE[state.mode]}
					</>
				}
				visible={modalState.isVisible}
				confirmLoading={false}
				okText="確定"
				cancelText="取消"
				okButtonProps={{ ...Modal.okButtonProps, disabled: submitDisabled }}
				onOk={onFormSubmitHandle}
				onCancel={() => onFormPopupToggle("")}>
				<Input
					label="名稱"
					placeholder="請輸入名稱*"
					type="textArea"
					onChange={(e) => onFormChangeHandle(e, "name")}
					value={sendParamsState?.createPublicCodeParams["name"]?.value || ""}
					message={sendParamsState?.createPublicCodeParams["name"]?.message || ""}
				/>
				<Input
					label="題本輸出排序"
					placeholder="請輸入排序*"
					type="textArea"
					onChange={(e) => onFormChangeHandle(e, "orderby")}
					value={sendParamsState?.createPublicCodeParams["orderby"]?.value || ""}
					message={sendParamsState?.createPublicCodeParams["orderby"]?.message || ""}
				/>
			</Modal>
			<Modal.WarningModal
				visible={modalState.isDuplicateVisible}
				onOk={setDuplicateFormFetch}
				onCancel={() => {
					setModalState({
						...modalState,
						isDuplicateVisible: false,
					});
				}}>
				資料：{sendParamsState.createPublicCodeParams["name"]?.value || ""} 已存在, 請確認輸入內容。
			</Modal.WarningModal>
			<Modal
				title={
					<>
						<i className="material-icons">visibility</i>題目屬性
					</>
				}
				visible={modalState.questionTypeModalIsVisible}
				onCancel={questionTypeModalHandler}
				footer={null}>
				{renderQuestionTypeDetail()}
			</Modal>
			<Modal
				confirmLoading={uploadPopup.loading}
				visible={uploadPopup.visible}
				onCancel={toggleUploadPopupVisible}
				okText={uploadPopup.loading ? "上傳中..." : "確定"}
				onOk={submitFiles}
				title="上傳檔案">
				<Upload
					fileList={files}
					onChange={changeFileList}
					prompt={
						<>
							僅限上傳一個Microsoft Excel 檔<br />
							(副檔名 : .xls/.xlsx)
						</>
					}
					acceptFileType=".xls,.xlsx"
				/>
			</Modal>
		</UiQuestionTypePage>
	);
};

export default EnglishListeningQuestionTypePage;
