export const ROLES = {
	Editor: "編輯",
	Guest: "設排",
	ManagingEditor: "編輯主管",
	Admin: "系統管理員",
};

export const COMMON_TEXT = {
	CREATE: "create",
	EDIT: "edit",
	KNOWLEDGE: "KNOWLEDGE",
	SUB_TOPIC: "SUB_TOPIC",
	TOPIC: "TOPIC",
	NO_HIERARCHY: "NO_HIERARCHY",
	OTHER: "OTHER",
};

export const POPUP_TITLE = {
	create: "新增",
	edit: "編輯",
};

export const TOPIC = {
	"{0}-{1}": "舊綱",
	"{0}{1}": "新綱",
};

// 語文科
export const LANGUAGE_GROUP = ["PC", "EN", "EL", "CH"];

export const EDUCATIONAL = [
	{
		name: "國小",
		value: "E",
	},
	{
		name: "國中",
		value: "J",
	},
	{
		name: "高中",
		value: "H",
	},
];

export const EDUCATIONAL_LIST = {
	E: "國小",
	J: "國中",
	H: "高中",
};
export const AUTHORITY_VALUE = {
	View: 1,
	Edit: 2,
	Lock: 4,
	Import: 8,
	Export: 16,
};
export const AUTHORITY = {
	View: 0,
	Edit: 0,
	Lock: 0,
	Import: 0,
	Export: 0,
};

export const INIT_AUTHORITY = {
	authority: 0,
	tableAuthority: {
		View: 0,
		Edit: 0,
		Lock: 0,
		Import: 0,
		Export: 0,
	},
};

export const AUTHFUNCTIONICON = {
	Definition: "grid_on",
	EduSubject: "domain_verification",
	YearBook: "list_alt",
};

export const AUTHORITYOPTIONS = [
	{
		code: "View",
		name: "查看",
	},
	{
		code: "Edit",
		name: "編輯、新增",
	},
	{
		code: "Export",
		name: "匯出",
	},
	{
		code: "Import",
		name: "匯入",
	},
	{
		code: "Lock",
		name: "鎖定",
	},
];

export const subjectsObj = {
	CH: "國語",
	CN: "華語",
	EN: "英語",
	MA: "數學",
	NA: "自然與生活科技",
	SO: "社會",
	LI: "生活",
	PE: "健康與體育",
	PC: "國文",
	BI: "生物",
	PY: "理化",
	EA: "地球科學",
	GE: "地理",
	HI: "歷史",
	CT: "公民",
	CO: "綜合活動",
	TC: "科技",
	EW: "英文",
	PH: "物理",
	CE: "化學",
	CS: "公民與社會",
	IT: "資訊科技",
	LT: "生活科技",
};

export const naniExcelHeader = [
	"編號",
	"學制",
	"科目",
	"章節",
	"重點",
	"重點名稱",
	"適用年度",
	"對應知識概念",
	"對應南一章節重點",
];

export const versionList = {
	N: "南一",
	H: "翰林",
	K: "康軒",
};

export const versionMap = {
	N: "南",
	H: "翰",
	K: "康",
};
