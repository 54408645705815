import styled from "styled-components";
/**
 @Table搜尋列表
 */

export const UiTableTop = styled.div`
	.breadcrumb {
		margin-bottom: 10px !important;

		span {
			display: flex;
			align-items: center;
			color: #fff;

			a {
				color: #fff;
			}

			&:last-child {
				font-size: 18px;
			}
		}
	}
`;

export const UiTop = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin-bottom: 20px;
	flex-wrap: wrap;
`;

export const UiActionBox = styled.div`
	display: flex;
	align-items: center;

	& > * + * {
		margin-left: 5px;
	}
`;
export const UiLockModal = styled.div`
	.lockModalIcon {
		display: flex;
		justify-content: center;
		padding-top: 20px;

		& > div > i {
			font-size: 76px;
		}
	}

	.lockModalContent {
		font-size: 24px;
		text-align: center;
		color: #000;

		.lockModalHint {
			font-size: 14px;
			color: rgba(0, 0, 0, 0.45);
		}
	}
`;
