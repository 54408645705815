"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiIconPop = exports.UiIcon = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _styledSystem = require("styled-system");

var _templateObject, _templateObject2;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var UiIcon = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tdisplay: inline-flex;\n\ttransform: ", ";\n\tcolor: ", ";\n\tbackground-color: ", ";\n\tborder-radius: 4px;\n\tcursor: ", ";\n\t", "\n"])), function (_ref) {
  var rotate = _ref.rotate;
  return "rotate(".concat(rotate, "deg) ");
}, function (_ref2) {
  var color = _ref2.color,
      focus = _ref2.focus,
      focuscolor = _ref2.focuscolor;
  return (focus ? focuscolor : color) || "currentColor";
}, function (_ref3) {
  var bgcolor = _ref3.bgcolor;
  return bgcolor || "unset";
}, function (_ref4) {
  var disabled = _ref4.disabled,
      clickable = _ref4.clickable;
  return disabled ? "not-allowed" : clickable ? "pointer" : "auto";
}, _styledSystem.space);

exports.UiIcon = UiIcon;

var UiIconPop = _styledComponents.default.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\tdisplay: inline-flex;\n\n\t.ant-popover-message {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\n\t\t.ant-popover-message-title {\n\t\t\tpadding-left: 0;\n\t\t}\n\t}\n"])));

exports.UiIconPop = UiIconPop;