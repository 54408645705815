"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DATEFORMAT = exports.EDUCATIONAL = exports.ROLES = exports.COOKIE_LOGIN_TOKEN_NAME = void 0;
var COOKIE_LOGIN_TOKEN_NAME = "nani_oneclass_resource_platform_token";
exports.COOKIE_LOGIN_TOKEN_NAME = COOKIE_LOGIN_TOKEN_NAME;
var ROLES = {
  Editor: "編輯",
  Guest: "訪客"
};
exports.ROLES = ROLES;
var EDUCATIONAL = [{
  name: "國小",
  value: "E"
}, {
  name: "國中",
  value: "J"
}, {
  name: "高中",
  value: "H"
}];
exports.EDUCATIONAL = EDUCATIONAL;
var DATEFORMAT = "YYYY-MM-DD";
exports.DATEFORMAT = DATEFORMAT;