import styled from "styled-components";

export const UiSubjectListPage = styled.div`
	.actionBox {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
		min-height: 32px;
		flex-wrap: wrap;

		.left {
			display: flex;

			& > div {
				margin-right: 10px;
				width: 100%;
				min-width: 180px;
			}

			& > button {
				margin-right: 10px;
			}
		}

		.right {
			display: flex;

			& > button {
				margin-right: 10px;
				width: max-content;
			}
		}
	}

	.miniSelect {
		> div {
			min-width: 80px;
			background-color: #626262;
			border-color: #626262;
		}
	}
`;

export const UiPopupShowData = styled.div`
	margin: 10px 0;

	span {
		color: #1890ff;
	}
`;

export const UiModalButtons = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
`;
