import styled from "styled-components";
import { background, space } from "styled-system";

export const UiTag = styled.div`
	${background}
	${space}
	display: flex;
	align-items: center;
	padding: 3px 5px;
	min-width: auto !important;
	font-size: 14px;
	white-space: nowrap;
	border-radius: 3px;
	opacity: 0.85;
	transition: 0.35s;
	cursor: pointer;

	& > i {
		font-size: 14px;
	}

	&.blue {
		color: #2f54eb;
		background-color: #f0f5ff;
		border: 2px solid #adc6ff;
	}

	&.red {
		color: #f5222d;
		background-color: #fff1f0;
		border: 2px solid #ffa39e;
	}

	&.primary {
		color: #2f54eb;
		background: #f0f5ff;
		border: 2px solid #adc6ff;
	}

	&.secondary {
		color: #52c41a;
		background: #f6ffed;
		border: 2px solid #b7eb8f;
	}

	&.tertiary {
		color: #f5222d;
		background: #fff1f0;
		border: 2px solid #ffa39e;
	}

	&:hover {
		opacity: 1;
	}
`;
