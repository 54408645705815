import styled from "styled-components";
import { Modal, Drawer } from "antd";

export const UiNewSubjectChapterModal = styled(Modal)`
	.modalContent {
		display: flex;
		flex-direction: column;
		row-gap: 10px;
	}
`;

export const CustomDrawerContent = styled(Drawer)`
	.ant-timeline-item-tail {
		border-left: 1px solid #1890ff;
	}
`;

export const UiCustomDrawer = styled.div`
	cursor: pointer;

	.container {
		display: flex;

		:hover > .buttonGroup {
			display: flex;
			gap: 8px;
		}
	}

	.title {
		font-size: 16px;
	}
	.description {
		font-size: 14px;
		color: ${(props) => (props.isNow ? "#000000D9" : "#00000073")};
	}

	.buttonGroup {
		display: none;
		color: red;

		:has(.ant-select-open) {
			display: flex;
			gap: 8px;
		}
	}
`;
