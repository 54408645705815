import styled from "styled-components";

export const UiAccountAuthorityManagementPage = styled.div`
	display: block;

	.eduSubject {
		color: "#f00";
	}

	.switchItem {
		& > button {
			background-color: #888;

			&.ant-switch-checked {
				background-color: #1890ff;
			}
		}
	}

	.ant-modal {
		width: 80vw;

		.modalTitle {
			display: flex;
			align-items: center;
		}

		.editAccountContent {
			display: flex;
			flex-direction: column;

			.userInfo {
				display: flex;
				justify-content: space-between;

				.userInfoLeft {
					padding: 8px;
					flex: 0.4;

					.userAvatar {
						display: flex;
						align-items: center;

						& > img {
							width: 45%;
						}

						& > .userName {
							margin: 0 auto;
							font-size: 20px;
						}
					}

					.userInfoDetail {
						margin-top: 12px;

						& > div {
							display: flex;
							margin: 4px 0 0;

							&.lastLogin {
								margin-top: 12px;
								color: #a1a4b1;
							}
						}
					}
				}

				.userInfoList {
					/* display: flex; */
					flex: 1;

					& > .userInfoListRow {
						display: flex;
						margin: 12px 0;

						& > div {
							flex: 1;

							&:last-child {
								margin-left: 8px;
							}
						}
					}
				}
			}

			.userAuthority {
				.userAuthorityList {
					display: flex;

					.userAuthorityDetail {
						flex: 1;
						margin-right: 8px;

						&:nth-child(4n) {
							margin-right: 0;
						}

						.userAuthorityTitle {
							display: flex;
							align-items: center;
							padding: 24px;
							border: 1px solid #f0f0f0;

							& * {
								margin-right: 4px;
								font-size: 16px;
							}
						}

						.userAuthorityDetailList {
							padding: 24px;
							border: 1px solid #f0f0f0;
							border-top: none;
						}
					}
				}
			}
		}
	}

	.tableItem {
		flex: 1 0 140px;
	}
`;

export const UiTabTitle = styled.div`
	display: flex;
	align-items: center;
	height: 100%;

	> span {
		padding: 0 12px;
	}
`;
