import React, { useEffect, useMemo, useState } from "react";
// import PropTypes from 'prop-types';
import {
	Button,
	Select,
	Input,
	Table,
	Pagination,
	Modal,
	Breadcrumb,
	AuthMachine,
	AuthProvider,
	Upload,
} from "common-components";
import { useSetState } from "utils/useSetState";
import { useMachine } from "@xstate/react";
import { getBasicTableData, createBasicCode, editBasicCode, importBasic } from "api/definition";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getTime } from "date-fns";
import { createValidator, required, checkDuplicateName } from "utils/validation";
import { setSelectOptions, getTableAuthority, exportFile } from "utils/common";
import { POPUP_TITLE, COMMON_TEXT, INIT_AUTHORITY } from "constants/index";
import { UiTop, UiActionBox, UiTableTop } from "styles";
import useQuery from "utils/hooks/useQuery";
import { useUpload } from "utils/hooks/useUpload";
import classnames from "classnames";

import { UiBasicPage } from "./BasicPage.style";

/**
 * 共用代碼頁面
 */
const { state: AuthStates } = AuthMachine;
const { useAuthState } = AuthProvider;

const { Header, Body, Row, Item } = Table;
export const BasicPage = () => {
	const authState = useAuthState();
	const history = useHistory();
	const query = useQuery();
	const isPublicCode = useRouteMatch({ path: "/publicCode" }) ? true : false;
	const { executeUpload } = useUpload();
	const [
		{
			type,
			mode,
			listData,
			total,
			options,
			searchValue,
			currentPage,
			limit,
			isDuplicateVisible,
			isVisible,
			createPublicCodeParams,
			existList,
			duplicatePayload,
			targetName,
		},
		setState,
	] = useSetState({
		type: query.get("type") || "year",
		mode: COMMON_TEXT.CREATE,
		listData: null,
		total: 0,
		options: null,
		existList: null,
		searchValue: "",
		currentPage: 1,
		limit: 10,
		isDuplicateVisible: false,
		isVisible: false,
		createPublicCodeParams: {},
		duplicatePayload: {},
		targetName: "",
	});

	const [files, setFiles] = useState([]);

	const [uploadPopup, setUploadPopup] = useState({
		visible: false,
		loading: false,
	});

	const [authorityState, setAuthority] = useState(INIT_AUTHORITY);

	const rulesModel = {
		name: [{ message: "名稱欄位不得空白", validate: required }],
		code: [
			{ message: "代碼欄位不得空白", validate: required },
			{
				message: "該代碼已被其他資料使用，請輸入其他名稱後繼續新增",
				validate: (value) => (mode === COMMON_TEXT.CREATE ? checkDuplicateName(value, existList.code) : true),
			},
		],
	};

	const submitDisabled = useMemo(() => {
		const allSubmitDataLength = Object.keys(rulesModel).length;
		if (Object.keys(createPublicCodeParams).length >= allSubmitDataLength) {
			for (const [, value] of Object.entries(createPublicCodeParams)) {
				if (value.message) {
					return true;
				}
			}
			return false;
		}
		return true;
	});
	const breadcrumbLists = useMemo(() => {
		const tableName = (options && options.filter((item) => item.value === type.toUpperCase())[0]?.name) || "";
		const result = [
			{
				icon: "home",
				name: "首頁-異動紀錄",
				path: "/",
			},
			{
				icon: "grid_on",
				name: `共用代碼-${tableName}總表`,
				path: `/publicCode?type=${type}`,
			},
		];
		return result;
	});

	const showData = useMemo(() => {
		const result = [];
		if (listData) {
			for (let i = (currentPage - 1) * limit; i < currentPage * limit; i++) {
				if (!listData[i]) break;
				result.push(listData[i]);
			}
		}

		return result;
	});

	const [stateSelection, send] = useMachine(fetchMachine, {
		services: {
			fetchData: (_context, event) => getBasicTableData(),
			createData: (_context, event) => createBasicCode(event.payload),
			updateData: (_context, event) => editBasicCode(event.payload),
		},
	});

	const pageChange = (currentPage) => {
		setState({
			currentPage,
		});
	};

	const onShowSizeChange = (currentPage, limit) => {
		setState({
			currentPage,
			limit,
		});
	};

	const onSearchHandle = (event) => {
		const value = event.target ? event.target.value : "";
		setState({
			searchValue: value.toString(),
			currentPage: 1,
		});
	};

	const changeTableType = (value) => {
		if (!isPublicCode || !value) return;
		const type = value.toLowerCase();

		setState({
			type,
			searchValue: "",
		});
		// state.tableType = value;
		if (type === "ques_type" || type === "subject_id" || type === "source" || type === "edu_subject") {
			const routeName = {
				ques_type: "questionType",
				subject_id: "subject",
				source: "source",
				edu_subject: "eduSubject",
			};
			history.push(`/publicCode/${routeName[type]}`);
		} else {
			history.push({ pathname: "/publicCode", search: `?type=${type}` });
		}
	};

	const renderNewFiber = (item, systemNow) => {
		const systemNowTime = getTime(new Date(systemNow));
		const itemTime = getTime(new Date(item.createTime));
		return (
			<div key={item.uid} className="haveFiber">
				{item.name}
				{systemNowTime - itemTime < 604800000 && (
					<i
						className={
							classnames("material-icons", "fiber") //7days
						}>
						fiber_new
					</i>
				)}
			</div>
		);
	};

	const onFormPopupToggle = (mode) => {
		setState({
			isVisible: !isVisible,
			createPublicCodeParams: {},
			mode,
		});
	};

	const onFormChangeHandle = (event, key) => {
		const value = event.target ? event.target.value : "";
		setState({
			createPublicCodeParams: {
				...createPublicCodeParams,
				[key]: {
					value,
					message: "",
				},
			},
		});
	};

	const onUpdatePopup = (itemData) => {
		onFormPopupToggle(COMMON_TEXT.EDIT);
		const { code, nameText, remark } = itemData;
		setState({
			createPublicCodeParams: {
				code: {
					value: code,
				},
				name: {
					value: nameText,
				},
				remark: {
					value: remark,
				},
			},
			targetName: nameText,
		});
	};

	const setFormFetch = (payload) => {
		mode === COMMON_TEXT.CREATE ? send(FetchEventType.Create, { payload }) : send(FetchEventType.Update, { payload });
		onFormPopupToggle("");
	};

	const setDuplicateFormFetch = () => {
		const nextPayload = {
			isIgnoreName: true,
			...duplicatePayload,
		};
		mode === COMMON_TEXT.CREATE
			? send(FetchEventType.Create, { payload: nextPayload })
			: send(FetchEventType.Update, {
					payload: nextPayload,
			  });
		setState({
			isDuplicateVisible: false,
		});
		onFormPopupToggle("");
	};

	const onFormSubmitHandle = async () => {
		const inputValues = {
			name: "",
			code: "",
			remark: "",
		};
		Object.entries(createPublicCodeParams).forEach(([key, value]) => {
			inputValues[key] = value.value;
		});
		const rules = rulesModel;
		const [isPass, errorGroup] = createValidator(inputValues, rules);
		setState({
			createPublicCodeParams: {
				...createPublicCodeParams,
				...errorGroup,
			},
		});
		if (isPass) return;
		const payload = {
			type,
			...inputValues,
		};
		const filterSelfExistList = existList.name.filter((item) => item !== targetName);
		if (!checkDuplicateName(inputValues.name, filterSelfExistList)) {
			setState({
				isDuplicateVisible: true,
				duplicatePayload: payload,
			});
			return;
		}
		setFormFetch(payload);
	};

	const exportHandle = () => {
		const url = `${process.env.REACT_APP_DOMAIN}/api/Definition/Basic/Export?type=${type.toLocaleUpperCase()}`;
		exportFile(url, "get");
	};

	const toggleUploadPopupVisible = () => {
		setUploadPopup({
			...uploadPopup,
			visible: !uploadPopup.visible,
		});
	};

	const changeFileList = (value) => {
		setFiles(value);
	};

	const submitFiles = async () => {
		if (files.length === 0) return;
		setUploadPopup({
			...uploadPopup,
			loading: true,
		});
		await executeUpload(files, `basic/${type}`, importBasic(type));
		setUploadPopup({
			visible: false,
			loading: false,
		});
		setFiles([]);
		send(FetchEventType.Fetch);
	};

	useEffect(() => {
		if (!query.get("type")) history.push({ pathname: "/publicCode", search: `?type=year` });
		if (!authState.matches(AuthStates.LoginDone)) return;
		const { authority, tableAuthority } = getTableAuthority(
			query.get("type"),
			authState.context.authority.function.Definition,
		);

		setAuthority({
			...authorityState,
			authority,
			tableAuthority,
		});
		send(FetchEventType.Fetch);
	}, [authState.value]);

	useEffect(() => {
		setState({
			type: query.get("type"),
		});
	}, [query.get("type")]);

	useEffect(() => {
		if (!stateSelection.context.result) return;
		const tableType = !Array.isArray(type) && type ? type : "year";
		const typeKey = tableType.toUpperCase();
		const {
			data: { data: tableData, types: options },
			systemNow,
		} = stateSelection.context.result;
		const formatData = tableData[typeKey]
			.map((value) => ({
				...value,
				nameText: value.name,
				name: renderNewFiber(value, systemNow),
				editState: false,
			}))
			.filter((item) => item.nameText.indexOf(searchValue) >= 0);
		setState({
			listData: formatData,
			total: formatData.length,
			options: setSelectOptions(options),
			existList: {
				code: tableData[typeKey] && tableData[typeKey].map((item) => item.code),
				name: tableData[typeKey] && tableData[typeKey].map((item) => item.name),
			},
		});
	}, [stateSelection.context.result, type, searchValue]);

	return (
		<UiBasicPage>
			<UiTableTop>
				<div className="breadcrumb">
					<Breadcrumb data={breadcrumbLists} />
				</div>
				<UiTop>
					<div className="select">
						<Select options={options || []} defaultValue={type ? type.toUpperCase() : ""} onChange={changeTableType} />
					</div>
					<UiActionBox>
						{authorityState.tableAuthority.Import ? (
							<Button.IconButton variant="blue" full={false} iconName="cloud_upload" onClick={toggleUploadPopupVisible}>
								匯入
							</Button.IconButton>
						) : (
							""
						)}

						{authorityState.tableAuthority.Export ? (
							<Button.IconButton variant="blue" full={false} iconName="save_alt" onClick={exportHandle}>
								匯出
							</Button.IconButton>
						) : (
							""
						)}

						{authorityState.tableAuthority.Edit ? (
							<Button.IconButton
								variant="blue"
								full={false}
								iconName="add"
								onClick={() => onFormPopupToggle(COMMON_TEXT.CREATE)}>
								新增
							</Button.IconButton>
						) : (
							""
						)}
						<div className="search">
							<Input placeholder="搜尋..." type={"search"} value={searchValue} onChange={(e) => onSearchHandle(e)} />
						</div>
					</UiActionBox>
				</UiTop>
			</UiTableTop>
			<Table margin={20}>
				<Header>
					<Row>
						<Item>代碼</Item>
						<Item flex={5}>名稱</Item>
						<Item>備註</Item>
						<Item>編輯</Item>
					</Row>
				</Header>
				<Body>
					{showData &&
						showData.map((item) => {
							return (
								<Row key={item.code}>
									<Item>{item.code}</Item>
									<Item flex={5}>{item.name}</Item>
									<Item>{item.remark}</Item>
									<Item>
										{authorityState.tableAuthority.Edit ? (
											<Button.IconButton
												variant="orange"
												full={false}
												iconName="edit"
												onClick={() => onUpdatePopup(item)}>
												編輯
											</Button.IconButton>
										) : (
											""
										)}
									</Item>
								</Row>
							);
						})}
				</Body>
			</Table>
			{total > 10 && (
				<Pagination
					defaultCurrent={1}
					current={currentPage}
					onChange={pageChange}
					onShowSizeChange={onShowSizeChange}
					total={total}
					defaultPageSize={limit}
				/>
			)}
			<Modal
				title={
					<>
						<i className="material-icons">add_box</i>
						{POPUP_TITLE[mode]}
					</>
				}
				visible={isVisible}
				confirmLoading={false}
				okText="確定"
				cancelText="取消"
				okButtonProps={{ ...Modal.okButtonProps, disabled: submitDisabled }}
				onOk={onFormSubmitHandle}
				onCancel={() => onFormPopupToggle("")}>
				{mode === COMMON_TEXT.CREATE ? (
					<Input
						placeholder="代碼*"
						onChange={(e) => onFormChangeHandle(e, "code")}
						label="代碼"
						value={createPublicCodeParams["code"]?.value || ""}
						message={createPublicCodeParams["code"]?.message || ""}
					/>
				) : (
					<div className="editCode">
						代碼<span style={{ color: "#1890ff" }}>【{createPublicCodeParams["code"]?.value || ""}】</span>
					</div>
				)}

				<Input
					label="名稱"
					placeholder="請輸入名稱*"
					type="textArea"
					onChange={(e) => onFormChangeHandle(e, "name")}
					value={createPublicCodeParams["name"]?.value || ""}
					message={createPublicCodeParams["name"]?.message || ""}
				/>
				<Input
					placeholder="備註"
					onChange={(e) => onFormChangeHandle(e, "remark")}
					label="備註"
					value={createPublicCodeParams["remark"]?.value || ""}
				/>
			</Modal>
			<Modal.WarningModal
				visible={isDuplicateVisible}
				onOk={setDuplicateFormFetch}
				onCancel={() => setState({ isDuplicateVisible: false })}>
				資料：{createPublicCodeParams["name"]?.value || ""} 已存在, 請確認輸入內容。
			</Modal.WarningModal>

			<Modal
				confirmLoading={uploadPopup.loading}
				visible={uploadPopup.visible}
				onCancel={toggleUploadPopupVisible}
				okText={uploadPopup.loading ? "上傳中..." : "確定"}
				onOk={submitFiles}
				title="上傳檔案">
				<Upload
					fileList={files}
					onChange={changeFileList}
					prompt={
						<>
							僅限上傳一個Microsoft Excel 檔<br />
							(副檔名 : .xls/.xlsx)
						</>
					}
					acceptFileType=".xls,.xlsx"
				/>
			</Modal>
		</UiBasicPage>
	);
};
