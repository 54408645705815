import { Api } from "common-components";
import { stringify } from "query-string";
/**
 * 取得起始頁查詢用選單資料
 */
export const getBookMetaSelection = async () => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/BookMeta/Selection`);
	return response;
};

/**
 * 查詢年度科目之課本列表
 */
export const getBookMetaBooks = async (year, payload) => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/BookMeta/${year}/Books`, payload);
	return response;
};

/**
 * 鎖定課本章節列表
 */
export const lockBookMetaBooks = async (bookID, payload) => {
	const querystring = stringify(payload);
	const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/BookMeta/${bookID}/Lock?${querystring}`);
	return response;
};
