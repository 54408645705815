import styled from "styled-components";

export const UiChapterPage = styled.div`
	overflow-x: hidden;
	width: 95%;
	flex: 1;

	.noBooks {
		padding: 0 15px;
		font-size: 18px;
		font-weight: bold;
		text-align: center;
		color: #f00;
	}

	.chapterBox {
		position: relative;
		padding-top: 10px;
		width: 100%;

		&::after {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: #535353;
			content: "";
		}

		.title {
			padding: 15px 0;
			font-size: 18px;
			font-weight: bold;
			color: #ffec43;
		}

		.booksBox {
			display: flex;
			align-items: center;
			width: 100%;
			flex-wrap: wrap;

			.bookItem {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				font-size: 16px;
				color: #fff;
				background-color: #1f1f1f;
				box-shadow: inset -1px 0 0 #f0f0f0, inset 0 1px 0 #f0f0f0, inset 1px 0 0 #f0f0f0, inset 0 -1px 0 #f0f0f0;
				transition: 0.35s;
				flex-basis: 24.8%;
				margin-bottom: 15px;
				cursor: pointer;

				&:hover {
					background-color: #6c6c6c;
				}

				&:not(:nth-child(4n)) {
					margin-right: calc(1% / 4);
				}
			}
		}
	}
	.selectRadio {
		min-width: 120px;
		font-size: 15px;
		align-items: center;
		width: 100%;
	}

	.ant-modal-body {
		padding: 24px 0;
		max-height: 500px;
		overflow: auto;
	}

	.modalContent {
		display: flex;
		padding: 0 24px;
		flex-direction: column;
		row-gap: 10px;
	}

	.ant-tabs-nav {
		margin: 0 8px 24px;
	}

	.ant-tabs-tab {
		padding: 12px 16px;

		& + .ant-tabs-tab {
			margin-left: 12px;
		}

		&.ant-tabs-tab-active {
			background-color: #dbeeff;

			& .ant-tabs-tab-btn {
				text-shadow: none;
			}
		}
	}
`;
