import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMachine } from "@xstate/react";
import { format } from "date-fns";
import { Timeline } from "antd";
import * as XLSX from "xlsx";
import { v4 as uuidv4 } from "uuid";

import useQuery from "utils/hooks/useQuery";
import { UiTop, UiActionBox } from "styles";
import { Breadcrumb, Button, Upload, Input, Table, Pagination } from "common-components";
import { EDUCATIONAL_LIST, LANGUAGE_GROUP } from "constants/index";
import { subjectsObj } from "constants/index";
import { UiNewSubjectPage, UiNewSubjectTag } from "../common.style";
import fetchMachine, { FetchEventType, FetchStates } from "machines/fetchMachine";
import {
	getNewSubjectKnowledge,
	getNewSubjectKnowledgeHistory,
	importNewSubjectKnowledgeIndexes,
	importNewSubjectLanguageKnowledgeIndexes,
} from "api/chapter/index";
import { sliceTableData } from "utils/common";
import { UiNewSubjectChapterModal, CustomDrawerContent, UiCustomDrawer } from "./KnowledgePage.style";
import { useFirebaseStorage } from "utils/hooks/useFirebaseStorage";
import openNotificationWithIcon from "utils/hooks/useNotification";

const { Header, Body, Row, Item } = Table;
const multiSubjectMap = ["JSO", "JNA"];

export const KnowledgePage = () => {
	const history = useHistory();
	const { upload } = useFirebaseStorage();

	const edu = useQuery().get("edu");
	const subject = useQuery().get("subject");

	const eduSubject = edu + subject;

	const [pageState, setPageState] = useState({
		page: 1,
		limit: 10,
	});
	const [classList, setClassList] = useState([]);
	const [open, setOpen] = useState(false);
	const [uploadPopup, setUploadPopup] = useState({
		visible: false,
		loading: false,
	});
	const [uploadFile, setUploadFile] = useState({});
	const [isSubmitted, setIsSubmitted] = useState(false);

	// 確定上傳檔案跟當前科目是否相符
	const [uploadFileEduSubject, setUploadFileEduSubject] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	const eduName = EDUCATIONAL_LIST[edu];
	const subjectName = subjectsObj[subject];

	const pageChange = (page, limit) => {
		setPageState({
			page,
			limit,
		});
	};

	const breadcrumbGroup = [
		{
			icon: "home",
			name: "首頁",
			path: "/",
		},
		{
			icon: "format_list_bulleted",
			name: "新科目資料",
			path: "/newSubject",
		},
		{
			name: `${eduName} - ${subjectName}`,
			path: `/newSubject/info?edu=${edu}&subject=${subject}`,
		},
		{
			name: "知識點總表",
			path: `/newSubject/knowledge?edu=${edu}&subject=${subject}`,
		},
	];

	const [knowledgeData, setKnowledgeData] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_content, event) => {
				const response = await getNewSubjectKnowledge(event);
				const { data } = response;

				setIsLoading(false);

				if (Object.values(data).length > 0) {
					const classData = Object.values(data)[0];

					setClassList(classData.knowledgeIndexes);

					return {
						originClassList: classData.knowledgeIndexes,
						creator: classData.maintainer,
						createTime: format(new Date(classData.createTime), "yyyy/MM/dd HH:mm"),
					};
				} else {
					return null;
				}
			},
		},
	});

	const { originClassList, creator, createTime } = knowledgeData.context.result || {};

	const handleSearchKnowledge = (value) => {
		const searchValue = originClassList.filter((item) => {
			return item.code.includes(value) || item.name.includes(value);
		});

		setClassList(searchValue);
	};

	// 取得所有版本紀錄
	const [historyState, historySend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const payload = {
					eduSubject: event.eduSubject,
				};

				const response = await getNewSubjectKnowledgeHistory(payload);
				const response_ = response.data.map(({ id, maintainer, fileUrl, createTime, restoreTime }) => ({
					id,
					creator: maintainer,
					fileUrl,
					createTime: format(new Date(createTime), "yyyy/MM/dd HH:mm"),
					restoreTime: restoreTime && format(new Date(restoreTime), "yyyy/MM/dd HH:mm"),
				}));

				setOpen(true);

				return response_;
			},
		},
	});

	const historyData = historyState.context.result || [];

	const showDrawer = () => {
		historySend(FetchEventType.Fetch, {
			eduSubject: edu + subject,
		});
	};

	// 匯入知識點
	const toggleUploadPopupVisible = () => {
		setUploadPopup({
			...uploadPopup,
			visible: !uploadPopup.visible,
		});
		setUploadFile({});
		setUploadFileEduSubject({});
	};

	const [, uploadFileSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const isLanguage = LANGUAGE_GROUP.includes(subject);

				const response = isLanguage
					? await importNewSubjectLanguageKnowledgeIndexes(event.payload)
					: await importNewSubjectKnowledgeIndexes(event.payload);

				if (response?.error === 200) {
					openNotificationWithIcon("success", "上傳成功");
					toggleUploadPopupVisible();
					setIsLoading(true);
					setKnowledgeData(FetchEventType.Fetch, {
						edu,
						subject,
					});
				} else if (response?.error === 222) {
					openNotificationWithIcon("error", `上傳失敗！`);
					toggleUploadPopupVisible();
				} else {
					openNotificationWithIcon("error", `上傳失敗！ ${response.message}`);
					toggleUploadPopupVisible();
				}
			},
		},
	});

	const showConfirm = async () => {
		const uid = uuidv4();
		setIsSubmitted(true);

		if (uploadFile.knowledgeFile.length === 0) return;
		if (!uploadFileEduSubject.knowledgeFile?.edu.includes(eduName)) return;
		if (!uploadFileEduSubject.knowledgeFile?.subject.includes(subjectName)) return;

		// 語文科 (語文科才會有章節表)
		if (LANGUAGE_GROUP.includes(subject)) {
			if (
				uploadFile.chapterFile.length === 0 ||
				!uploadFileEduSubject.chapterFile?.subject.includes(subjectName) ||
				!uploadFileEduSubject.chapterFile?.edu.includes(eduName)
			) {
				return;
			}
		}

		const chapterInfo =
			LANGUAGE_GROUP.includes(subject) &&
			(await upload(`chapter/${uid}-${uploadFile.chapterFile[0].name}`, uploadFile.chapterFile[0]));
		const knowledgeInfo = await upload(
			`knowledge/${uid}-${uploadFile.knowledgeFile[0].name}`,
			uploadFile.knowledgeFile[0],
		);

		const payload = LANGUAGE_GROUP.includes(subject)
			? {
					knowledgeUrl: knowledgeInfo.url,
					knowledgeFileName: uploadFile.knowledgeFile[0].name,
					chapterUrl: chapterInfo.url,
					chapterFileName: uploadFile.chapterFile[0].name,
			  }
			: {
					url: knowledgeInfo.url,
					fileName: uploadFile.knowledgeFile[0].name,
			  };

		// // 語文科
		uploadFileSend(FetchEventType.Fetch, {
			payload,
		});
	};

	const changeChapterFile = (file, type) => {
		handleFileUpload(file, type);

		setUploadFile((prev) => {
			return {
				...prev,
				[type]: file,
			};
		});
	};

	const handleFileUpload = (event, type) => {
		if (event) {
			const file = event[0];
			const reader = new FileReader();

			reader.onload = (e) => {
				const data = new Uint8Array(e.target.result);
				const workbook = XLSX.read(data, { type: "array" });
				const firstSheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[firstSheetName];

				const edu = type === "chapterFile" ? worksheet.B2?.v : worksheet.A2?.v;

				setUploadFileEduSubject((prev) => ({
					...prev,
					[type]: {
						edu: edu,
						subject: worksheet.C2?.v,
					},
				}));
			};

			if (file) {
				reader.readAsArrayBuffer(file);
			}
		}
	};

	const onClose = () => {
		setOpen(false);
	};

	const handleUploadErrorMessage = (type) => {
		if (isSubmitted && uploadFile[type]?.length === 0) {
			return "請上傳檔案";
		}

		if (uploadFileEduSubject[type] && !uploadFileEduSubject[type]?.subject.includes(subjectName)) {
			return "「學制科目」有誤";
		}

		return "";
	};

	useEffect(() => {
		if (!edu || !subject) return;
		setIsLoading(true);
		setKnowledgeData(FetchEventType.Fetch, {
			edu,
			subject,
		});
	}, [edu, subject]);

	return (
		<div>
			<UiTop>
				<UiActionBox>
					<Button.IconButton
						variant="white"
						full={false}
						iconName="keyboard_backspace"
						onClick={() => {
							history.push(`/newSubject/info?edu=${edu}&subject=${subject}`);
						}}>
						回上層
					</Button.IconButton>
					{edu && subject && <Breadcrumb data={breadcrumbGroup} />}
				</UiActionBox>
			</UiTop>
			<UiNewSubjectPage>
				<div className="header">
					<div className="title">知識點總表</div>

					<UiActionBox style={{ marginLeft: "auto" }}>
						<Button.IconButton iconName="file_download" textColor="black" type="outLined">
							下載範本
						</Button.IconButton>
						<Button.IconButton iconName="lightbulb" textColor="black" type="outLined">
							入庫流程說明
						</Button.IconButton>
					</UiActionBox>
				</div>
				<div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "24px" }}>
					<UiActionBox>
						{!multiSubjectMap.includes(eduSubject) && (
							<Button.IconButton
								iconName="post_add"
								variant="yellow"
								textColor="black"
								full={false}
								onClick={() => toggleUploadPopupVisible()}>
								匯入知識點
							</Button.IconButton>
						)}
						<Input.Search
							mode="dark"
							placeholder="搜尋知識點"
							onChange={(e) => handleSearchKnowledge(e.target.value)}
						/>
					</UiActionBox>
				</div>
				{!multiSubjectMap.includes(eduSubject) && (
					<div className="history">
						<div>{creator && createTime && `最近異動：${creator} ${createTime}`}</div>
						<Button.IconButton
							variant="blue"
							full={false}
							iconName="pending_actions"
							onClick={() => showDrawer()}
							size="small">
							版本紀錄
						</Button.IconButton>
					</div>
				)}

				<Table margin={20}>
					<Header>
						<Row>
							{LANGUAGE_GROUP.includes(subject) && <Item flex={2}>課文</Item>}
							<Item flex={2}>知識點名稱</Item>
							<Item className="remarkIcon" flex={3}>
								知識點代碼
							</Item>
						</Row>
					</Header>
					<Body isLoading={isLoading}>
						{classList &&
							sliceTableData(classList, pageState.page, pageState.limit).map((item) => {
								return (
									<Row key={item.code}>
										{LANGUAGE_GROUP.includes(subject) && <Item flex={2}>{item.className}</Item>}
										<Item flex={2}>{item.name}</Item>
										<Item flex={3}>{item.code}</Item>
									</Row>
								);
							})}
						{classList.length === 0 && (
							<div className="tableNoData">
								<img src={`/assets/noData.png`} alt="noData" />
								無資料顯示
							</div>
						)}
					</Body>
				</Table>
				<Pagination
					defaultCurrent={1}
					current={pageState.page}
					onChange={pageChange}
					total={classList?.length}
					defaultPageSize={pageState.limit}
				/>

				{/* 版本紀錄 */}
				<CustomDrawerContent
					title={<div style={{ color: "#1890FF" }}>{`版本紀錄 - ${eduName} - ${subjectName}`}</div>}
					onClose={onClose}
					getContainer={false}
					visible={open}
					className="test"
					width="568px">
					<div>
						<Timeline>
							{historyData.map((item, index) => {
								return (
									<Timeline.Item
										key={item.id}
										dot={
											<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
												<rect x="-0.000488281" width="10" height="10" rx="5" fill="#1890FF" />
											</svg>
										}>
										<UiCustomDrawer isNow={index === 0}>
											<div className="container">
												<div style={{ width: "210px" }}>
													<div className="title">
														{item.createTime} {index === 0 && <span>(目前)</span>}
													</div>
													<div className="description">
														<div>{item.creator}</div>
														{item.restoreTime && (
															<div style={{ display: "flex", gap: "8px" }}>
																<UiNewSubjectTag style={{ margin: "0px" }}>還原</UiNewSubjectTag>
																<div>{item.restoreTime}</div>
															</div>
														)}
													</div>
												</div>
											</div>
										</UiCustomDrawer>
									</Timeline.Item>
								);
							})}
						</Timeline>
					</div>
				</CustomDrawerContent>

				<UiNewSubjectChapterModal
					confirmLoading={uploadPopup.loading}
					visible={uploadPopup.visible}
					onCancel={toggleUploadPopupVisible}
					okText={uploadPopup.loading ? "上傳中..." : "上傳"}
					onOk={showConfirm}
					title={<div style={{ color: "#1890FF" }}>{`新增知識點：${eduName} - ${subjectName}`}</div>}
					width="560px">
					<div className="modalContent">
						{LANGUAGE_GROUP.includes(subject) && (
							<>
								<div>上傳章節表</div>
								<Upload
									fileList={uploadFile.chapterFile || []}
									onChange={(e) => changeChapterFile(e, "chapterFile")}
									prompt={
										<>
											僅限上傳一個 Microsoft Excel 檔<br />
											(副檔名 : .xlsx)
										</>
									}
									acceptFileType=".xlsx"
									message={handleUploadErrorMessage("chapterFile")}
								/>
							</>
						)}

						<div>上傳知識點表</div>
						<Upload
							fileList={uploadFile.knowledgeFile || []}
							onChange={(e) => changeChapterFile(e, "knowledgeFile")}
							prompt={
								<>
									僅限上傳一個 Microsoft Excel 檔<br />
									(副檔名 : .xlsx)
								</>
							}
							acceptFileType=".xlsx"
							message={handleUploadErrorMessage("knowledgeFile")}
						/>
					</div>
				</UiNewSubjectChapterModal>
			</UiNewSubjectPage>
		</div>
	);
};
