"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiDatePicker = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _styledSystem = require("styled-system");

var _templateObject;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var UiDatePicker = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tdisplay: block;\n\n\t> .ant-picker {\n\t\t", "\n\t\tdisplay: flex;\n\n\t\tinput,\n\t\tspan > span {\n\t\t\t", "\n\t\t}\n\t}\n"])), (0, _styledSystem.variant)({
  variants: {
    dark: {
      color: "#fff",
      background: "#626262",
      borderColor: "#626262"
    }
  }
}), (0, _styledSystem.variant)({
  variants: {
    dark: {
      color: "#fff",
      background: "#626262",
      borderColor: "#626262"
    }
  }
}));

exports.UiDatePicker = UiDatePicker;