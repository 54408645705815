import React from "react";
import PropTypes from "prop-types";
import { UiTag } from "./Tag.style";

/**
 * 通常是table專用tag組件
 */

export const Tag = ({ label, onClick, mr, background, color, children }) => {
	const onClickHandle = () => {
		onClick && onClick(label);
	};
	return (
		<UiTag className={color} onClick={onClickHandle} mr={mr} background={background}>
			{label}
			{children}
		</UiTag>
	);
};

Tag.propTypes = {
	children: PropTypes.any,
	label: PropTypes.string,
	onClick: PropTypes.func,
	mr: PropTypes.string,
	background: PropTypes.string,
	color: PropTypes.oneOf(["blue", "red", "primary", "secondary", "tertiary"]),
};
