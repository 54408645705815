import React, { useEffect, useMemo, useState, useRef } from "react";
import {
	Button,
	Select,
	Input,
	Table,
	Pagination,
	Modal,
	Breadcrumb,
	AuthMachine,
	AuthProvider,
	Upload,
} from "common-components";
import { Tag } from "components";
import { useMachine } from "@xstate/react";
import {
	getSourceTableData,
	getBasicTableData,
	createSource,
	updateSource,
	getSourceRelated,
	getSingleSourceRelated,
	importSource,
} from "api/definition";
import { fetchMachine, FetchEventType, FetchStates } from "machines/fetchMachine";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getTime } from "date-fns";
import { createValidator, required, checkDuplicateName, regex } from "utils/validation";
import { setSelectOptions, sliceTableData, getTableAuthority, exportFile } from "utils/common";
import { POPUP_TITLE, COMMON_TEXT, INIT_AUTHORITY } from "constants/index";
import { UiTop, UiActionBox, UiTableTop } from "styles";
import classnames from "classnames";
import { useUpload } from "utils/hooks/useUpload";
import { UiSourcePage } from "./SourcePage.style";

/**
 * 出處來源頁面
 */
const { Header, Body, Row, Item } = Table;
const tagColor = [
	{
		tagName: "primary",
	},
	{
		tagName: "secondary",
	},
	{
		tagName: "tertiary",
	},
];
//整理出處來源表 資料
const sortSourceTableData = (data) => {
	const sourceData = data.filter((item) => item.metaType === "SOURCE");
	const subSourceData = data.filter((item) => item.metaType === "SUB_SOURCE");
	let nextData = [];
	let count = 0;
	for (let i = 0; i < sourceData.length; i++) {
		let combineData = [sourceData[i]];
		let childData = [];
		for (let j = 0; j < subSourceData.length; j++) {
			if (subSourceData[j].parentUID === sourceData[i].uid) {
				subSourceData[j] = {
					...subSourceData[j],
					provenance: sourceData[i].name,
					tagName: tagColor[count % 3].tagName,
				};
				childData = childData.concat(subSourceData[j]);
			}
		}
		combineData = combineData.concat(childData);
		nextData = nextData.concat(combineData);
		count++;
	}
	return nextData;
};

const renderNewFiber = (item, systemNow) => {
	const systemNowTime = getTime(new Date(systemNow));
	const itemTime = getTime(new Date(item.createTime));
	return (
		<div key={item.uid} className="haveFiber">
			{item.name}
			{systemNowTime - itemTime < 604800000 && (
				<i
					className={
						classnames("material-icons", "fiber") //7days
					}>
					fiber_new
				</i>
			)}
		</div>
	);
};

const { state: AuthStates } = AuthMachine;
const { useAuthState } = AuthProvider;

export const SourcePage = () => {
	const authState = useAuthState();
	const history = useHistory();
	const isPublicCode = useRouteMatch({ path: "/publicCode" }) ? true : false;
	const { executeUpload } = useUpload();
	const [state, setState] = useState({
		type: "source",
		mode: COMMON_TEXT.CREATE,
		searchValue: "",
		duplicatePayload: {},
	});
	const [pageState, setPageState] = useState({
		currentPage: 1,
		limit: 10,
	});
	const [tagState, setTagState] = useState({
		provenance: "",
		tagName: "",
		parentUID: "",
	});
	const [modalState, setModalState] = useState({
		isDuplicateVisible: false,
		isVisible: false,
	});

	const [sendParamsState, setSendParamsState] = useState({});

	const [authorityState, setAuthority] = useState(INIT_AUTHORITY);

	const tableRef = useRef(null);

	const [files, setFiles] = useState([]);

	const [uploadPopup, setUploadPopup] = useState({
		visible: false,
		loading: false,
	});

	const [stateSource, send] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const res = await getSourceTableData();
				const { data, systemNow, isSuccess, message } = res;
				return {
					isSuccess,
					message,
					data: data
						? sortSourceTableData(data).map((value) => {
								if (value.metaType === "SUB_SOURCE") {
									return {
										...value,
										nameText: value.name,
										name: renderNewFiber(value, systemNow),
										provenance: (
											<Tag label={value.provenance} color={value.tagName} mr="2" onClick={() => setTag(value)} />
										),
										editState: false,
									};
								} else {
									return {
										...value,
										nameText: value.name,
										name: renderNewFiber(value, systemNow),
										editState: false,
									};
								}
						  })
						: [],
				};
			},
			createData: (_context, event) => createSource(event.payload),
			updateData: (_context, event) => updateSource(event.payload),
		},
	});

	const [stateSelection, sendSelection] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const { data, isSuccess, message } = await getBasicTableData();
				return {
					isSuccess,
					message,
					selectOption: setSelectOptions(data?.types),
				};
			},
		},
	});

	const [stateRelated, sendRelated] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const { data, isSuccess, message } = await getSourceRelated();
				return {
					isSuccess,
					message,
					sourceTypes: data?.metaTypes || [],
					sourceOptions: data?.sourceMap || [],
					sourceExistName: data?.existName || [],
					sourceExistSubSource: data?.existSubSource || [],
				};
			},
		},
	});
	const [singleStateRelated, singleSendRelated] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const res = await getSingleSourceRelated(event.uid);
				const {
					data: { existName },
				} = res;
				return {
					editExistName: existName,
				};
			},
		},
	});

	const { data } = stateSource.context.result || [];
	const { selectOption } = stateSelection.context.result || [];
	const { sourceTypes, sourceOptions, sourceExistName, sourceExistSubSource } = stateRelated.context.result || {};
	const { editExistName } = singleStateRelated.context.result || {};

	const rulesModel = {
		SUB_SOURCE: {
			name: [{ message: "名稱欄位不得空白", validate: required }],
			parentCode: [{ message: "請選擇出處", validate: required }],
			code: [
				{ message: "代碼欄位不得空白", validate: required },
				{
					message: "該代碼已被其他資料使用，請輸入其他名稱後繼續新增",
					validate: (value) => (state.mode === COMMON_TEXT.CREATE ? !sourceExistSubSource.includes(value) : true),
				},
			],
		},
		[COMMON_TEXT.CREATE]: {
			name: [{ message: "名稱欄位不得空白", validate: required }],
			type: [{ message: "請選擇類型", validate: required }],
		},
		[COMMON_TEXT.EDIT]: {
			name: [{ message: "名稱欄位不得空白", validate: required }],
		},
	};

	const submitDisabled = useMemo(() => {
		if (!sendParamsState) return;
		let allSubmitDataLength = -1;
		const sendDataLength = Object.keys(sendParamsState).length;
		if (sendDataLength > 0) {
			if (sendParamsState.type && sendParamsState.type.value === "SUB_SOURCE") {
				allSubmitDataLength = Object.keys(rulesModel.SUB_SOURCE).length + 1;
			} else {
				allSubmitDataLength = state.mode ? Object.keys(rulesModel[state.mode]).length : -1;
			}

			if (sendDataLength >= allSubmitDataLength) {
				for (const [, value] of Object.entries(sendParamsState)) {
					if (value.message) {
						return true;
					}
				}
				return false;
			}
		}
		return true;
	}, [sendParamsState]);

	const filterData = useMemo(() => {
		if (!tagState.parentUID) {
			return data?.filter((item) => item?.nameText?.indexOf(state.searchValue) >= 0) || [];
		} else {
			return data
				.filter((item) => item.parentUID && item.parentUID === tagState.parentUID)
				?.filter((item) => item.nameText.indexOf(state.searchValue) >= 0);
		}
	}, [data, state.searchValue, tagState.parentUID]);

	const breadcrumbLists = useMemo(() => {
		const tableName =
			(selectOption && selectOption.filter((item) => item.value === state.type.toUpperCase())[0]?.name) || "";
		const result = [
			{
				icon: "home",
				name: "首頁-異動紀錄",
				path: "/",
			},
			{
				icon: "grid_on",
				name: `共用代碼-${tableName}總表`,
				path: `/publicCode/questionType`,
			},
		];
		return result;
	});

	const setTag = (tagData) => {
		const { provenance, tagName, parentUID } = tagData;
		setTagState({
			provenance,
			tagName,
			parentUID,
		});
	};
	const clearTag = () => {
		setTagState({
			provenance: "",
			tagName: "",
			parentUID: "",
		});
	};

	const pageChange = (currentPage, pageSize) => {
		setPageState({
			...pageState,
			currentPage,
			limit: pageSize,
		});
		tableRef.current.scrollIntoView();
	};

	const onSearchHandle = (event) => {
		const value = event.target ? event.target.value : "";
		setState({
			...state,
			searchValue: value.toString(),
		});
		setPageState({
			...pageState,
			currentPage: 1,
		});
	};

	const changeTableType = (value) => {
		if (!isPublicCode || !value) return;
		const type = value.toLowerCase();

		setState({
			...state,
			type,
		});
		if (type === "ques_type" || type === "subject_id" || type === "source" || type === "edu_subject") {
			const routeName = {
				ques_type: "questionType",
				subject_id: "subject",
				source: "source",
				edu_subject: "eduSubject",
			};
			history.push(`/publicCode/${routeName[type]}`);
		} else {
			history.push({ pathname: "/publicCode", search: `?type=${type}` });
		}
	};

	const onFormPopupToggle = (mode) => {
		if (mode) {
			sendRelated(FetchEventType.Fetch);
		}

		setModalState({
			...modalState,
			isDuplicateVisible: false,
			isVisible: !modalState.isVisible,
		});
		setState({
			...state,
			mode,
		});
		setSendParamsState({});
	};
	const onUpdatePopup = (itemData) => {
		onFormPopupToggle(COMMON_TEXT.EDIT);
		const { code, nameText, parentUID, metaType, remark, uid } = itemData;
		singleSendRelated(FetchEventType.Fetch, { uid });
		setSendParamsState({
			uid: {
				value: uid,
			},
			type: {
				value: metaType,
			},
			code: {
				value: code,
			},
			parentCode: {
				value: parentUID,
			},
			name: {
				value: nameText,
			},
			remark: {
				value: remark,
			},
		});
	};

	const onFormChangeHandle = (event, key) => {
		const value = event.target ? event.target.value : "";
		setSendParamsState({
			...sendParamsState,
			[key]: {
				value,
				message: "",
			},
		});
	};
	const onFormSelectChangeHandle = (value, key) => {
		setSendParamsState({
			...sendParamsState,
			[key]: {
				value,
			},
		});
	};

	const setFormFetch = (payload) => {
		state.mode === COMMON_TEXT.CREATE
			? send(FetchEventType.Create, { payload })
			: send(FetchEventType.Update, { payload });
	};

	const setDuplicateFormFetch = () => {
		const nextPayload = {
			isIgnoreName: true,
			...state.duplicatePayload,
		};
		state.mode === COMMON_TEXT.CREATE
			? send(FetchEventType.Create, { payload: nextPayload })
			: send(FetchEventType.Update, {
					payload: nextPayload,
			  });
		setModalState({
			...modalState,
			isDuplicateVisible: false,
		});
	};

	const onFormSubmitHandle = async () => {
		const inputValues = {
			type: "",
			name: "",
			parentCode: "",
			code: "",
		};
		Object.entries(sendParamsState).forEach(([key, value]) => {
			inputValues[key] = value.value;
		});
		let rules = {};

		if (inputValues.type === "SUB_SOURCE") {
			rules = rulesModel[inputValues.type];
		} else {
			rules = rulesModel[state.mode];
			if (state.mode !== COMMON_TEXT.CREATE) {
				delete inputValues.parentCode;
			}
		}
		const [isPass, errorGroup] = createValidator(inputValues, rules);
		setSendParamsState({
			...sendParamsState,
			...errorGroup,
		});
		if (isPass) return;
		let existNameArray = [];
		Object.entries(state.mode === COMMON_TEXT.CREATE ? sourceExistName : editExistName).forEach(([, value]) => {
			existNameArray = existNameArray.concat(value.map((item) => item));
		});
		if (!checkDuplicateName(inputValues.name, existNameArray)) {
			setModalState({
				...modalState,
				isDuplicateVisible: true,
			});
			setState({
				...state,
				duplicatePayload: inputValues,
			});
			return;
		}
		setFormFetch(inputValues);
	};

	const exportHandle = () => {
		const url = `${process.env.REACT_APP_DOMAIN}/api/Definition/Source/Export`;
		exportFile(url, "get");
	};

	const toggleUploadPopupVisible = () => {
		setUploadPopup({
			...uploadPopup,
			visible: !uploadPopup.visible,
		});
	};

	const changeFileList = (value) => {
		setFiles(value);
	};

	const submitFiles = async () => {
		if (files.length === 0) return;
		setUploadPopup({
			...uploadPopup,
			loading: true,
		});
		await executeUpload(files, "basic/source", importSource);
		setUploadPopup({
			visible: false,
			loading: false,
		});
		setFiles([]);
		send(FetchEventType.Fetch);
		sendRelated(FetchEventType.Fetch);
	};

	useEffect(() => {
		if (!authState.matches(AuthStates.LoginDone)) return;

		const { authority, tableAuthority } = getTableAuthority("SOURCE", authState.context.authority.function.Definition);
		setAuthority({
			...authorityState,
			authority,
			tableAuthority,
		});
		send(FetchEventType.Fetch);
		sendSelection(FetchEventType.Fetch);
		sendRelated(FetchEventType.Fetch);
	}, [authState.value]);

	useEffect(() => {
		if (!stateSource.matches(FetchStates.Resolved)) return;
		setModalState({
			...modalState,
			isDuplicateVisible: false,
			isVisible: false,
		});
	}, [stateSource.value]);

	return (
		<UiSourcePage ref={tableRef}>
			<UiTableTop>
				<div className="breadcrumb">
					<Breadcrumb data={breadcrumbLists} />
				</div>
				<UiTop>
					<UiActionBox>
						<div className="select">
							<Select
								options={selectOption}
								defaultValue={state.type ? state.type.toUpperCase() : ""}
								onChange={changeTableType}
							/>
						</div>
						{tagState.provenance && (
							<Tag label={tagState.provenance} color={tagState.tagName} onClick={clearTag}>
								<i className="material-icons">close</i>
							</Tag>
						)}
					</UiActionBox>

					<UiActionBox>
						{authorityState.tableAuthority.Import ? (
							<Button.IconButton variant="blue" full={false} iconName="cloud_upload" onClick={toggleUploadPopupVisible}>
								匯入
							</Button.IconButton>
						) : (
							""
						)}
						{authorityState.tableAuthority.Export ? (
							<Button.IconButton variant="blue" full={false} iconName="save_alt" onClick={exportHandle}>
								匯出
							</Button.IconButton>
						) : (
							""
						)}
						{authorityState.tableAuthority.Edit ? (
							<Button.IconButton
								variant="blue"
								full={false}
								iconName="add"
								onClick={() => onFormPopupToggle(COMMON_TEXT.CREATE)}>
								新增
							</Button.IconButton>
						) : (
							""
						)}
						<div className="search">
							<Input
								placeholder="搜尋..."
								type={"search"}
								value={state.searchValue}
								onChange={(e) => onSearchHandle(e)}
							/>
						</div>
					</UiActionBox>
				</UiTop>
			</UiTableTop>

			<Table margin={20}>
				<Header>
					<Row>
						<Item>代碼</Item>
						<Item flex={5}>名稱</Item>
						<Item>出處</Item>
						<Item>備註</Item>
						<Item>編輯</Item>
					</Row>
				</Header>
				<Body>
					{filterData &&
						sliceTableData(filterData, pageState.currentPage, pageState.limit).map((item) => {
							return (
								<Row key={item.code}>
									<Item>{item.code}</Item>
									<Item flex={5}>{item.name}</Item>
									<Item>{item.provenance}</Item>
									<Item>{item.remark}</Item>
									<Item>
										{authorityState.tableAuthority.Edit ? (
											<Button.IconButton
												variant="orange"
												full={false}
												iconName="edit"
												onClick={() => onUpdatePopup(item)}>
												編輯
											</Button.IconButton>
										) : (
											""
										)}
									</Item>
								</Row>
							);
						})}
				</Body>
			</Table>
			{filterData && filterData.length > 10 && (
				<Pagination
					defaultCurrent={1}
					current={pageState.currentPage}
					onChange={pageChange}
					total={filterData.length}
					defaultPageSize={pageState.limit}
				/>
			)}

			<Modal
				title={
					<>
						<i className="material-icons">add_box</i>
						{POPUP_TITLE[state.mode]}
					</>
				}
				visible={modalState.isVisible}
				confirmLoading={false}
				okText="確定"
				cancelText="取消"
				okButtonProps={{ ...Modal.okButtonProps, disabled: submitDisabled }}
				onOk={onFormSubmitHandle}
				onCancel={() => onFormPopupToggle("")}>
				{state.mode !== COMMON_TEXT.CREATE && (
					<div className="editCode">
						代碼
						<span style={{ color: "#1890ff" }}>【{sendParamsState["code"]?.value || ""}】</span>
					</div>
				)}
				{sourceTypes && sourceTypes.length > 0 && state.mode === COMMON_TEXT.CREATE ? (
					<Select
						label="類型"
						options={setSelectOptions(sourceTypes)}
						onChange={(value) => onFormSelectChangeHandle(value, "type")}
						message={sendParamsState["type"]?.message || ""}
						value={sendParamsState["type"]?.value || ""}
					/>
				) : (
					""
				)}
				{sourceOptions && sourceOptions.length > 0 && sendParamsState["type"]?.value === "SUB_SOURCE" && (
					<>
						<Select
							label="出處"
							options={setSelectOptions(sourceOptions)}
							onChange={(value) => onFormSelectChangeHandle(value, "parentCode")}
							message={sendParamsState["parentCode"]?.message || ""}
							value={sendParamsState["parentCode"]?.value || ""}
						/>
						{state.mode === COMMON_TEXT.CREATE && (
							<Input
								placeholder="代碼*"
								onChange={(e) => onFormChangeHandle(e, "code")}
								label="代碼"
								value={sendParamsState["code"]?.value || ""}
								message={sendParamsState["code"]?.message || ""}
							/>
						)}
					</>
				)}
				<Input
					label="名稱"
					placeholder="請輸入名稱*"
					type="textArea"
					onChange={(e) => onFormChangeHandle(e, "name")}
					value={sendParamsState["name"]?.value || ""}
					message={sendParamsState["name"]?.message || ""}
				/>

				<Input
					placeholder="備註"
					onChange={(e) => onFormChangeHandle(e, "remark")}
					label="備註"
					value={sendParamsState["remark"]?.value || ""}
				/>
			</Modal>
			<Modal.WarningModal
				visible={modalState.isDuplicateVisible}
				onOk={setDuplicateFormFetch}
				onCancel={() => {
					setModalState({
						...modalState,
						isDuplicateVisible: false,
					});
				}}>
				資料：{sendParamsState["name"]?.value || ""} 已存在, 請確認輸入內容。
			</Modal.WarningModal>

			<Modal
				confirmLoading={uploadPopup.loading}
				visible={uploadPopup.visible}
				onCancel={toggleUploadPopupVisible}
				okText={uploadPopup.loading ? "上傳中..." : "確定"}
				onOk={submitFiles}
				title="上傳檔案">
				<Upload
					fileList={files}
					onChange={changeFileList}
					prompt={
						<>
							僅限上傳一個Microsoft Excel 檔<br />
							(副檔名 : .xls/.xlsx)
						</>
					}
					acceptFileType=".xls,.xlsx"
				/>
			</Modal>
		</UiSourcePage>
	);
};
