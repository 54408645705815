import styled from "styled-components";

export const UiChapterListPage = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	.search {
		min-width: 200px;
	}

	.knowledgeName {
		color: #ffb802;
	}

	.knowledgeLink {
		cursor: pointer;
	}

	.knowledgeLink:hover {
		text-decoration: underline;
		color: #1890ff;
	}

	.remarkIcon {
		justify-content: center;

		svg {
			font-size: 20px;
		}
	}
`;
