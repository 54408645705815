import styled from "styled-components";
import { variant, space } from "styled-system";
export const UiCurriculumTag = styled.div`
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	padding: 2px 8px;

	border: 1px solid #dbffb7;
	box-sizing: border-box;
	border-radius: 4px;

	${variant({
		variants: {
			108: {
				color: "#DBFFB7",
				borderColor: "#DBFFB7",
			},
			99: {
				color: "#1890FF;",
				borderColor: "#1890FF;",
			},
		},
	})}
	${space}
`;
