import React, { useEffect, useMemo, useState, useRef } from "react";
import {
	Button,
	Select,
	Input,
	Table,
	Pagination,
	Modal,
	Breadcrumb,
	AuthMachine,
	AuthProvider,
	Upload,
} from "common-components";
import { useMachine } from "@xstate/react";
import {
	getQuestionTypeTableData,
	getBasicTableData,
	createQuestionType,
	updateQuestionTypeList,
	getQuestionTypeRelated,
	getSingleQuestionTypeRelated,
	importQuestionType,
} from "api/definition";
import { fetchMachine, FetchEventType, FetchStates } from "machines/fetchMachine";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getTime } from "date-fns";
import { createValidator, required, checkDuplicateName } from "utils/validation";
import { setSelectOptions, sliceTableData, getTableAuthority, exportFile } from "utils/common";
import { POPUP_TITLE, COMMON_TEXT, INIT_AUTHORITY } from "constants/index";
import { UiTop, UiActionBox, UiTableTop } from "styles";
import useQuery from "utils/hooks/useQuery";
import { useUpload } from "utils/hooks/useUpload";
import { HeadphonesSolid } from "@onedesign/icon";
import { Checkbox as AntCheckbox } from "antd";
import classnames from "classnames";

import { UiQuestionTypePage, UiQuestionTypeDetail } from "./QuestionTypePage.style";
/**
 * 題目類型總表頁面
 */
const { Header, Body, Row, Item } = Table;

const questionTypeLabel = {
	QUES_GROUP: "groupCode",
	QUES_PRINT: "printCode",
};
const { state: AuthStates } = AuthMachine;
const { useAuthState } = AuthProvider;
export const QuestionTypePage = () => {
	const authState = useAuthState();
	const history = useHistory();
	const query = useQuery();
	const isPublicCode = useRouteMatch({ path: "/publicCode" }) ? true : false;
	const { executeUpload } = useUpload();
	const [state, setState] = useState({
		type: query.get("type") || "ques_type",
		mode: COMMON_TEXT.CREATE,
		searchValue: "",
		duplicatePayload: {},
	});
	const [pageState, setPageState] = useState({
		currentPage: 1,
		limit: 10,
	});
	const [sendParamsState, setSendParamsState] = useState({
		createPublicCodeParams: {},
	});

	const [modalState, setModalState] = useState({
		isDuplicateVisible: false,
		isVisible: false,
		questionTypeModalIsVisible: false,
	});

	const [authorityState, setAuthority] = useState(INIT_AUTHORITY);

	const tableRef = useRef(null);

	const [files, setFiles] = useState([]);

	const [uploadPopup, setUploadPopup] = useState({
		visible: false,
		loading: false,
	});

	const rulesModel = {
		[COMMON_TEXT.CREATE]: {
			orderby: [{ message: "排序欄位不得空白", validate: required }],
			name: [{ message: "名稱欄位不得空白", validate: required }],
			groupCode: [{ message: "作答方式為必填", validate: required }],
			printCode: [{ message: "預設列印方式為必填", validate: required }],
		},
		[COMMON_TEXT.EDIT]: {
			orderby: [{ message: "排序欄位不得空白", validate: required }],
			name: [{ message: "名稱欄位不得空白", validate: required }],
			printCode: [{ message: "預設列印方式為必填", validate: required }],
		},
	};

	const submitDisabled = useMemo(() => {
		const allSubmitDataLength = state.mode ? Object.keys(rulesModel[state.mode]).length : -1;
		if (Object.keys(sendParamsState.createPublicCodeParams).length >= allSubmitDataLength) {
			for (const [, value] of Object.entries(sendParamsState.createPublicCodeParams)) {
				if (value.message) {
					return true;
				}
			}
			return false;
		}
		return true;
	}, [state.mode, sendParamsState.createPublicCodeParams]);

	const renderNewFiber = (item, systemNow) => {
		const systemNowTime = getTime(new Date(systemNow));
		const itemTime = getTime(new Date(item.createTime));
		return (
			<div key={item.uid} className="haveFiber">
				{item.name}
				{systemNowTime - itemTime < 604800000 && (
					<i
						className={
							classnames("material-icons", "fiber") //7days
						}>
						fiber_new
					</i>
				)}
			</div>
		);
	};

	const [stateQuestionType, send] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const res = await getQuestionTypeTableData();
				const { data, systemNow, isSuccess, message } = res;
				return {
					isSuccess,
					message,
					data: data
						? data.map((value) => ({
								...value,
								nameText: value.name,
								name: renderNewFiber(value, systemNow),
								editState: false,
						  }))
						: [],
				};
			},
			createData: (_context, event) => createQuestionType(event.payload),
			updateData: (_context, event) => updateQuestionTypeList(event.payload),
		},
	});

	const { data } = stateQuestionType.context.result || [];

	const filterData = useMemo(() => {
		return data?.filter((item) => item.nameText.indexOf(state.searchValue) >= 0) || [];
	}, [data, state.searchValue]);

	const [stateSelection, sendSelection] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const { data, isSuccess, message } = await getBasicTableData();
				return {
					isSuccess,
					message,
					selectOption: setSelectOptions(data?.types),
				};
			},
		},
	});

	const { selectOption } = stateSelection.context.result || [];

	const breadcrumbLists = useMemo(() => {
		const tableName =
			(selectOption && selectOption.filter((item) => item.value === state.type.toUpperCase())[0]?.name) || "";
		const result = [
			{
				icon: "home",
				name: "首頁-異動紀錄",
				path: "/",
			},
			{
				icon: "grid_on",
				name: `共用代碼-${tableName}總表`,
				path: `/publicCode/questionType`,
			},
		];
		return result;
	});
	const [stateRelated, sendRelated] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const { data, isSuccess, message } = await getQuestionTypeRelated();
				return {
					isSuccess,
					message,
					questionTypes: data?.attributes || [],
					questionTypeOptions: data?.attrData || {},
					questionTypeExistName: data?.existName || [],
				};
			},
		},
	});

	const { questionTypes, questionTypeOptions, questionTypeExistName } = stateRelated.context.result || {};

	const [singleStateRelated, singleSendRelated] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context, event) => {
				const res = await getSingleQuestionTypeRelated(event.code);
				return {
					editExistName: res.data.existName || [],
				};
			},
		},
	});

	const { editExistName } = singleStateRelated.context.result || {};

	const pageChange = (currentPage, pageSize) => {
		setPageState({
			...pageState,
			currentPage,
			limit: pageSize,
		});
		tableRef.current.scrollIntoView();
	};

	const onSearchHandle = (event) => {
		const value = event.target ? event.target.value : "";
		setState({
			...state,
			searchValue: value.toString(),
		});
		setPageState({
			...pageState,
			currentPage: 1,
		});
	};

	const changeTableType = (value) => {
		if (!isPublicCode || !value) return;
		const type = value.toLowerCase();

		setState({
			...state,
			type,
		});
		if (type === "ques_type" || type === "subject_id" || type === "source" || type === "edu_subject") {
			const routeName = {
				ques_type: "questionType",
				subject_id: "subject",
				source: "source",
				edu_subject: "eduSubject",
			};
			history.push(`/publicCode/${routeName[type]}`);
		} else {
			history.push({ pathname: "/publicCode", search: `?type=${type}` });
		}
	};

	const onFormPopupToggle = (mode) => {
		setModalState({
			...modalState,
			isDuplicateVisible: false,
			isVisible: !modalState.isVisible,
		});
		setState({
			...state,
			mode,
		});
		setSendParamsState({
			createPublicCodeParams: {},
		});
	};

	const onFormChangeHandle = (event, key) => {
		const value = event.target ? event.target.value : "";
		setSendParamsState({
			createPublicCodeParams: {
				...sendParamsState.createPublicCodeParams,
				[key]: {
					value,
					message: "",
				},
			},
		});
	};

	const onUpdatePopup = (itemData) => {
		onFormPopupToggle(COMMON_TEXT.EDIT);
		const { code, nameText, remark, printCode, groupDesc, isListen, orderby } = itemData;
		singleSendRelated(FetchEventType.Fetch, { code });
		setSendParamsState({
			createPublicCodeParams: {
				code: {
					value: code,
				},
				groupCode: {
					value: groupDesc,
				},
				printCode: {
					value: printCode,
				},
				name: {
					value: nameText,
				},
				remark: {
					value: remark,
				},
				isListen: {
					value: isListen,
				},
				orderby: {
					value: orderby,
				},
			},
		});
	};

	const setFormFetch = (payload) => {
		state.mode === COMMON_TEXT.CREATE
			? send(FetchEventType.Create, { payload })
			: send(FetchEventType.Update, { payload });
	};

	const setDuplicateFormFetch = () => {
		const nextPayload = {
			isIgnoreName: true,
			...state.duplicatePayload,
		};
		state.mode === COMMON_TEXT.CREATE
			? send(FetchEventType.Create, { payload: nextPayload })
			: send(FetchEventType.Update, {
					payload: nextPayload,
			  });
		setModalState({
			...modalState,
			isDuplicateVisible: false,
		});
	};

	const onFormSelectChangeHandle = (value, key) => {
		setSendParamsState({
			createPublicCodeParams: {
				...sendParamsState.createPublicCodeParams,
				[key]: {
					value,
				},
			},
		});
	};

	const onFormSubmitHandle = async () => {
		let rules = {};
		const inputValues = {
			name: "",
			groupCode: "",
			printCode: "",
		};
		Object.entries(sendParamsState.createPublicCodeParams).forEach(([key, value]) => {
			inputValues[key] = value.value;
		});

		rules = rulesModel[state.mode];

		if (state.mode !== COMMON_TEXT.CREATE) {
			delete inputValues.groupCode;
		}
		const [isPass, errorGroup] = createValidator(inputValues, rules);
		setSendParamsState({
			createPublicCodeParams: {
				...sendParamsState.createPublicCodeParams,
				...errorGroup,
			},
		});
		if (isPass) return;
		const payload = {
			type: state.type.toLocaleUpperCase(),
			...inputValues,
		};
		if (
			!checkDuplicateName(inputValues.name, state.mode === COMMON_TEXT.CREATE ? questionTypeExistName : editExistName)
		) {
			setModalState({
				...modalState,
				isDuplicateVisible: true,
			});
			setState({
				...state,
				duplicatePayload: payload,
			});
			return;
		}
		setFormFetch(payload);
	};

	const exportHandle = () => {
		const url = `${process.env.REACT_APP_DOMAIN}/api/NewDefinition/QuestionType/Export`;
		exportFile(url, "get");
	};

	const questionTypeModalHandler = () => {
		setModalState({
			...modalState,
			questionTypeModalIsVisible: !modalState.questionTypeModalIsVisible,
		});
	};

	const renderQuestionTypeDetail = () => {
		return (
			<>
				<UiQuestionTypeDetail>
					<div className="questionTypeOptionsContent">
						<div className="questionTypeOptionsCode">
							<div>
								<div>代碼</div>
								{questionTypeOptions &&
									questionTypeOptions["QUES_GROUP"]?.map((item, index) => <div key={index}>{item.code}</div>)}
							</div>
						</div>
						<div className="questionTypeOptionsName">
							<div>
								<div>作答方式</div>
								{questionTypeOptions &&
									questionTypeOptions["QUES_GROUP"]?.map((item, index) => <div key={index}>{item.name}</div>)}
							</div>
						</div>
					</div>
					<div className="questionTypeOptionsContent quesPrint">
						<div className="questionTypeOptionsCode">
							<div>
								<div>代碼</div>
								{questionTypeOptions &&
									questionTypeOptions["QUES_PRINT"]?.map((item, index) => <div key={index}>{item.code}</div>)}
							</div>
						</div>
						<div className="questionTypeOptionsName">
							<div>
								<div>列印方式</div>
								{questionTypeOptions &&
									questionTypeOptions["QUES_PRINT"]?.map((item, index) => <div key={index}>{item.name}</div>)}
							</div>
						</div>
					</div>
				</UiQuestionTypeDetail>
			</>
		);
	};

	const toggleUploadPopupVisible = () => {
		setUploadPopup({
			...uploadPopup,
			visible: !uploadPopup.visible,
		});
	};

	const changeFileList = (value) => {
		setFiles(value);
	};

	const submitFiles = async () => {
		if (files.length === 0) return;
		setUploadPopup({
			...uploadPopup,
			loading: true,
		});
		await executeUpload(files, "basic/questionType", importQuestionType);
		setUploadPopup({
			visible: false,
			loading: false,
		});
		setFiles([]);
		send(FetchEventType.Fetch);
		sendRelated(FetchEventType.Fetch);
	};

	const listenChange = (e) => {
		setSendParamsState({
			createPublicCodeParams: {
				...sendParamsState.createPublicCodeParams,
				isListen: {
					value: e.target.checked,
					message: "",
				},
			},
		});
	};

	useEffect(() => {
		if (!authState.matches(AuthStates.LoginDone)) return;

		const { authority, tableAuthority } = getTableAuthority(
			"QUESTYPE",
			authState.context.authority.function.Definition,
		);
		setAuthority({
			...authorityState,
			authority,
			tableAuthority,
		});
		send(FetchEventType.Fetch);
		sendSelection(FetchEventType.Fetch);
		sendRelated(FetchEventType.Fetch);
	}, [authState.value]);

	useEffect(() => {
		if (!stateQuestionType.matches(FetchStates.Resolved)) return;
		setModalState({
			...modalState,
			isDuplicateVisible: false,
			isVisible: false,
		});
	}, [stateQuestionType.value]);

	return (
		<UiQuestionTypePage ref={tableRef}>
			<UiTableTop>
				<div className="breadcrumb">
					<Breadcrumb data={breadcrumbLists} />
				</div>
				<UiTop>
					<div className="select">
						<Select
							options={selectOption}
							defaultValue={state.type ? state.type.toUpperCase() : ""}
							onChange={changeTableType}
						/>
					</div>
					<UiActionBox>
						<Button.IconButton variant="orange" full={false} iconName="visibility" onClick={questionTypeModalHandler}>
							題目屬性
						</Button.IconButton>

						{authorityState.tableAuthority.Import ? (
							<Button.IconButton variant="blue" full={false} iconName="cloud_upload" onClick={toggleUploadPopupVisible}>
								匯入
							</Button.IconButton>
						) : (
							""
						)}
						{authorityState.tableAuthority.Export ? (
							<Button.IconButton variant="blue" full={false} iconName="save_alt" onClick={exportHandle}>
								匯出
							</Button.IconButton>
						) : (
							""
						)}
						{authorityState.tableAuthority.Edit ? (
							<Button.IconButton
								variant="blue"
								full={false}
								iconName="add"
								onClick={() => onFormPopupToggle(COMMON_TEXT.CREATE)}>
								新增
							</Button.IconButton>
						) : (
							""
						)}

						<div className="search">
							<Input
								placeholder="搜尋..."
								type={"search"}
								value={state.searchValue}
								onChange={(e) => onSearchHandle(e)}
							/>
						</div>
					</UiActionBox>
				</UiTop>
			</UiTableTop>
			<Table margin={20}>
				<Header>
					<Row>
						<Item>排序</Item>
						<Item>代碼</Item>
						<Item flex={5}>名稱</Item>
						<Item>作答方式</Item>
						<Item>預設列印方式</Item>
						<Item>備註</Item>
						<Item>編輯</Item>
					</Row>
				</Header>
				<Body>
					{filterData &&
						sliceTableData(filterData, pageState.currentPage, pageState.limit).map((item) => {
							return (
								<Row key={item.code}>
									<Item>{item.orderby}</Item>
									<Item>{item.code}</Item>
									<Item flex={5}>
										{item.name}
										{item.isListen && <HeadphonesSolid style={{ color: "#ffb800", marginLeft: "8px" }} />}
									</Item>
									<Item>{item.groupDesc}</Item>
									<Item>{item.printDesc}</Item>
									<Item>{item.remark}</Item>
									<Item>
										{authorityState.tableAuthority.Edit ? (
											<Button.IconButton
												variant="orange"
												full={false}
												iconName="edit"
												onClick={() => onUpdatePopup(item)}>
												編輯
											</Button.IconButton>
										) : (
											""
										)}
									</Item>
								</Row>
							);
						})}
				</Body>
			</Table>
			{filterData && filterData.length > 10 && (
				<Pagination
					defaultCurrent={1}
					current={pageState.currentPage}
					onChange={pageChange}
					total={filterData.length}
					defaultPageSize={pageState.limit}
				/>
			)}

			<Modal
				title={
					<>
						<i className="material-icons">add_box</i>
						{POPUP_TITLE[state.mode]}
					</>
				}
				visible={modalState.isVisible}
				confirmLoading={false}
				okText="確定"
				cancelText="取消"
				okButtonProps={{ ...Modal.okButtonProps, disabled: submitDisabled }}
				onOk={onFormSubmitHandle}
				onCancel={() => onFormPopupToggle("")}>
				{state.mode !== COMMON_TEXT.CREATE && (
					<div className="editCode">
						代碼
						<span style={{ color: "#1890ff" }}>【{sendParamsState.createPublicCodeParams["code"]?.value || ""}】</span>
					</div>
				)}
				<Input
					label="排序"
					placeholder="請輸入排序*"
					type="textArea"
					onChange={(e) => onFormChangeHandle(e, "orderby")}
					value={sendParamsState?.createPublicCodeParams["orderby"]?.value || ""}
					message={sendParamsState?.createPublicCodeParams["orderby"]?.message || ""}
				/>
				<Input
					label="名稱"
					placeholder="請輸入名稱*"
					type="textArea"
					onChange={(e) => onFormChangeHandle(e, "name")}
					value={sendParamsState?.createPublicCodeParams["name"]?.value || ""}
					message={sendParamsState?.createPublicCodeParams["name"]?.message || ""}
				/>
				{questionTypes && questionTypes.length > 0
					? questionTypes.map((type) => {
							if (state.mode !== COMMON_TEXT.CREATE && type.code === "QUES_GROUP") {
								return (
									<div className="editCode">
										{type.name}
										<span>【{sendParamsState.createPublicCodeParams[questionTypeLabel[type.code]]?.value || ""}】</span>
									</div>
								);
							}
							return (
								<Select
									key={type.name}
									label={type.name}
									options={questionTypeOptions[type.code].map((item) => {
										return {
											name: item.name,
											value: item.code,
										};
									})}
									value={sendParamsState.createPublicCodeParams[questionTypeLabel[type.code]]?.value || ""}
									message={sendParamsState.createPublicCodeParams[questionTypeLabel[type.code]]?.message || ""}
									onChange={(value) => onFormSelectChangeHandle(value, questionTypeLabel[type.code])}
								/>
							);
					  })
					: ""}
				<div>
					<AntCheckbox
						onChange={listenChange}
						checked={sendParamsState.createPublicCodeParams["isListen"]?.value || false}>
						此題型為聽力試題 <HeadphonesSolid style={{ color: "#ffb800", marginLeft: "8px" }} />
					</AntCheckbox>
				</div>

				<Input
					placeholder="備註"
					onChange={(e) => onFormChangeHandle(e, "remark")}
					label="備註"
					value={sendParamsState.createPublicCodeParams["remark"]?.value || ""}
				/>
			</Modal>
			<Modal.WarningModal
				visible={modalState.isDuplicateVisible}
				onOk={setDuplicateFormFetch}
				onCancel={() => {
					setModalState({
						...modalState,
						isDuplicateVisible: false,
					});
				}}>
				資料：{sendParamsState.createPublicCodeParams["name"]?.value || ""} 已存在, 請確認輸入內容。
			</Modal.WarningModal>
			<Modal
				title={
					<>
						<i className="material-icons">visibility</i>題目屬性
					</>
				}
				visible={modalState.questionTypeModalIsVisible}
				onCancel={questionTypeModalHandler}
				footer={null}>
				{renderQuestionTypeDetail()}
			</Modal>
			<Modal
				confirmLoading={uploadPopup.loading}
				visible={uploadPopup.visible}
				onCancel={toggleUploadPopupVisible}
				okText={uploadPopup.loading ? "上傳中..." : "確定"}
				onOk={submitFiles}
				title="上傳檔案">
				<Upload
					fileList={files}
					onChange={changeFileList}
					prompt={
						<>
							僅限上傳一個Microsoft Excel 檔<br />
							(副檔名 : .xls/.xlsx)
						</>
					}
					acceptFileType=".xls,.xlsx"
				/>
			</Modal>
		</UiQuestionTypePage>
	);
};
