import { Api } from "common-components";

/**
 * 查詢 課本章節資料
 */
export const getChapterBooks = async (bookID) => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Chapter/${bookID}`);
	return response;
};

/**
 * 查詢 課本章節資料頁 使用之下拉選單
 */
export const getChapterBooksListSelection = async (bookID) => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Chapter/${bookID}/Selection`);
	return response;
};

/**
 * 查詢 新增課本章節資料 使用之相關資訊
 */
export const getChapterBooksRelated = async (year, payload) => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Chapter/${year}/Books/Related`, payload);
	return response;
};

/**
 * 取得新增節次相關資料
 */
export const getBooksRelated = async (bookID) => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Chapter/${bookID}/Related`);
	return response;
};

/**
 * 新增節次
 */
export const createChapterBook = async (bookID, payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Chapter/${bookID}`, payload);
	return response;
};

/**
 * 修改節次
 */
export const editChapterBook = async (bookID, payload) => {
	const response = await Api.put(`${process.env.REACT_APP_DOMAIN}/api/Chapter/${bookID}/Section`, payload);
	return response;
};

/**
 * 取得修改章節相關資料
 */
export const getChapterSectionRelated = async (bookID, chapterUID) => {
	const response = await Api.get(`${process.env.REACT_APP_DOMAIN}/api/Chapter/${bookID}/Related/${chapterUID}`);
	return response;
};

/**
 * 刪除章節
 */
export const deleteChapterSection = async (bookID, payload) => {
	const { ChapterUID } = payload;
	const response = await Api.delete(
		`${process.env.REACT_APP_DOMAIN}/api/Chapter/${bookID}/Section?chapterUID=${ChapterUID}`,
	);
	return response;
};

/**
 * 上傳章節
 */
export const importChapter = (bookID) => async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/Chapter/${bookID}/Import`, payload);
	return response;
};

/**
 * 一階層多階層知識向度
 */
export const importKnowledge = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/Knowledge/ChapterFocus/Import`, payload);
	return response;
};

/**
 * 一階層多階層章節表
 */
export const importLevel = async (param, payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/NewChapter/${param}/CMS/book/Import`, payload);
	return response;
};

/**
 * 大考章節知識向度
 */
export const importExamChapterKnowledge = async (param, payload) => {
	const response = await Api.post(
		`${process.env.REACT_APP_DOMAIN}/api/NewSubjectMeta/${param}/Import/OneLayer`,
		payload,
	);
	return response;
};
/**
 * 大考章節章節表
 */
export const importExamChapterLevel = async (bookID, payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/api/NewChapter/${bookID}/Import/OneLayer`, payload);
	return response;
};

/**
 * 主題式無課綱知識向度
 */
export const importSyllabusKnowledge = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/Knowledge/NoCurriculum/Import`, payload);
	return response;
};
/**
 * 主題式無課綱章節表
 */
export const importSyllabusLevel = async (param, payload) => {
	const response = await Api.post(
		`${process.env.REACT_APP_DOMAIN}/api/NewChapter/${param}/NoCurriculum/book/Import`,
		payload,
	);
	return response;
};

/**
 * 一階層多階層知識向度（命題概要）
 */
export const importKnowledgeProposition = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/Knowledge/Proposition/Import`, payload);
	return response;
};

/**
 * 一階層多階層章節表（命題概要）
 */
export const importLevelProposition = async (param, payload) => {
	const response = await Api.post(
		`${process.env.REACT_APP_DOMAIN}/api/NewChapter/${param}/CMS/Proposition/book/Import`,
		payload,
	);
	return response;
};

/**
 * (新科目) 匯入知識概念代碼(非語言科)
 */

export const importNewSubjectKnowledge = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/chapterv2/proposition/import`, payload);
	return response;
};

/**
 * (新科目) 匯入知識概念代碼(語言科)
 */

export const importNewSubjectLanguageKnowledge = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/chapterv2/proposition/language/import`, payload);
	return response;
};

/**
 * (新科目) 匯入課本章節
 */

export const importNewSubjectChapterBook = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/chapterv2/import`, payload);
	return response;
};

/**
 * (新科目) 取得知識點總表
 */

/**
 * (新科目) 取得課本章節
 */

export const getNewSubjectChapterBook = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/chapterv2/books`, payload);
	return response;
};

/**
 * (新科目) 取得課本版本紀錄
 */

export const getNewSubjectChapterBookHistory = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/chapterv2/book/history`, payload);
	return response;
};

/**
 * (新科目) 取得歷程課本章節
 */

export const getNewSubjectBookHistory = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/chapterv2/history`, payload);
	return response;
};

/**
 * (新科目) 恢復課本章節
 */
export const restoreNewSubjectChapterBook = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/chapterv2/restore`, payload);
	return response;
};

/**
 * (新科目) 取得課本章節總表
 */
export const getNewSubjectChapterBookList = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/chapterv2/overview/books`, payload);
	return response;
};

/**
 * (新科目) 匯入課本章節冊次查詢
 */
export const getNewSubjectChapterBookInfo = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/chapterv2/import/info`, payload);
	return response;
};

/**
 * (新科目) 章節查詢選單
 */
export const getNewSubjectChapterBookSelection = async () => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/chapterv2/selection`);
	return response;
};

/**
 * (新科目) 查詢學制科目冊次選單
 */
export const getNewSubjectVolumesSelection = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/chapterv2/selection/volumes`, payload);
	return response;
};

/**
 * (新科目) 取得知識點總表
 */
export const getNewSubjectKnowledge = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/chapterv2/knowledgeindexes`, payload);
	return response;
};

/**
 * (新科目) 取得知識點歷程總表
 */
export const getNewSubjectKnowledgeHistory = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/chapterv2/knowledgeindexrecord`, payload);
	return response;
};

/**
 * (新科目) 匯入知識概念代碼 (非語文科)
 */
export const importNewSubjectKnowledgeIndexes = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/chapterv2/proposition/import`, payload);
	return response;
};

/**
 * (新科目) 匯入知識概念代碼 (語文科)
 */
export const importNewSubjectLanguageKnowledgeIndexes = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/chapterv2/proposition/language/import`, payload);
	return response;
};

/**
 * 匯入英聽類別表
 */
export const importEnglishListeningCategory = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/definitionv2/enlcategory/import`, payload);
	return response;
};

/**
 * 查詢英聽類別表(單筆)
 */
export const getEnglishListeningCategory = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/definitionv2/enlcategory`, payload);
	return response;
};

/**
 * 取得英聽類別表總表(多筆)
 */
export const getEnglishListeningCategoryList = async (payload) => {
	const response = await Api.post(`${process.env.REACT_APP_DOMAIN}/definitionv2/overview/enlcategories`, payload);
	return response;
};
