import { useFirebaseStorage } from "utils/hooks/useFirebaseStorage";
import openNotificationWithIcon from "utils/hooks/useNotification";
import { v4 as uuidv4 } from "uuid";
export const useUpload = () => {
	const { upload } = useFirebaseStorage();
	let result = {};
	const executeUpload = async (files, uploadUrl, api) => {
		await Promise.all(
			files.map(async (file) => {
				const uid = uuidv4();
				const res = await upload(`${uploadUrl}/${uid}-${file.name}`, file);
				const payload = {
					url: res.url,
					fileName: file.name,
				};
				result = await api(payload);
			}),
		);
		if (result.isSuccess || result.error === 200) {
			openNotificationWithIcon("success", "上傳成功！");
		} else {
			openNotificationWithIcon("error", `上傳失敗！${result.message}`);
		}
	};

	return { executeUpload };
};
