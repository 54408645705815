"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiButton = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _styledSystem = require("styled-system");

var _antd = require("antd");

var _templateObject;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var UiButton = (0, _styledComponents.default)(_antd.Button)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\twidth: ", " !important;\n\n\t> .icon {\n\t\tdisplay: flex;\n\t\tmargin-right: 8px;\n\n\t\t> div {\n\t\t\tvertical-align: middle;\n\t\t}\n\t}\n\t", "\n\t", "\n\n\t&:hover,\n\t&:focus,\n\t&:active {\n\t\topacity: 0.8;\n\t\t", "\n\t}\n"])), function (props) {
  return props.full ? "100%" : "max-content";
}, _styledSystem.space, (0, _styledSystem.variant)({
  variants: {
    orange: {
      color: "#fff",
      background: "buttonColor.orange",
      borderColor: "buttonColor.orange"
    },
    deepOrange: {
      color: "#fff",
      background: "buttonColor.deepOrange",
      borderColor: "buttonColor.deepOrange"
    },
    blue: {
      color: "#fff",
      background: "buttonColor.blue",
      borderColor: "buttonColor.blue"
    },
    green: {
      color: "#fff",
      background: "buttonColor.green",
      borderColor: "buttonColor.green"
    },
    yellow: {
      color: "#fff",
      background: "buttonColor.yellow",
      borderColor: "buttonColor.yellow"
    },
    red: {
      color: "#fff",
      background: "buttonColor.red",
      borderColor: "buttonColor.red"
    },
    white: {
      color: "#535353",
      background: "buttonColor.white",
      borderColor: "#D9D9D9"
    }
  }
}), (0, _styledSystem.variant)({
  variants: {
    orange: {
      color: "#fff",
      background: "buttonColor.orange",
      borderColor: "buttonColor.orange"
    },
    deepOrange: {
      color: "#fff",
      background: "buttonColor.deepOrange",
      borderColor: "buttonColor.deepOrange"
    },
    blue: {
      color: "#fff",
      background: "buttonColor.blue",
      borderColor: "buttonColor.blue"
    },
    green: {
      color: "#fff",
      background: "buttonColor.green",
      borderColor: "buttonColor.green"
    },
    yellow: {
      color: "#fff",
      background: "buttonColor.yellow",
      borderColor: "buttonColor.yellow"
    },
    red: {
      color: "#fff",
      background: "buttonColor.red",
      borderColor: "buttonColor.red"
    },
    white: {
      color: "#fff",
      background: "buttonColor.gray",
      borderColor: "buttonColor.gray"
    }
  }
}));
exports.UiButton = UiButton;