"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiPagination = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _styledSystem = require("styled-system");

var _templateObject;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var UiPagination = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\t-webkit-touch-callout: none;\n\t-webkit-user-select: none;\n\t-moz-user-select: none;\n\t-ms-user-select: none;\n\tuser-select: none;\n\n\t&.Hide {\n\t\tdisplay: none;\n\t}\n\n\tul {\n\t\tdisplay: flex;\n\t\tmargin-top: 0;\n\t\tlist-style: none;\n\t\tmargin-bottom: 0;\n\n\t\tli {\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t\tjustify-content: center;\n\t\t\tmargin: 0 5px;\n\t\t\tmargin-right: 8px;\n\t\t\tmin-width: 30px;\n\t\t\theight: 30px;\n\t\t\ttext-align: center;\n\t\t\tvertical-align: middle;\n\n\t\t\t& > a {\n\t\t\t\tcolor: ", ";\n\t\t\t}\n\n\t\t\t& > .ant-pagination-item-link {\n\t\t\t\tcolor: ", ";\n\n\t\t\t\t&:hover {\n\t\t\t\t\tcolor: #1890ff;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t.anticon {\n\t\t\t\tvertical-align: baseline;\n\t\t\t}\n\n\t\t\t&.ant-pagination-item-active {\n\t\t\t\ta {\n\t\t\t\t\tcolor: #1890ff;\n\t\t\t\t\t", "\n\t\t\t\t}\n\t\t\t\t", "\n\t\t\t}\n\n\t\t\t&.page {\n\t\t\t\tpadding: 2.5px 5px;\n\t\t\t\tcursor: pointer;\n\n\t\t\t\t&.active {\n\t\t\t\t\tcolor: #1890ff;\n\t\t\t\t\tborder: 1px solid #1890ff;\n\t\t\t\t}\n\n\t\t\t\t&.disabled {\n\t\t\t\t\tcolor: rgba(0, 0, 0, 0.25);\n\t\t\t\t\tborder-color: 1px solid #d9d9d9;\n\t\t\t\t\tcursor: not-allowed;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t&.dot {\n\t\t\t\tcolor: #fff;\n\t\t\t}\n\t\t\t", "\n\t\t}\n\n\t\t.ant-pagination-item-link {\n\t\t\t&.ant-pagination-disabled {\n\t\t\t\t", "\n\t\t\t}\n\n\t\t\t&:hover {\n\t\t\t\t", "\n\t\t\t}\n\n\t\t\t", "\n\t\t}\n\n\t\t.ant-pagination-item-ellipsis {\n\t\t\t", "\n\t\t}\n\t}\n"])), function (_ref) {
  var textcolor = _ref.textcolor;
  return textcolor || "#fff";
}, function (_ref2) {
  var textcolor = _ref2.textcolor;
  return textcolor || "#fff";
}, (0, _styledSystem.variant)({
  variants: {
    default: {
      color: "#1890ff"
    }
  }
}), (0, _styledSystem.variant)({
  variants: {
    default: {
      borderColor: "#1890ff"
    }
  }
}), (0, _styledSystem.variant)({
  variants: {
    default: {
      backgroundColor: "transparent",
      border: "1px solid transparent"
    }
  }
}), (0, _styledSystem.variant)({
  variants: {
    default: {
      color: "#d9d9d9"
    }
  }
}), (0, _styledSystem.variant)({
  variants: {
    default: {
      color: "#fff"
    }
  }
}), (0, _styledSystem.variant)({
  variants: {
    default: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
      backgroundColor: "transparent",
      borderColor: "transparent"
    }
  }
}), (0, _styledSystem.variant)({
  variants: {
    default: {
      color: "#fff!important"
    }
  }
}));

exports.UiPagination = UiPagination;