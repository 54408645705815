"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiTable = exports.UiTableBody = exports.UiTableHeader = exports.UiTableRow = exports.UiTableItem = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _styledSystem = require("styled-system");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var UiTableItem = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tflex: 1;\n\tdisplay: flex;\n\talign-items: center;\n\tpadding: 4px 15px;\n\tmin-width: 100px;\n\ttext-overflow: ellipsis;\n\t", "\n\t", "\n"])), function (_ref) {
  var issticky = _ref.issticky;
  return issticky && (0, _styledComponents.css)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n\t\t\tposition: sticky;\n\t\t\tleft: 0;\n\t\t"])));
}, _styledSystem.flexbox);

exports.UiTableItem = UiTableItem;

var UiTableRow = _styledComponents.default.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n\tdisplay: flex;\n\twidth: 100%;\n\tflex-grow: 0;\n\tflex-direction: row;\n\tflex-wrap: nowrap;\n\t", "\n"])), function (_ref2) {
  var issticky = _ref2.issticky;
  return issticky && (0, _styledComponents.css)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n\t\t\tposition: sticky;\n\t\t\ttop: 0;\n\t\t\tz-index: 2;\n\t\t"])));
});

exports.UiTableRow = UiTableRow;

var UiTableHeader = _styledComponents.default.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n\tdisplay: flex;\n\n\t", " {\n\t\tfont-size: 14px;\n\t\tfont-weight: 700;\n\t}\n"])), UiTableItem);

exports.UiTableHeader = UiTableHeader;

var UiTableBody = _styledComponents.default.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n\tposition: relative;\n\tflex: 1;\n\n\t", " {\n\t\tfont-size: 14px;\n\t\tfont-weight: 400;\n\t}\n"])), UiTableItem);

exports.UiTableBody = UiTableBody;

var UiTable = _styledComponents.default.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n\tdisplay: flex;\n\toverflow: auto;\n\theight: 100%;\n\tflex: 1;\n\tflex-direction: column;\n\t", "\n\t", "\n\t", " {\n\t\t", " {\n\t\t\t", "\n\t\t}\n\t\t", " {\n\t\t\t", "\n\t\t}\n\t}\n\t", " {\n\t\t", " {\n\t\t\t", "\n\n\t\t\t&:nth-child(odd) > div {\n\t\t\t\t", "\n\t\t\t}\n\n\t\t\t&:nth-child(even) > div {\n\t\t\t\t", "\n\t\t\t}\n\n\t\t\t&:first-child > div {\n\t\t\t\t", "\n\t\t\t}\n\n\t\t\t& + ", " > ", " {\n\t\t\t\t", "\n\t\t\t}\n\t\t}\n\t}\n"])), _styledSystem.space, (0, _styledSystem.variant)({
  variants: {
    default: {
      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)"
    }
  }
}), UiTableHeader, UiTableItem, (0, _styledSystem.variant)({
  variants: {
    default: {
      backgroundColor: "lightblue",
      color: "white"
    },
    light: {
      backgroundColor: "#fafafa",
      color: "#000"
    }
  }
}), UiTableRow, (0, _styledSystem.variant)({
  variants: {
    light: {
      color: "#000",
      borderBottom: "1px solid #f0f0f0"
    }
  }
}), UiTableBody, UiTableRow, (0, _styledSystem.variant)({
  variants: {
    default: {
      color: "white"
    },
    light: {
      color: "#000",
      borderBottom: "1px solid #f0f0f0"
    }
  }
}), (0, _styledSystem.variant)({
  variants: {
    default: {
      backgroundColor: "dark"
    }
  }
}), (0, _styledSystem.variant)({
  variants: {
    default: {
      backgroundColor: "gray"
    }
  }
}), function (_ref3) {
  var issticky = _ref3.issticky;
  return issticky && (0, _styledComponents.css)(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n\t\t\t\t\t\tcolor: ", ";\n\t\t\t\t\t\tbackground: ", ";\n\t\t\t\t\t"])), function (_ref4) {
    var theme = _ref4.theme;
    return theme.colors.white;
  }, function (_ref5) {
    var theme = _ref5.theme;
    return theme.colors.lightblue;
  });
}, UiTableRow, UiTableItem, (0, _styledSystem.variant)({
  variants: {
    default: {
      borderBottom: "1px solid rgba(255, 255, 255, 0.2)"
    }
  }
}));

exports.UiTable = UiTable;