import React, { useEffect } from "react";
import { useMachine } from "@xstate/react";
import { useHistory } from "react-router-dom";

import { Breadcrumb } from "common-components";
import fetchMachine, { FetchEventType } from "machines/fetchMachine";
import { setSelectOptions } from "utils/common";
import { getNewSubjectChapterBookSelection } from "api/chapter";
import { UiNewSubjectPage, UiContentComponent } from "../common.style";

const breadcrumbGroup = [
	{
		icon: "home",
		name: "首頁",
		path: "/",
	},
	{
		icon: "format_list_bulleted",
		name: "新科目資料",
		path: "/newSubject",
	},
];

const EduSubjectComponent = ({ edu, subjectList, handleSubjectClick }) => {
	return (
		<UiContentComponent>
			<div className="breakLine" />
			<div className="title">{edu.name}學科</div>
			<div className="content">
				{subjectList?.map((subject) => {
					return (
						<div
							className="contentBox"
							key={subject.code}
							onClick={() => handleSubjectClick(edu.value, subject.code)}
							style={{
								fontSize: "20px",
								padding: "12px 24px",
							}}>
							{subject.name}
						</div>
					);
				})}
			</div>
		</UiContentComponent>
	);
};

export const BasicPage = () => {
	const history = useHistory();
	const [selectionState, selectSend] = useMachine(fetchMachine, {
		services: {
			fetchData: async (_context) => {
				const response = await getNewSubjectChapterBookSelection();
				const { eduMap, eduSubjectMap } = response.data;

				return {
					eduMap: setSelectOptions(eduMap),
					eduSubject: eduSubjectMap,
				};
			},
		},
	});

	const { eduMap, eduSubject } = selectionState.context.result || {};

	const handleSubjectClick = (edu, subject) => {
		history.push(`/newSubject/info?edu=${edu}&subject=${subject}`);
	};

	useEffect(() => {
		selectSend(FetchEventType.Fetch);
	}, []);

	return (
		<UiNewSubjectPage>
			<Breadcrumb data={breadcrumbGroup} mb={20} />
			<div className="header">新科目資料</div>
			{eduMap?.map((edu) => {
				const subjectList = eduSubject[edu.value];
				return (
					<EduSubjectComponent
						key={edu.value}
						edu={edu}
						subjectList={subjectList}
						handleSubjectClick={handleSubjectClick}
					/>
				);
			})}
		</UiNewSubjectPage>
	);
};

export default BasicPage;
