import styled from "styled-components";

export const UiMainLayoutHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #fff;
	flex: 1;

	.title {
		margin: 0;
		font-size: 24px;
		color: #fff;
		cursor: pointer;
	}
`;

export const UiMenu = styled.div`
	.ant-menu-item,
	.ant-menu-submenu-title {
		display: flex;
		align-items: center;

		i:nth-child(1) {
			margin-right: 5px;
		}
	}
`;

export const UiUserBox = styled.div`
	display: flex;
	align-items: center;
	transition: 0.5s;

	img {
		overflow: hidden;
		margin-right: 5px;
		width: 24px;
		height: 24px;
		border-radius: 50%;
	}

	&:hover {
		color: #fff;
		opacity: 0.8;
	}
`;
