import styled from "styled-components";
import { space } from "styled-system";
export const UiAuthorityDefaultSettingPage = styled.div`
	display: block;
`;
export const UiAuthorityDefaultSettingContent = styled.div`
	${space}
	background-color: #fff;

	.header {
		display: flex;
		justify-content: space-between;

		.authorityList {
			display: flex;
		}
	}

	.userAuthority {
		.userAuthorityList {
			display: flex;

			.userAuthorityDetail {
				flex: 1;
				margin-right: 8px;

				&:nth-child(4n) {
					margin-right: 0;
				}

				.userAuthorityTitle {
					display: flex;
					align-items: center;
					padding: 24px;
					border: 1px solid #f0f0f0;

					& * {
						margin-right: 4px;
						font-size: 16px;
					}
				}

				.userAuthorityDetailList {
					padding: 24px;
					border: 1px solid #f0f0f0;
					border-top: none;

					& > div:not(:last-child) {
						margin-bottom: 12px;
					}
				}
			}
		}
	}
`;
export const UiAuthorityList = styled.div`
	position: relative;
	display: flex;

	&::after {
		position: absolute;
		bottom: 0;
		left: ${({ barindex }) => `${barindex * 25}%`};
		width: 25%;
		height: 5px;
		background: #1890ff;
		border-radius: 4px;
		content: "";
		transition: 0.5s;
	}

	& > div {
		margin-bottom: 8px;
	}
`;
export const UiAuthorityItem = styled.div`
	text-align: center;
	opacity: ${({ active }) => (active ? 1 : 0.25)};
	flex: 1;
	cursor: pointer;
`;
