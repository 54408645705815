import styled from "styled-components";

export const UiQuestionTypePage = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;

	.search {
		min-width: 200px;
	}

	.breadcrumb {
		margin-bottom: 10px !important;

		span {
			display: flex;
			align-items: center;
			color: #fff;

			a {
				color: #fff;
			}

			&:last-child {
				font-size: 18px;
			}
		}
	}

	.ant-checkbox-wrapper {
		span {
			display: flex;
			align-items: center;
		}
	}
`;

export const UiQuestionTypeDetail = styled.div`
	display: flex;
	flex: 1;
	justify-content: space-around;

	.questionTypeOptionsContent {
		display: flex;
		flex: 0.38;
		font-size: 16px;

		&.quesPrint {
			flex: 0.58;

			.questionTypeOptionsCode {
				& > div {
					div:first-child {
						background: #c8ffe8;
						border-bottom: none;
					}
				}
			}

			.questionTypeOptionsName {
				& > div {
					div:first-child {
						background: #c8ffe8;
						border-bottom: none;
					}
				}
			}
		}
	}

	.questionTypeOptionsCode {
		width: 30%;

		& > div {
			margin-right: 5px;

			div {
				padding: 2.5px 0;
				text-align: center;
				border-bottom: 1px solid #f0f0f0;
			}

			div:first-child {
				background: #c8f8ff;
				border-bottom: none;
			}
		}
	}

	.questionTypeOptionsName {
		width: 70%;

		& > div {
			margin-right: 5px;

			div {
				padding: 2.5px 0 2.5px 5px;
				border-bottom: 1px solid #f0f0f0;
			}

			div:first-child {
				background: #c8f8ff;
				border-bottom: none;
			}
		}
	}
`;
