import React from "react";
import PropTypes from "prop-types";
import { UiCurriculumTag } from "./CurriculumTag.style";

/**
 * 0.| 這邊請描述一下這個組件在幹嘛請記得填寫 我寫這麼長請注意看我 look at me mother fucker |.0
 */

export const CurriculumTag = ({ year, mb }) => {
	return (
		<UiCurriculumTag variant={year} mb={mb}>
			{year} 課綱
		</UiCurriculumTag>
	);
};

CurriculumTag.propTypes = {
	year: PropTypes.number,
};
