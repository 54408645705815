"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createValidator = exports.checkDuplicateName = exports.regex = exports.required = void 0;

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var toSBC = function toSBC(str) {
  //轉半形
  var result = "";
  var len = str.length;

  for (var i = 0; i < len; i++) {
    var cCode = str.charCodeAt(i); //全形與半形相差（除空格外）：65248（十進位制）

    cCode = cCode >= 0xff01 && cCode <= 0xff5e ? cCode - 65248 : cCode; //處理空格

    cCode = cCode === 0x03000 ? 0x0020 : cCode;
    result += String.fromCharCode(cCode);
  }

  return result;
};

var isEmpty = function isEmpty(value) {
  return value === undefined || value === null || value === "" ? false : true;
};

var required = function required(value) {
  return isEmpty(value);
};

exports.required = required;

var regex = function regex(value, re) {
  return RegExp(re).test(value);
};

exports.regex = regex;

var checkDuplicateName = function checkDuplicateName(name, checkArray) {
  var _iterator = _createForOfIteratorHelper(checkArray),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var item = _step.value;
      var newItem = toSBC(item).toLowerCase();
      if (newItem === toSBC(name).toLowerCase()) return false;
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return true;
};

exports.checkDuplicateName = checkDuplicateName;

var createValidator = function createValidator(formData, rules) {
  var isPass = false;
  var errorGroup = {};
  Object.entries(formData).forEach(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    var isFirstValidator = true;
    if (!rules[key]) return;
    rules[key].forEach(function (rule) {
      if (!rule.validate(value)) {
        if (!isFirstValidator) return;
        errorGroup[key] = {
          value: "",
          message: rule.message
        };
        isFirstValidator = false;
        isPass = true;
      }
    });
  });
  return [isPass, errorGroup];
};

exports.createValidator = createValidator;