import React, { useEffect, useMemo, useState } from "react";
import { UiTableTop } from "styles";
import { Pagination, Breadcrumb } from "common-components";
import { getBookRelated } from "api/subject";
import { useMachine } from "@xstate/react";
import { fetchMachine, FetchEventType } from "machines/fetchMachine";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { sliceTableData } from "utils/common";
import {
	UiKnowledgeDetailPage,
	UiKnowledgeDetailContent,
	UiKnowledgeDetailContainer,
	UiKnowledgeDetailListBox,
	UiKnowledgeDetailList,
	UiRelatedList,
	UiListItem,
	UiKnowledgeList,
} from "./KnowledgeDetailPage.style";

/**
 * 知識向度詳細頁
 */
export const KnowledgeDetailPage = () => {
	const history = useHistory();
	const initUrlParams = queryString.parse(useLocation().search);
	const [pageState, setPageState] = useState({
		currentPage: 1,
		limit: 15,
	});
	const [state, send] = useMachine(fetchMachine, {
		services: {
			fetchData: async () => {
				const { eduSubject, UID } = initUrlParams;
				const response = await getBookRelated({
					eduSubject,
					metaType: "KNOWLEDGE",
				})({ UID });
				const {
					isSuccess,
					message,
					data: { parents = [], remark = "", chapters = {}, code = "", name = "", sameGroup = [] } = {},
				} = response;
				return {
					isSuccess,
					message,
					chapters,
					code,
					name,
					sameGroup,
					parents: parents ? parents.reverse() : [],
					remark: remark || "--",
				};
			},
		},
	});
	const { chapters, code, name, sameGroup, parents, remark } = state.context.result || {};
	const breadcrumbLists = useMemo(() => {
		return [
			{
				name: `${code || ""} | ${name || ""}`,
			},
		];
	}, [code, name]);
	const pageChange = (currentPage, pageSize) => {
		setPageState({
			...pageState,
			currentPage,
			limit: pageSize,
		});
	};
	useEffect(() => {
		send(FetchEventType.Fetch);
	}, []);
	return (
		<UiKnowledgeDetailPage>
			<UiTableTop>
				<div className="breadcrumb">
					<Breadcrumb data={breadcrumbLists} />
				</div>
			</UiTableTop>
			<UiKnowledgeDetailContainer>
				<UiKnowledgeDetailContent flex={1} mr={4}>
					<UiKnowledgeDetailListBox mb={4}>
						<div className="listTitle">基本資料</div>
						<UiKnowledgeDetailList>
							<div className="info">
								<div className="listRow">
									<UiListItem>代碼</UiListItem>
									<UiListItem>{code}</UiListItem>
								</div>
								<div className="listRow">
									<UiListItem>名稱</UiListItem>
									<UiListItem>{name}</UiListItem>
								</div>
								<div className="listRow">
									<UiListItem>備註</UiListItem>
									<UiListItem>{remark}</UiListItem>
								</div>
							</div>
						</UiKnowledgeDetailList>
					</UiKnowledgeDetailListBox>
					<UiKnowledgeDetailListBox>
						<div className="listTitle">近年使用年度版本</div>
						<UiKnowledgeDetailList>
							<div className="recentlyInfo">
								{chapters &&
									Object.entries(chapters).map(([key, value]) => {
										return (
											<>
												<div className="listRow">
													<UiListItem fontWeight="bold">{key}</UiListItem>
												</div>
												{value.length === 0 ? (
													<div className="listRow">暫無資料</div>
												) : (
													value.map((item) => (
														<>
															<div className="listRow">
																<UiListItem fontWeight="bold">{item.code}</UiListItem>
																<UiListItem
																	flex={2}
																	className="link"
																	onClick={() => {
																		history.push(`/chapter/list?code=${item.bookID}`);
																	}}>
																	{item.name}
																</UiListItem>
																<UiListItem flex={3}>{item.bookName}</UiListItem>
															</div>
														</>
													))
												)}
											</>
										);
									})}
							</div>
						</UiKnowledgeDetailList>
					</UiKnowledgeDetailListBox>
				</UiKnowledgeDetailContent>
				<UiKnowledgeDetailContent flex={2}>
					<div className="title">相關聯的知識向度</div>
					{parents && parents.length > 0 ? (
						<UiRelatedList my={3}>
							{parents.map((item) => (
								<>
									<div className="listRow" key={item.itemName}>
										<UiListItem fontWeight="bold">{item.itemName}</UiListItem>
										<UiListItem>{item.code}</UiListItem>
										<UiListItem flex={4}>{item.name}</UiListItem>
									</div>
								</>
							))}
						</UiRelatedList>
					) : undefined}

					<UiKnowledgeList>
						{sameGroup &&
							sliceTableData(sameGroup, pageState.currentPage, pageState.limit).map((item, index) => {
								if (index === 0) {
									return (
										<div className="listRow" key={`${item.code}${index}`}>
											<UiListItem fontWeight="bold">知識向度</UiListItem>
											<UiListItem>{item.code}</UiListItem>
											<UiListItem flex={4}>{item.name}</UiListItem>
										</div>
									);
								}
								return (
									<div className="listRow" key={`${item.code}${index}`}>
										<UiListItem fontWeight="bold"></UiListItem>
										<UiListItem>{item.code}</UiListItem>
										<UiListItem flex={4}>{item.name}</UiListItem>
									</div>
								);
							})}
					</UiKnowledgeList>
					<Pagination
						textcolor={"#000"}
						defaultCurrent={1}
						current={pageState.currentPage}
						onChange={pageChange}
						total={sameGroup?.length || 0}
						defaultPageSize={pageState.limit}
					/>
				</UiKnowledgeDetailContent>
			</UiKnowledgeDetailContainer>
		</UiKnowledgeDetailPage>
	);
};

KnowledgeDetailPage.propTypes = {};
