"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UiLoading = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _templateObject;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var UiLoading = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tposition: absolute;\n\ttop: 50%;\n\tright: 0;\n\tleft: 0;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tmargin: auto;\n\twidth: max-content;\n\ttransform: translateY(-50%);\n"])));

exports.UiLoading = UiLoading;